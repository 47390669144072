import { useCallback, useMemo } from "react";
import { SectionRowProps } from "../sectionrow";

import {
  getDetailpageUrlBasedOnNodeType,
  getPageNode,
  getTimeDifference,
} from "../../utils";

import { ContentPreviewer, CoreNodesCircleIcon } from "..";
import { ETL_SECTION_TITLES } from "../../constants/labelconstants/etlconfig";

import {
  SourceCodeRendererStyled,
  SourceOfRelationRendererStyled,
} from "./etlcodesource.styles";
import LinkButton from "../linkbutton";

import StyledLink from "../styledlink/styledlink";
import { settingsIcon } from "../../svgs";

const {
  EXECUTION_DETAILS: { LAST_UPDATED_ON, SOURCE_CODE },
  META_INFO: { TARGET, SOURCE, SOURCE_OF_RELATION },
} = ETL_SECTION_TITLES;

const LastRunRenderer = (item: SectionRowProps): JSX.Element => {
  const { value = "" } = item || {};
  return <div>{getTimeDifference(value)}</div>;
};

const SourceCodeRenderer = (item: SectionRowProps): JSX.Element => {
  const { value = "" } = item;
  const codePreviewJsx = <div />;

  return (
    <SourceCodeRendererStyled>
      <ContentPreviewer
        title=""
        customJsx={codePreviewJsx}
        showCrossIcon={false}
        marginBottom="0"
        sqlQuery={value}
      />
    </SourceCodeRendererStyled>
  );
};

const NodeRenderer = (item: SectionRowProps): JSX.Element => {
  const etlNodeIcon = settingsIcon;

  const getNodeUrl = useCallback((nodeId, nodeType) => {
    const url = getDetailpageUrlBasedOnNodeType(nodeId, nodeType);

    return url || "";
  }, []);

  const { additionalInfo, value, id } = item || {};
  const { nodeType, nodeSubType } = additionalInfo || {};

  const { isETLPage: isEtlNode } = useMemo(() => {
    return getPageNode(nodeType);
  }, []);

  return (
    <div className="table-field-name">
      {isEtlNode ? (
        <span className="etl-node-icon">{etlNodeIcon}</span>
      ) : (
        <CoreNodesCircleIcon
          nodeType={nodeType}
          nodeSubType={nodeSubType}
          width="15px"
          height="15px"
        />
      )}
      <LinkButton>
        <StyledLink to={getNodeUrl(id, nodeType)}>{value}</StyledLink>
      </LinkButton>
    </div>
  );
};

const SourceOfRelationRenderer = (item: SectionRowProps): JSX.Element => {
  const { value = "" } = item;

  return (
    <SourceOfRelationRendererStyled>
      <span>{value}</span>
    </SourceOfRelationRendererStyled>
  );
};

export const etlCodeRefDrawer: {
  [key: string]: (_item: SectionRowProps) => JSX.Element;
} = {
  [LAST_UPDATED_ON]: (item) => <LastRunRenderer {...item} />,
  [SOURCE_CODE]: (item) => <SourceCodeRenderer {...item} />,
  [TARGET]: (item) => <NodeRenderer {...item} />,
  [SOURCE]: (item) => <NodeRenderer {...item} />,
  [SOURCE_OF_RELATION]: (item) => <SourceOfRelationRenderer {...item} />,
};
