import { API_CONFIG } from "../../constants/apiconfig";
import { getParsedEtlDocForRefPage } from "../../parsers/etlparser/etlparser";
import { EtlParsedDocType } from "../../parsers/etlparser/etlparser.types";
import { useGetAll } from "../baseservice";
import { CustomReactQueryHookReturnType } from "../services.types";

const { get_node: getNode } = API_CONFIG;

export function useGetEtlDoc(
  nodeID: string,
  nodeType: string,
  nodeStatus: string
): CustomReactQueryHookReturnType<EtlParsedDocType> {
  return useGetAll({
    apiConfig: getNode,
    params: [nodeID, nodeType, nodeStatus, "true", "", ""],
    parser: getParsedEtlDocForRefPage,
  });
}
