import styled from "styled-components";

export const BreadcrumStyled = styled.div`
  min-height: 33px;
  background: ${(props): string => props.theme.bgColors.BLUE_10};
  display: flex;
  .breadcrum-content {
    font-size: 13px;
    color: ${(props): string => props.theme.textColors.GREY_COLOR};
    display: flex;
    align-items: center;
    column-gap: 10.5px;
    padding: 11px 0px 6px 16.4px;
    flex: 1;

    .sep-sec {
      display: flex;
      align-items: center;
      > svg {
        margin-top: 2px;
        width: 6.1px;
        height: 10px;
      }
    }
    .path-link {
      padding: 0 4px 0 6px;
      color: ${({ theme }): string => theme.textColors.BLUE_14};
      font-size: 13px;

      :hover {
        text-decoration: underline;
      }
    }

    .breadcrum-item {
      cursor: auto;
      display: flex;
      align-items: center;
      column-gap: 11.8px;
      color: ${({ theme }): string => theme.textColors.GREY_13};
      font-size: 13px;
      svg {
        path {
          fill: ${({ theme }): string => theme.textColors.DARK_GREY_201};
        }
      }
      :first-of-type {
        a {
          color: ${({ theme }): string => theme.textColors.BLUE_14};

          :hover {
            text-decoration: underline;
          }
        }
      }
    }
    .paths-container {
      span {
        margin: 0px 3px;
      }
      a {
        display: inline;
        padding: 0px 3px;
      }
    }
  }

  > div:nth-of-type(2) {
    min-height: 41px;
    display: flex;
    align-items: center;
    background: #438eb9;
    border-color: #438eb9;
    opacity: 0.55;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    cursor: pointer;
    width: 42px;
    justify-content: center;

    :hover,
    :focus {
      background: #fe9e19;
      border-color: #fe9e19;
      opacity: 1;
    }
  }

  .breadcrum-item-text {
    color: ${({ theme }): string => theme.textColors.GREY_13};
    font-size: 13px;
    cursor: default;
  }

  .breadcrum-item-text-expanded {
    color: ${({ theme }): string => theme.textColors.BLUE_14};
    font-size: 13px;
    cursor: pointer;
  }
`;
