import styled from "styled-components";

/* This component is created to be used as a replacement for StyledLink component.
  Because StyledLink component doesn't work properly in certain places like inside
  the notifications due to some react-router issues  */

export const AnchorStyled = styled.a<{ type?: "link" | "disabled" }>`
  display: inline-flex;

  color: ${({ theme, type = "link" }): string =>
    type === "link"
      ? theme?.textColors?.BLUE_116
      : type === "disabled"
      ? theme?.textColors?.GREY_34
      : theme?.textColors?.BLACK_13};

  cursor: ${({ type = "link" }): string =>
    type === "disabled" ? "default" : "pointer"};

  :hover {
    color: ${({ theme, type = "link" }): string =>
      type === "link"
        ? theme?.textColors?.BLUE_116
        : type === "disabled"
        ? theme?.textColors?.GREY_34
        : theme?.textColors?.BLACK_13};
  }
`;
