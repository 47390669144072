import { SkeltonButtonBlankSlate } from "../../components/skeltonblankslate";
import { LinkTabsProps } from "../../components/tabs/linktabs/linktabs.types";
import { ELEMENT_IDS } from "../../constants";
import { SourceDetailPageParamViewKeys } from "./sourcedetailpage.types";

export const datasourceConditionalTabsIds = ["settings", "files"];

export const sourceDetailPageTabsValues: {
  [key in SourceDetailPageParamViewKeys]: SourceDetailPageParamViewKeys;
} = {
  overview: "overview",
  settings: "settings",
  files: "files",
  scan_history: "scan_history",
  scan_logs: "scan_logs",
  "ai-agents": "ai-agents",
};

export const sourceDetailPageTabs = (
  isLoading?: boolean,
  isExlSource?: boolean,
  isRestDataSource?: boolean,
  isChatEnabled?: boolean,
  tab?: SourceDetailPageParamViewKeys,
  botsCount?: number
): LinkTabsProps["tabs"] => {
  const {
    datsrc_detail_page_overview_tab: DATSRC_DETAIL_PAGE_OVERVIEW_TAB,
    datsrc_detail_page_scan_hist_tab: DATSRC_DETAIL_PAGE_SCAN_HIST_TAB,
    datsrc_detail_page_logs_tab: DATSRC_DETAIL_PAGE_LOGS_TAB,
    datsrc_detail_page_files_tab: DATSRC_DETAIL_PAGE_FILES_TAB,
    datsrc_detail_page_settings_tab: DATSRC_DETAIL_PAGE_SETTINGS_TAB,
    datsrc_detail_page_ai_agents_tab: DATSRC_DETAIL_PAGE_AI_AGENTS_TAB,
  } = ELEMENT_IDS;

  const isExcelTab = tab === sourceDetailPageTabsValues?.files;
  const isChatBotsTab = tab === sourceDetailPageTabsValues?.["ai-agents"];
  const isSettingsTab = tab === sourceDetailPageTabsValues?.settings;

  const tabs: any = [
    {
      key: sourceDetailPageTabsValues.overview,
      value: "Overview",
      disabled: isRestDataSource,
      id: isLoading ? "" : DATSRC_DETAIL_PAGE_OVERVIEW_TAB,
    },
    {
      key: sourceDetailPageTabsValues.scan_history,
      value: "Scan History",
      disabled: isRestDataSource,
      id: isLoading ? "" : DATSRC_DETAIL_PAGE_SCAN_HIST_TAB,
    },
    {
      key: sourceDetailPageTabsValues.scan_logs,
      value: "Logs",
      disabled: isRestDataSource,
      id: isLoading ? "" : DATSRC_DETAIL_PAGE_LOGS_TAB,
    },
  ];

  if (!isLoading) {
    if (isExlSource) {
      tabs.push({
        key: sourceDetailPageTabsValues.files,
        value: "Files",
        id: isLoading ? "" : DATSRC_DETAIL_PAGE_FILES_TAB,
      });
    }

    tabs.push({
      key: "settings",
      value: "Settings",
      id: isLoading ? "" : DATSRC_DETAIL_PAGE_SETTINGS_TAB,
    });

    if (isChatEnabled) {
      tabs.push({
        key: sourceDetailPageTabsValues["ai-agents"],
        value: `AI Agents (${botsCount || 0})`,
        id: isLoading ? "" : DATSRC_DETAIL_PAGE_AI_AGENTS_TAB,
      });
    }
  }

  if (isLoading) {
    tabs.push({
      key: "settings",
      value: isSettingsTab ? (
        "Settings"
      ) : isExcelTab ? (
        "Files"
      ) : (
        <SkeltonButtonBlankSlate active />
      ),
    });
  }
  return tabs;
};
