import { useScrollingUp } from "../../customhooks";
import StickySection from "../stickysection";
import { LinkTabs } from "../tabs";
import { TabsWithRightSecStyled } from "./tabswithrightsec.styles";
import { TabsWithRightSectionProps } from "./tabswithrightsec.types";

const elemId = "tabs-with-right-sec";

function TabsWithRightSection(props: TabsWithRightSectionProps): JSX.Element {
  const { tabs, children } = props;

  const isScrolled = useScrollingUp(elemId);

  return (
    <StickySection isScrolled={isScrolled} elemId={elemId}>
      <TabsWithRightSecStyled className="tabs-with-right-sec">
        <LinkTabs {...tabs} />
        {children}
      </TabsWithRightSecStyled>
    </StickySection>
  );
}

export default TabsWithRightSection;
