import styled, { createGlobalStyle, css } from "styled-components";
import { hexToRgbA } from "../utils";

export const GlobalStyles = createGlobalStyle<{ theme: any }>`
  html {
    .ant-menu-dark {
       
      .chat-menu-item{
          color: #b1bac1;

         :hover{
          color: #85c0ec;
          }
      }

      .ant-menu-item,
      .ant-menu-item:hover{ 
        background: #414b51; 
        margin: 0 !important;
        height: 45px; 
        padding-top: 2px;
        left: 0;
       
      }
    }
     
    body {
      font-family: OpenSans;
      font-size: 14px;
      color: #222a2d;

      .ag-theme-alpine {
        font-family: OpenSans;
      }
      .page-content-padding {
        background: #fff;
        margin: 0;
        padding: 8px 20px 24px;
      }
      .columnname-popover {
        padding-top: 0px;
        .ant-popover-content {
          .ant-popover-arrow {
            display: none;
          }
          .ant-popover-inner {
            border-radius: 10px;
            .ant-popover-inner-content {
              padding: 0px;
            }
          }
        }
      }

      .ant-select-item-empty{
        padding:0;
      }

      .ant-notification,
      .error-ant-notification {
        left: calc(50% - 200px) !important;
        max-width: 550px;
        .ant-notification-notice {
          &.desktop-app-center-notification {
            position: relative;
            left: -34px;
          }
          width: max-content;
          max-width: 550px;
          min-height: 56px;
          padding: 16px 15px 17px 22px;
          box-shadow: 0 3px 6px 0 rgba(45, 83, 10, 0.65);
          border: solid 1px
            ${({ theme }): string => theme.borderColors.GREEN_35};
          background-color: ${({ theme }): string => theme.bgColors.GREEN_40};
          margin-left: 0px;

          .ant-notification-notice-content {
            .ant-notification-notice-message {
              display: none;
            }
            .ant-notification-notice-description {
              color: ${({ theme }): string => theme.textColors.GREEN_35};
              display: flex;
              align-items: center;

              .action-text {
                font-family: OpenSansSemiBold;
                margin-right: 3px;
                display: flex;
                align-items: center;

                svg{
                  margin-right: 3px;
                }
              }

              a {
                color: ${({ theme }): string => theme.textColors.BLUE_116};
                text-decoration: underline;
              }

              .margin-right-8 {
                margin-right: 8px;
              }

              .bold-text {
                font-family: OpenSansSemiBold;
              }

              .bolder-text {
                font-family: OpenSansBold;
              }

              .space-text {
                > * {
                  margin-left: 4px;
                  margin-right: 4px;
                }
              }
            }
          }

          .ant-notification-notice-close {
            display: none;
          }
        }
        
      }

      .ant-notification {
        .share-analysis-notification {
          margin-left: 110px;
        }
      }

      .ant-drawer-content {
        .ant-notification {
          left: calc(100vw - 460px) !important;

          .ref-page-notification {
            left: -62px;
          }
        }
      }

      .error-ant-notification.ant-notification-notice {
        border: solid 1px ${({ theme }): string => theme.borderColors.RED_13};
        background-color: ${({ theme }): string => theme.bgColors.RED_13};
        margin-left: 0px;

        .ant-notification-notice-content {
          .ant-notification-notice-description {
            color: ${({ theme }): string => theme.textColors.RED_12};
            .error-type {
              display: flex;
              .action-text {
                svg {
                  margin-right: 3px;
                  path {
                    fill: ${({ theme }): string => theme.bgColors.RED_12};
                  }
                }
                display: inline-flex;
                align-items: center;
                font-family: OpenSansSemiBold;
                margin-right: 4px;
              }
            }
          }
        }
      }

      .info-ant-notification.ant-notification-notice {
        border: solid 1px ${({ theme }): string =>
          hexToRgbA(theme?.bgColors?.YELLOW_COLOR, 0.8)};
        background-color: ${({ theme }): string =>
          theme.borderColors.YELLOW_12};
        box-shadow: 0 2px 8px 0 ${({ theme }): string =>
          hexToRgbA(theme?.bgColors?.YELLOW_13, 0.3)};
        margin-left: 0px;

        .ant-notification-notice-content {
          .ant-notification-notice-description {
            color: ${({ theme }): string => theme.textColors.GREY_40};
          }
        }
      }

      .column-tooltip-content{
        .ant-tooltip-inner { 
            padding: unset;
            box-shadow: unset;
            background-color: transparent; 
                color: ${({ theme }): string => theme.textColors.GREY_26};
                margin-left:8px ;
          }

          .ant-tooltip-arrow-content {
              box-shadow: unset;
              background-color: ${({ theme }): string =>
                theme.bgColors.WHITE_COLOR};
              width: 0;
              height: 0;
            }
        
      }

      .dvsum-tooltip {
        max-width: 400px; 
        .ant-tooltip-content {
          .ant-tooltip-arrow {
            .ant-tooltip-arrow-content {
              box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
              background-color: ${({ theme }): string =>
                theme.bgColors.WHITE_COLOR};
              width: 8px;
              height: 8px;
            }
          }
          .ant-tooltip-inner {
            max-width: 400px; 
            padding: 13px 9.2px 14px 15px;
            box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
            background-color: ${({ theme }): string =>
              theme.bgColors.WHITE_COLOR};
            color: ${({ theme }): string => theme.textColors.GREY_26};
          }
        }
      }

      .dvsum-select-option-tooltip{
        .ant-tooltip-content {
          .ant-tooltip-arrow {
            .ant-tooltip-arrow-content {
              box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
              background-color: ${({ theme }): string =>
                theme.bgColors.WHITE_COLOR};
              width: 8px;
              height: 8px;
            }
          }
          .ant-tooltip-inner { 
            padding: 13px 9.2px 14px 15px;
            box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
            background-color: ${({ theme }): string =>
              theme.bgColors.WHITE_COLOR};
            color: ${({ theme }): string => theme.textColors.GREY_26};
          }
        }
      }

      .form-checkbox-tooltip {
        left: 340px !important; 
    .ant-tooltip-content {
      .ant-tooltip-arrow {
        .ant-tooltip-arrow-content {
          box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
          background-color: ${({ theme }): string =>
            theme.bgColors.WHITE_COLOR};
          width: 8px;
          height: 8px;
        }
      }
      .ant-tooltip-inner {
        width: 400px;
        min-height: 82px;
        padding: 16px 9.2px 18px 15px;
        box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
        background-color: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
        font-size: 13px;
        color: ${({ theme }): string => theme.textColors.GREY_26};
      }
    }
  }

  .info-icon-tooltip{
    padding-left: 0;
    .ant-tooltip-arrow{
      display: none;
    }
  }

      
      .term-info-tooltip,.show-comments-tooltip,.guide-me-tooltip,.exclusion-criteria-tooltip,.listing-question-renderer-tooltip, .title-tooltip{
        .ant-tooltip-inner{
          min-width: max-content;
        }
      }

      .delete-confirmation-tooltip{
        .ant-tooltip-inner{
          min-width: 350px;
        }
      }


      .chat-bot-save-button{
        .ant-tooltip-inner{
          min-width: 200px;
        }
      }

      .verbose-tooltip{
        .ant-tooltip-inner{
          min-width: 400px;
        }
      }

      .chatbar-title-tooltip{
        .ant-tooltip-inner { 
            padding:8px !important;
            
          }
      }
      .dvsum-data-import-info-tooltip {
        max-width: 540px;
        .ant-tooltip-content {
          .ant-tooltip-inner {
            max-width: 540px;
          }
        }
      }

      .dvsum-popularity-score-tooltip {
        max-width: 540px;

        .ant-tooltip-content {
          position: relative;
          left: -10px;

          .ant-tooltip-arrow {
            top: -13px;
          }

          .ant-tooltip-inner {
            max-width: 540px;
          }
        }
      }

      .dvsum-info-tooltip {
        max-width: 400px;
        .ant-tooltip-content {
          .ant-tooltip-arrow {
            .ant-tooltip-arrow-content {
              box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
              border: 1px solid
                ${({ theme }): string => theme.borderColors.LIGHT_BLUE_105};
              background-color: ${({ theme }): string =>
                theme.bgColors.LIGHT_BLUE_15};
              width: 8px;
              height: 8px;
            }
          }
          .ant-tooltip-inner {
            max-width: 400px;
            padding: 13px 9.2px 14px 15px;
            box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
            border: 1px solid
              ${({ theme }): string => theme.borderColors.LIGHT_BLUE_105};
            background-color: ${({ theme }): string =>
              theme.bgColors.LIGHT_BLUE_15};
          }
        }
      }

      .query-refresh-tooltip{
        max-width: 280px;
        .ant-tooltip-content {
          .ant-tooltip-arrow {
            .ant-tooltip-arrow-content {
              box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
              border: 1px solid
                ${({ theme }): string => theme.borderColors.DARK_BLUE_20}  ;
              background-color: ${({ theme }): string =>
                theme.bgColors.DARK_BLUE_20}  ;
              width: 8px;
              height: 8px;
            }
          }
          .ant-tooltip-inner {
            color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
            max-width: 280px;
            padding: 13px 9.2px 14px 15px;
            box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
            border: 1px solid
                ${({ theme }): string => theme.borderColors.DARK_BLUE_20};
              background-color: ${({ theme }): string =>
                theme.bgColors.DARK_BLUE_20} ; 
          }
        }
      }

      .dvsum-error-tooltip {
        .ant-tooltip-content {
          .ant-tooltip-arrow {
            .ant-tooltip-arrow-content {
              box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
              background-color: ${({ theme }): string =>
                theme.bgColors.RED_100};
              width: 8px;
              height: 8px;
            }
          }
          .ant-tooltip-inner {
            max-width: 300px;
            padding: 5px 10px;
            box-shadow: 0 0 7px 0 rgba(211, 211, 211, 0.85);
            background-color: ${({ theme }): string => theme.bgColors.RED_100};
            color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
          }
        }
      }
 

      .ant-tooltip-disabled-compatible-wrapper {
        display: flex !important;
      }

      .analysis-pinned-tables-dvsum-tooltip {
        max-width: 378px;
        .ant-tooltip-content {
          .ant-tooltip-inner {
            max-width: 378px;
          }
        }
      }

      .analytics-data-dictionary-tooltip {
        max-width: 220px;
        .ant-tooltip-content {
          .ant-tooltip-inner {
            max-width: 220px;
          }
        }
      }

      .ag-header-cell {
        .ag-header-icon {
          .ag-icon-menu {
            display: none;
          }
        }
      }

      .header-dropdown-menu {
        top: 44px !important;
        position: fixed;
      }
    }
    .caddi-maximized-qry-modal{
      bottom: -31px;
      overflow-x: hidden;
    }
  }
`;

export const ellipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const disabledContent = css`
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: all;
`;

export const multilineEllipsis = css`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
`;

export const LoadingSpinerStyled = styled.div`
  padding: 5px 5px 5px 10px;
  opacity: unset;
  color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
`;

export const WhiteSpacePreLineStyled = styled.span`
  white-space: pre-line;
  word-break: break-word;
`;

export const pulseAnimationCss = css`
  animation-timing-function: cubic-bezier(0, 0.42, 0.26, 0.96);
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: complementary-pulse-blue;

  @keyframes complementary-pulse-blue {
    0% {
      box-shadow: 0 0 0 2px #ff6915, 0 0 3px 3px rgb(255, 105, 21);
    }
    40% {
      box-shadow: 0 0 0 6px #ff6915, 0 0 7px 7px rgb(255, 105, 21);
    }
    100% {
      box-shadow: 0 0 0 2px #ff6915, 0 0 3px 3px rgb(255, 105, 21);
    }
  }
`;

export const AbsoluteElement = styled.div``;

export const MarkdownContainerStyled = styled.span`
  span,
  code,
  pre,
  strong {
    white-space: normal;
    font-family: OpenSans;
    word-break: break-word;
  }

  code,
  pre {
    white-space: pre-line;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: revert;
  }

  li {
    margin: 0.6em 0px 0.6em 1.2em;
    padding: 0px 0px 0px 0.6em;
  }

  ul {
    margin: 0px 0px 1.5rem;
    padding: 0px;
  }

  ol {
    margin: 0px 0px 1.5rem;
    padding: 0px;

    ul {
      margin-bottom: 0px;
    }
  }

  span,
  ol,
  ul,
  code,
  pre,
  li,
  strong,
  code,
  pre,
  h1,
  h2,
  h3,
  h4,
  h5,
  li,
  ol,
  ul {
    color: rgb(49, 51, 63);
  }
`;

export const AccessDialogueStyled = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;

  .readonly-chat-banner {
    .created-by {
      font-family: OpenSansSemiBold;
    }
  }
`;
