import dagre from "dagre";
import { Position, Edge } from "reactflow";

//        <--Types Start-->
import { LineageNode } from "../../../parsers/lineageparser/lineageparser.types";
import { JUNCTION_NODE_TYPE } from "./helper";
import { DQ_ALERT_NOTIFICATION_HEIGHT } from "../lineagepage.constants";
//        <--Types End-->

export const getLayoutedElements = (
  nodes: LineageNode[] = [],
  edges: Edge[] = [],
  direction = "TB"
): {
  nodes: LineageNode[];
  edges: Edge[];
} => {
  try {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const isHorizontal = direction === "LR";

    dagreGraph.setGraph({
      rankdir: direction,
      ranksep: 150,
      nodesep: 60,
    });

    // Filter only visible nodes for layout calculations
    const visibleNodes = nodes?.filter((node) => !node?.hidden);

    // Filter only visible edges for layout calculations
    const visibleEdges = edges?.filter((edge) => !edge?.hidden);

    // Add only visible nodes to the dagreGraph for layout calculation
    visibleNodes?.forEach((node) => {
      const isJunctionNode = node.type === JUNCTION_NODE_TYPE;
      const ranksep = isJunctionNode ? 80 : 150; // Tighter separation for junction nodes

      dagreGraph.setNode(node.id, {
        width: node.style?.width,
        height: node.style?.height,
      });

      dagreGraph.graph().ranksep = ranksep;
    });

    visibleEdges?.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    // Update positions and dimensions only for visible nodes
    visibleNodes?.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? Position.Left : Position.Top;
      node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).

      const nodeXAxisPos =
        nodeWithPosition.x - Number(node?.style?.width ?? 0) / 2;

      const nodeYAxisPos =
        nodeWithPosition.y - Number(node?.style?.height ?? 0) / 2;

      node.position = {
        x: nodeXAxisPos,
        y: nodeYAxisPos,
      };

      return node;
    });

    return { nodes, edges };
  } catch {
    return { nodes, edges };
  }
};
