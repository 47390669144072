import styled from "styled-components";
import { hexToRgbA } from "../../../../utils";

export const ExcelSourceFilesStyled = styled.div<{
  isEmpty: boolean;
  isGridExpanded?: boolean;
}>`
  padding-top: 0;
  padding-bottom: 24px;
  /* border: 1px solid
    ${({ theme }): string =>
    hexToRgbA(theme.borderColors.LIGHT_GREY_11, 0.2)}; */

  .empty-sec {
    display: ${({ isEmpty }): any => !isEmpty && "none"};
  }

  .non-empty-sec {
    border: ${({ theme, isGridExpanded }): string =>
      isGridExpanded
        ? ""
        : `1px solid ${hexToRgbA(theme.borderColors.LIGHT_GREY_11, 0.2)}`};
    display: ${({ isEmpty }): any => isEmpty && "none"};
  }
`;

export const DeleteExcelSourceFilesStyled = styled.div`
  .text {
    padding: 30.4px 28px 56px 28px;
  }
`;
