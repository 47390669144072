import styled from "styled-components";
import LinkButton from "../../../../../../../../../../components/linkbutton/linkbutton";

export const QueryBlockSettingsStyled = styled.div<{
  isChatPage: boolean;
  isMaximized: boolean;
}>`
  margin-left: 25.6px;
  background: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
  border-left: 1px solid ${({ theme }): string => theme?.borderColors?.GREY_56};
  border-right: 1px solid ${({ theme }): string => theme?.borderColors?.GREY_56};
  border-bottom: 1px solid
    ${({ theme }): string => theme?.borderColors?.GREY_56};
  border-top: 1px solid ${({ theme }): string => theme?.borderColors?.GREY_56};
  padding: 10px 15px;
  height: 100%;
  min-height: ${({ isChatPage }): string => (isChatPage ? `65%` : "")};

  .chart-settings-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    .header-left-sec {
      display: flex;
      column-gap: 8px;

      .heading {
        font-family: "OpenSansSemiBold";
      }

      .ant-btn-link {
        font-size: 12px;
        color: ${({ theme }): string => theme?.textColors?.BLUE_116};
      }
    }

    .header-right-sec {
      display: flex;
      align-items: center;

      .ant-btn-link {
        display: flex;
        align-items: center;
        margin-top: 4px;
      }
    }
  }
`;

export const ChartTabsSectionStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }): string => theme?.textColors?.GREY_56};
  padding-right: 13px;

  .tabs-list {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }

  .reset-btn {
    color: ${({ theme }): string => theme?.textColors?.BLUE_116};
    font-size: 12px;
  }
`;

export const TabStyled = styled(LinkButton)<{
  isSelected?: boolean;
  isTabDataNotExist?: boolean;
}>`
  &,
  &:hover,
  &:focus,
  span {
    font-size: 12px;
    font-weight: normal;
    color: ${({ theme, isSelected }): string =>
      isSelected ? theme?.textColors?.BLUE_116 : theme?.textColors?.GREY_210};

    border-bottom: 1px solid
      ${({ theme, isSelected }): string =>
        isSelected ? theme?.textColors?.BLUE_116 : "transparent"};
  }
`;
