import Flex from "../flex";
import GritterNotification from "../gritternotification/gritternotification";
import LinkButton from "../linkbutton/linkbutton";
import {
  OnlineProfilingProgressBarStyled,
  ProfilingProgressBarStyled,
} from "./onlineprofilingprogressbar.styles";
import { OnlineProfilingProgressBarProps } from "./onlineprofilingprogressbar.types";

const OnlineProfilingProgressBar = (
  props: OnlineProfilingProgressBarProps
): JSX.Element => {
  const {
    onCancelProfilingClick,
    progressPercenatge,
    textMessage,
    profilingStatus,
    profilingJobId,
    onClickRunProfileOnline,
    addGenericStyling = false,
  } = props;

  const isProfilingFailed = profilingStatus === "error";

  return (
    <OnlineProfilingProgressBarStyled addGenericStyling={addGenericStyling}>
      <div className="profiling-info-wrapper">
        <GritterNotification
          message={
            <div className="gritter-message">
              {textMessage}
              <span className="percentage">{progressPercenatge}%</span>
            </div>
          }
          showIcon={false}
          type={isProfilingFailed ? "error" : "info"}
          customJsx={
            <Flex columnGap={8}>
              {isProfilingFailed && (
                <LinkButton onClick={onClickRunProfileOnline}>
                  Try Again
                </LinkButton>
              )}
              {!!profilingJobId && (
                <LinkButton onClick={onCancelProfilingClick}>Cancel</LinkButton>
              )}
            </Flex>
          }
        />
        <ProfilingProgressBarStyled
          percent={progressPercenatge}
          showInfo={false}
          profilingStatus={isProfilingFailed ? "error" : "info"}
          gapPosition="bottom"
        />
      </div>
    </OnlineProfilingProgressBarStyled>
  );
};

export default OnlineProfilingProgressBar;
