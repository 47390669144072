import { Edge } from "reactflow";

export function traverseLineageBackward(
  data: Edge[],
  targetIds: string[],
  combineIds: string[],
  fetchAllConnected = true,
  includeTargetId = true,
  visited = new Set<string>() // Track visited nodes to prevent cycles
): string[] {
  // Add current targetIds to the combined list
  if (includeTargetId) {
    combineIds?.push(...targetIds);
  }

  // Find the parent nodes for the given targetIds
  const parentOfDataIds = data
    ?.filter((item) => targetIds?.includes(item?.target))
    ?.map((item) => item?.source);

  // Filter out already visited nodes to prevent cycles
  const unvisitedParents = parentOfDataIds?.filter(
    (parentId) => !visited?.has(parentId)
  );

  // Mark current targetIds as visited
  targetIds?.forEach((id) => visited?.add(id));

  try {
    if (unvisitedParents?.length) {
      // If fetchAllConnected is true, continue recursive traversal
      combineIds?.push(...unvisitedParents);
      if (fetchAllConnected) {
        return traverseLineageBackward(
          data,
          unvisitedParents,
          combineIds,
          fetchAllConnected,
          includeTargetId,
          visited
        );
      }
      // If fetchAllConnected is false, return only directly connected nodes
    }

    return [...new Set(combineIds)];
  } catch (error) {
    console.error("Error in traverseLineageBackward", error);
    return [];
  }
}
