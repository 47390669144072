import { AxiosResponse } from "axios";

import {
  LineageAlertNodesParsedType,
  LineageAlertNodesResponse,
  LineageResponse,
  LineageResponseRuleInfo,
  ReturnTypeOfParsedLineageData,
} from "./lineageparser.types";

import {
  getObjectKeys,
  jsonParse,
  sortListOnSpecificKeyValue,
} from "../../utils";

import {
  createColumnNodes,
  createEdges,
  createReportNodesAndEdges,
  createTableNode,
  edgeCommonProperties,
  edgeStyles,
  getUniqueDataOfNode,
} from "./lineageparser.util";

//                  <--Utils-->

export function getParsedLineageData({
  data = [],
}: AxiosResponse<LineageResponse>): ReturnTypeOfParsedLineageData {
  const isDrillDownMode = true;
  const url = new URL(window.location.href);
  const uniqueTables = getUniqueDataOfNode(data, "NODE_ID");

  const uniqueColumns = getUniqueDataOfNode(data, "COL_ID")?.filter(
    (item) => item?.NODE_SUB_TYPE !== "RPT"
  );

  const reportNodes = isDrillDownMode
    ? data?.filter((item) => item?.NODE_SUB_TYPE === "RPT")
    : [];

  const dsrAndRptNodes = uniqueTables?.filter(
    (item) => item?.NODE_TYPE === "DSR" || item?.NODE_SUB_TYPE === "RPT"
  );

  const remainingNodes = uniqueTables?.filter(
    (item) => item?.NODE_TYPE !== "DSR" && item?.NODE_SUB_TYPE !== "RPT"
  );

  const dsrAndRptNodesSourcesIds = [
    ...new Set(dsrAndRptNodes?.map((item) => item?.SRC_ID)),
  ];

  const seperateRptAndDsrOfSources: {
    [key: string]: { reports: LineageResponse; datasources: LineageResponse };
  } = dsrAndRptNodesSourcesIds?.reduce((prev, next) => {
    return {
      ...prev,
      [next]: {
        reports: dsrAndRptNodes?.filter(
          (item) => item?.SRC_ID === next && item?.NODE_SUB_TYPE === "RPT"
        ),
        datasources: dsrAndRptNodes?.filter(
          (item) =>
            item?.SRC_ID === next &&
            item?.NODE_TYPE === "DSR" &&
            item?.NODE_SUB_TYPE !== "RPT"
        ),
      },
    };
  }, {});

  const dsrAndRptSources = getObjectKeys(seperateRptAndDsrOfSources)?.map(
    (item) => {
      const reportsGroupSourceId = `${item}:${
        seperateRptAndDsrOfSources?.[item]?.reports
          ?.map((rpt) => rpt?.NODE_ID)
          ?.toString() || ""
      }`;
      const datasourcesGroupSourceId = `${item}:${
        seperateRptAndDsrOfSources?.[item]?.datasources
          ?.map((rpt) => rpt?.NODE_ID)
          ?.toString() || ""
      }`;
      return {
        tableNodes: [
          ...createTableNode(
            seperateRptAndDsrOfSources?.[item]?.datasources,
            datasourcesGroupSourceId,
            uniqueColumns
          ),
          ...createTableNode(
            seperateRptAndDsrOfSources?.[item]?.reports,
            reportsGroupSourceId,
            uniqueColumns
          ),
        ],
        edge: [
          {
            id: `${datasourcesGroupSourceId}:${reportsGroupSourceId}`,
            source: `${datasourcesGroupSourceId || "--"}`,
            target: `${reportsGroupSourceId || "--"}`,
            ...edgeCommonProperties,
            style: { ...edgeStyles, display: "none" },
          },
        ],
      };
    }
  );

  // const remainingSources = uniqueSources?.filter(
  //   (item) => !dsrAndRptNodesSourcesIds?.includes(item?.SRC_ID)
  // );

  // const dsrAndRptSourceNodes =
  //   dsrAndRptSources?.map((item) => item?.sourceNodes)?.flat() || [];

  const dsrAndRptSourcesNodes =
    dsrAndRptSources?.map((item) => item?.tableNodes)?.flat() || [];

  const dsrAndRptSourcesEdges =
    dsrAndRptSources?.map((item) => item?.edge)?.flat() || [];

  // const sourceNodes = [
  //   ...createSRCGroupNode(remainingSources),
  //   ...dsrAndRptSourceNodes,
  // ];

  const tableNodes = [
    ...createTableNode(remainingNodes, undefined, uniqueColumns),
    ...dsrAndRptSourcesNodes,
  ];

  const sortedUniqueColumns = sortListOnSpecificKeyValue({
    list: uniqueColumns,
    key: "COL_NAME",
  });

  const columnNodes = [...createColumnNodes(uniqueColumns)];
  const edges = [...createEdges(data)];

  const updatedEdges = edges?.map((edge) => {
    const edgePos = {
      source: edge?.source || "",
      target: edge?.target || "",
    };

    // const sourcesJustContainingDataSources = dsrAndRptSourceNodes?.filter(
    //   (src) => {
    //     return src?.dummyfeshelldata?.isGroupChildJustDataSources;
    //   }
    // );

    // const hadIdChangedSource = sourcesJustContainingDataSources?.find((src) => {
    //   const id = src?.id?.split(":")?.[0] || "";
    //   return edge?.target === id;
    // });

    // if (hadIdChangedSource) {
    //   const id = hadIdChangedSource?.id?.split(":")?.[0] || "";
    //   if (edge?.source === id) {
    //     edgePos.source = hadIdChangedSource?.id || "";
    //   } else if (edge?.target === id) {
    //     edgePos.target = hadIdChangedSource?.id || "";
    //   }
    // }

    return {
      ...edge,
      ...edgePos,
    };
  });

  const {
    nodes: reportPagesNodes = [],
    edges: reportPagesEdges = [],
  } = createReportNodesAndEdges(isDrillDownMode ? reportNodes : []);

  const combineNodes =
    [
      // ...sourceNodes,
      ...tableNodes,
      ...columnNodes,
      ...reportPagesNodes,
    ]?.filter((item) => item?.id !== item?.parentNode) || [];

  const combineNodesIds = combineNodes?.map((nestItem) => nestItem?.id);

  const validNodes = combineNodes?.filter((item) =>
    item?.extent === "parent"
      ? combineNodesIds?.includes(item?.parentNode || "")
      : true
  );

  const finalEdges = [
    ...updatedEdges,
    ...dsrAndRptSourcesEdges,
    ...reportPagesEdges,
  ];

  const sortedNodes = validNodes?.sort((a, b) =>
    a?.data?.nodeName?.localeCompare(b?.data?.nodeName)
  );

  return {
    edges: finalEdges,
    nodes: sortedNodes,
  };
}

export function getParsedLineageAlertNodesData({
  data = [],
}: AxiosResponse<LineageAlertNodesResponse>): LineageAlertNodesParsedType {
  const sortedData = sortListOnSpecificKeyValue({
    list: data,
    key: "TBL_TITLE",
  });

  const alertingNodes =
    sortedData?.map((item) => {
      const dqRulesInfo = item?.DQ_RULES || "";

      const dqRules = jsonParse(dqRulesInfo, true) as LineageResponseRuleInfo[];

      return {
        tableName: item?.TBL_TITLE || "",
        alertingRulesCount: dqRules?.length || 0,
        run_date: item?.MAX_RULE_EXEC_ON || "",
      };
    }) || [];

  return alertingNodes;
}
