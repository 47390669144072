import { getDvSumInformativeText } from "../../../../utils";

import {
  FieldBase,
  SchemaType,
  SectionField,
} from "../../dynamicform/dynamicform.types";
import { AddMetricFormConfigProps } from "./addcomparemetricform.types";

export const addCompareMetricFormConfig = (
  props: AddMetricFormConfigProps
): SchemaType => {
  const {
    sourceSelectOptions,
    columnSelectOptions,
    onSourceChange,
    onTableChange,
    tablesSelectOptions,
    selectedTableFields,
    selectedTableMetricFields,
    onMetricTypeChange,
    parsedCompareMetricTypesOptions,
    parsedMetricTypesOptions,
    sortedMetricColumnOptions,
    existingFilters,
    existingRefFilters,
    isEdit,
    setValue,
  } = props;

  const {
    dat_dict_dat_qual_add_new_rle_dta_dif_col: {
      description: datDictDatQualAddNewRleDtaDifCol = "",
    } = {},
    dat_dict_dat_qual_add_new_rle_dta_dif_mtr: {
      description: datDictDatQualAddNewRleDtaDifMtr = "",
    } = {},
    dat_dict_dat_qual_add_new_rle_dta_dif_ref_col: {
      description: datDictDatQualAddNewRleDtaDifRefCol = "",
    } = {},
    dat_dict_dat_qual_add_new_rle_dta_dif_var_per: {
      description: datDictDatQualAddNewRleDtaDifVarPer = "",
    } = {},
    dat_dict_dat_qual_add_new_rle_dta_dif_comp_typ_ful: {
      description: datDictDatQualAddNewRleDtaDifCompTypFul = "",
    } = {},
    dat_dict_dat_qual_add_new_rle_dta_dif_comp_typ_inr: {
      description: datDictDatQualAddNewRleDtaDifCompTypInr = "",
    } = {},
    dat_dict_dat_qual_add_new_rle_dta_dif_comp_typ_lft: {
      description: datDictDatQualAddNewRleDtaDifCompTypLft = "",
    } = {},
    dat_dict_dat_qual_add_new_rle_dta_dif_comp_typ_rt: {
      description: datDictDatQualAddNewRleDtaDifCompTypRt = "",
    } = {},
    dat_dict_dat_qual_add_new_rle_dta_dif_ref_mtr: {
      description: datDictDatQualAddNewRleDtaDifRefMtr = "",
    } = {},
    dat_dict_dat_qual_add_new_rle_dta_dif_flt: {
      description: datDictDatQualAddNewRleDtaDifFlt = "",
    } = {},
    dat_dict_dat_qual_add_new_rle_dta_dif_ref_flt: {
      description: datDictDatQualAddNewRleDtaDifRefFlt = "",
    } = {},
  } = getDvSumInformativeText();

  const VARIANCE_PERCENT_INPUT_FIELD_MAP: FieldBase = {
    id: "variance_percent",
    type: "input",
    name: "variance_percent",
    label: "Variance Percent",
    required: true,
    description: datDictDatQualAddNewRleDtaDifVarPer,
    typeOfInputField: "number",
  };

  const COMAPRISON_TYPE_RADIO_BUTTON_FIELD_MAP: FieldBase = {
    id: "comparison_type",
    type: "radio_group",
    name: "comparison_type",
    label: "Comparison Type",
    options: parsedCompareMetricTypesOptions,
  };

  const METRIC_TYPE_INPUT_FIELD_MAP: FieldBase = {
    id: "metric_type",
    type: "select",
    name: "metric_type",
    label: "Metric Type",
    required: true,
    placeholder: "Select",
    onChange: onMetricTypeChange,
    options: parsedMetricTypesOptions || [],
    propOnClear: () => {
      setValue?.("metric_type", "CNT");
    },
  };

  const COLUMNS_INPUT_FIELD_MAP: FieldBase = {
    id: "columns",
    type: "select",
    name: "columns",
    label: "Column(s)",
    required: false,
    options: columnSelectOptions,
    description: datDictDatQualAddNewRleDtaDifCol,
    placeholder: "Select one or more",
    multiSelect: true,
  };

  const REQUIRED_COLUMNS_INPUT_FIELD_MAP: FieldBase = {
    id: "columns",
    type: "select",
    name: "columns",
    label: "Column(s)",
    required: true,
    options: columnSelectOptions,
    description: datDictDatQualAddNewRleDtaDifCol,
    placeholder: "Select one or more",
    multiSelect: true,
    showSearch: true,
  };

  const METRIC_COLUMNS_INPUT_FIELD_MAP: FieldBase = {
    id: "metrics",
    type: "select",
    name: "metrics",
    label: "Metric Column(s)",
    required: true,
    options: sortedMetricColumnOptions || [],
    description: datDictDatQualAddNewRleDtaDifMtr,
    placeholder: "Select one or more",
    multiSelect: true,
    showSearch: true,
  };

  const REFEREENCE_SOURCE_SELECT_FIELD_MAP: FieldBase = {
    id: "reference_source",
    type: "select",
    name: "reference_source",
    label: "Reference Source",
    required: true,
    options: sourceSelectOptions,
    placeholder: "Select",
    showSearch: true,
    onChange: onSourceChange,
  };

  const REFEREENCE_TABLE_SELECT_FIELD_MAP: FieldBase = {
    id: "reference_table",
    type: "select",
    name: "reference_table",
    label: "Reference Table",
    required: true,
    options: tablesSelectOptions?.options,
    isLoadingFieldData: tablesSelectOptions?.isLoading,
    placeholder: "Select",
    showSearch: true,
    onChange: onTableChange,
  };

  const REFEREENCE_COLUMNS_SELECT_FIELD_MAP: FieldBase = {
    id: "reference_columns",
    type: "select",
    name: "reference_columns",
    label: "Reference Column(s)",
    required: false,
    options: selectedTableFields?.options,
    isLoadingFieldData: selectedTableFields?.isLoading,
    description: datDictDatQualAddNewRleDtaDifRefCol,
    placeholder: "Select one or more",
    multiSelect: true,
    showSearch: true,
  };

  const REQUIRED_REFEREENCE_COLUMNS_SELECT_FIELD_MAP: FieldBase = {
    id: "reference_columns",
    type: "select",
    name: "reference_columns",
    label: "Reference Column(s)",
    required: true,
    options: selectedTableFields?.options,
    isLoadingFieldData: selectedTableFields?.isLoading,
    description: datDictDatQualAddNewRleDtaDifRefCol,
    placeholder: "Select one or more",
    multiSelect: true,
    showSearch: true,
  };

  const REFEREENCE_METRIC_COLUMNS_SELECT_FIELD_MAP: FieldBase = {
    id: "reference_metrics",
    type: "select",
    name: "reference_metric",
    label: "Reference Metric Column(s)",
    required: true,
    options: selectedTableMetricFields,
    description: datDictDatQualAddNewRleDtaDifRefMtr,
    placeholder: "Select one or more",
    multiSelect: true,
    showSearch: true,
  };

  const FILTER_CONDITION_FIELD_MAP: FieldBase = {
    id: "filter_criteria",
    type: "filter",
    name: "filter_criteria",
    label: "Filter Condition",
    // description: datDictDatQualAddNewRleDtaDifFlt,
    existingFilters,
  };

  const REFERENCE_FILTER_CONDITION_FIELD_MAP: FieldBase = {
    id: "reference_filter_criteria",
    type: "filter",
    name: "reference_filter_criteria",
    label: "Reference Filter Condition",
    description: datDictDatQualAddNewRleDtaDifRefFlt,
    existingFilters: existingRefFilters,
    isRefFilter: true,
  };

  return [
    ...((!isEdit && [
      {
        id: "basic",
        type: "section",
        fields: [
          {
            id: "rule_type",
            type: "select",
            name: "rule_type",
            label: "Rule Type",
            required: true,
            disabled: true,
          },
          {
            id: "rule_description",
            type: "text_area",
            name: "rule_description",
            label: "Rule Description",
            placeholder: "Description",
            description: "",
          },
        ],
      } as SectionField,
    ]) ||
      []),
    {
      id: "basic_input_sum_avg_min_max_cd",
      type: "section",
      title: "Basic Input",
      fields: [
        METRIC_TYPE_INPUT_FIELD_MAP,
        REQUIRED_COLUMNS_INPUT_FIELD_MAP,
        METRIC_COLUMNS_INPUT_FIELD_MAP,
        FILTER_CONDITION_FIELD_MAP,
      ],
    },
    {
      id: "basic_input_cnt",
      type: "section",
      title: "Basic Input",
      fields: [
        METRIC_TYPE_INPUT_FIELD_MAP,
        COLUMNS_INPUT_FIELD_MAP,
        FILTER_CONDITION_FIELD_MAP,
      ],
    },
    {
      id: "reference_input_sum_avg_min_max_cd",
      type: "section",
      title: "Reference Input",
      fields: [
        REFEREENCE_SOURCE_SELECT_FIELD_MAP,
        REFEREENCE_TABLE_SELECT_FIELD_MAP,
        REQUIRED_REFEREENCE_COLUMNS_SELECT_FIELD_MAP,
        REFEREENCE_METRIC_COLUMNS_SELECT_FIELD_MAP,
        REFERENCE_FILTER_CONDITION_FIELD_MAP,
      ],
    },
    {
      id: "reference_input_cnt",
      type: "section",
      title: "Reference Input",
      fields: [
        REFEREENCE_SOURCE_SELECT_FIELD_MAP,
        REFEREENCE_TABLE_SELECT_FIELD_MAP,
        REFEREENCE_COLUMNS_SELECT_FIELD_MAP,
        REFERENCE_FILTER_CONDITION_FIELD_MAP,
      ],
    },
    {
      id: "delta_comparison_sum_avg_min_max_cnt_cd",
      type: "section",
      title: "Delta Comparison",
      fields: [
        VARIANCE_PERCENT_INPUT_FIELD_MAP,
        COMAPRISON_TYPE_RADIO_BUTTON_FIELD_MAP,
      ],
    },
  ];
};
