import styled from "styled-components";

export const QueryChartSettingsStyled = styled.div<{
  isChatPage: boolean;
  isMaximized: boolean;
  isChartVisible: boolean;
}>`
  height: ${({ isMaximized }): string => (isMaximized ? "88vh" : "")};
  min-height: ${({ isChatPage }): string => (isChatPage ? `65%` : "")};
  overflow-y: hidden;

  .settings-container {
    margin-top: 12px;
    height: calc(100% - -3%);
  }

  .chat-settings-content-container {
    padding-bottom: 15px;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    .content-sec {
      max-width: 100%;
    }

    .sec-title {
      font-family: "OpenSansSemiBold";
      padding-bottom: 9.6px;
      background: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
    }

    .tag-wrapper-btn {
      width: 90%;
    }

    .y-axis-sec-title {
      margin-top: 19.2px;
    }

    .not-visualized-sec-title {
      padding-bottom: 0;
      margin-top: 19.2px;
    }

    .y-axis-tag-item {
      display: flex;
      column-gap: 6.4px;
    }

    .y-axis-sec {
      margin-bottom: 19.2px;
    }

    .not-visualized-desc {
      font-size: 12px;
      margin-bottom: 19.2px;
    }

    .attr-dates-sec {
      margin-bottom: 19.2px;
    }

    .metric-fields-sec {
      .sec-title {
        margin-bottom: 6.4px;
      }

      .validation-text {
        color: #d15b47;
        font-size: 10.4px;
        font-style: italic;
        margin-bottom: 9.6px;
      }
    }
  }
`;
