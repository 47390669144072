import { useDispatch } from "react-redux";
import { Fragment, useCallback } from "react";
import { Link } from "react-router-dom";

//               <--Constants-->
import { APP_ROUTES } from "../../constants";
//               <--Constants-->

//               <--Styles-->
import { BreadcrumStyled } from "./breadcrum.styles";
//               <--Styles-->

//               <--SVGs-->
import { chevronRight, homeIcon } from "../../svgs";
//               <--SVGs-->

//               <--Types-->
import { BreadcrumProps } from "./breadcrum.types";

import { useGetAppState } from "../../customhooks";

import { setExpandableGridProps } from "../../reducers/appreducer/appreducer";
import LinkButton from "../linkbutton";
//               <--Types-->

const BreadCrumItem = ({
  text = "",
  onClick,
  isExpandedGridView = false,
}: {
  text: string;
  onClick: () => void;
  isExpandedGridView?: boolean;
}): JSX.Element => {
  return (
    <LinkButton
      onClick={onClick}
      className={`breadcrum-item-text${isExpandedGridView ? "-expanded" : ""}`}
    >
      {text}
    </LinkButton>
  );
};

function BreadCrum({
  currentPage,
  currentPages,
  listingNodeType = "ADM",
}: BreadcrumProps): JSX.Element {
  const {
    expandableGridProps: { isGridExpanded: isGridExpandedAppState } = {
      isGridExpanded: false,
    },
  } = useGetAppState();
  const dispatch = useDispatch();

  const onCollapseExpandedGrid = useCallback(() => {
    dispatch(setExpandableGridProps({ isGridExpanded: false }));
  }, [listingNodeType]);

  const isExpandedGridView = isGridExpandedAppState;

  return (
    <BreadcrumStyled>
      <div className="breadcrum-content">
        <div className="breadcrum-item">
          {homeIcon()}
          <Link
            to={APP_ROUTES.private_routes.home}
            data-testid="breadcrum-home-link"
          >
            Home
          </Link>
        </div>
        {currentPages?.length
          ? currentPages.map((path) => (
              <Fragment key={`key-bread-crumb-header-${path?.label}`}>
                <span className="sep-sec">{chevronRight}</span>
                <div className="paths-container">
                  {path?.link ? (
                    <Link
                      className={`${
                        path.link ? "path-link" : "breadcrum-item"
                      }`}
                      to={path.link ? path.link : "#"}
                      data-testid={`breadcrum-${path?.label}-link`}
                    >
                      {path.label}
                    </Link>
                  ) : (
                    <BreadCrumItem
                      text={path?.label}
                      onClick={onCollapseExpandedGrid}
                      isExpandedGridView={isExpandedGridView}
                    />
                  )}
                </div>
              </Fragment>
            ))
          : currentPage && (
              <>
                <div className="sep-sec">{chevronRight}</div>
                <BreadCrumItem
                  text={currentPage?.label}
                  onClick={onCollapseExpandedGrid}
                  isExpandedGridView={isExpandedGridView}
                />
              </>
            )}
      </div>
    </BreadcrumStyled>
  );
}

export default BreadCrum;
