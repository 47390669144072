import { useCallback } from "react";
import { useHistory } from "react-router";

//      <---Components--->
import CoreNodesCircleIcon from "../corenodetypescircleicon";
import Flex from "../flex";

import LinkButton from "../linkbutton";
//      <---Components--->

//      <---Custom Hooks--->
import {
  useGetAppType,
  useOpenDrawer,
  useQueryParams,
} from "../../customhooks";
//      <---Custom Hooks--->

//      <---SVGS--->
import { alertBlankSlate, longDownArrow } from "./lineagepreviewer.svgs";
//      <---SVGS--->

//      <---Styles--->
import {
  AlertMessageWrapperStyled,
  LineageNodesPreviewerStyled,
  LineagePreviewerStyled,
  LineageSingleSecStyled,
  LineageSingleSecWrapperStyled,
  LineageWrapperStyled,
} from "./lineagepreviewer.styles";
//      <---Styles--->

//      <---Styles--->
import {
  getDetailpageUrlBasedOnNodeType,
  getDrawerIdBasedOnNodeType,
  getUserPermissions,
  jsonStringify,
} from "../../utils";
//      <---Styles--->

//      <---Styles--->
import { LineagePreviewerProps } from "./lineagepreviewer.types";
import GritterNotification from "../gritternotification";

import { APP_ROUTES } from "../../constants";
import { getLineageUrl } from "../../utils/getlineageurl";

import { getLineagePageQueryParams } from "../../pages/lineagepage/lineagepage.utils";

import { useGetLineageAlertNodesData } from "../../api/lineagepageservice/lineagepageservice";

import StateHandler from "../statehandler";
import {
  LineagePageQueryNodeParamsType,
  LineageUrlNodesType,
} from "../../pages/lineagepage/lineagepage.types";
import StyledLink from "../styledlink/styledlink";
import ConditionalWrapper from "../conditionalwrapper";
import LineageAlertMessage from "../lineagealertmessage";

//      <---Styles--->

const keysOfData: Array<keyof LineagePreviewerProps["data"]> = [
  "level1",
  "level2",
  "level3",
  "level4",
  "level5",
];

function LineagePreviewer(props: LineagePreviewerProps): JSX.Element {
  const { data, isRefView, align, marginBottom, lineageExists = false } = props;

  const history = useHistory();
  const params = useQueryParams();

  const lineageQueryParams = getLineagePageQueryParams(params);

  const openDrawer = useOpenDrawer();
  const { isExtOrDesktop } = useGetAppType();

  const filteredKeys = keysOfData?.filter((item) => data?.[item]?.length);

  const getNodeUrl = useCallback((id, nodeType, nodeSubType) => {
    return getDetailpageUrlBasedOnNodeType(id, nodeType, nodeSubType);
  }, []);

  const openDrawerBasedOnNodeType = useCallback((id, nodeType, nodeSubType) => {
    const drawerId = getDrawerIdBasedOnNodeType(nodeType, nodeSubType);
    if (drawerId) {
      openDrawer({
        drawerId,
        visible: true,
        drawerProps: { id },
      });
    }
  }, []);

  const { has_no_lineage_access: hasNoLineageAccess } = getUserPermissions();

  const baseNodeId = data?.level3?.[0]?.id || "";
  const baseNodeType = data?.level3?.[0]?.nodeType || "TBL";
  const baseNodeName = data?.level3?.[0]?.nodeName || "";
  const baseNodeSubType = data?.level3?.[0]?.nodeSubType;

  const { parsedData, isLoading, error } = useGetLineageAlertNodesData(
    baseNodeId,
    baseNodeType
  );

  const onViewAlertClick = useCallback(() => {
    const isBaseNodeColOrDSF = baseNodeType === "COL" || baseNodeType === "DSF";

    const lineageLevel = isBaseNodeColOrDSF ? "column" : "table";
    const nodeLevel = isBaseNodeColOrDSF ? 2 : 1;

    const lineageNode: LineagePageQueryNodeParamsType = {
      isFocused: true,
      isExpanded: false,
      nodeId: `${baseNodeId}`,
      nodeType: baseNodeType,
      nodeLevel,
      nodeName: baseNodeName,
      oneDownstream: "show",
      oneUpstream: "show",
      isBaseNode: true,
    };

    const nodeInConsideration: LineageUrlNodesType = {
      [baseNodeId]: lineageNode,
    };
    const allNodes = jsonStringify(nodeInConsideration);

    const newLineageUrl = getLineageUrl({
      ...lineageQueryParams,
      originNodeId: baseNodeId,
      originNodeName: baseNodeName,
      originNodeType: baseNodeType,
      originNodeSubType: baseNodeSubType,
      nodes: allNodes,
      lineageLevel,
      lineageType: "functional",
      mode: "dq_alert",
      enabledLayer: "DQ",
      isShowAlert: "1",
      expandedJunctionNodes: "",
      isFromDqAlert: "1",
    });

    window.open(newLineageUrl);
  }, [data, lineageQueryParams]);

  const alertMessage = (
    <LineageAlertMessage
      onClick={onViewAlertClick}
      alertingRulesInfo={parsedData}
    />
  );

  const isAnyAlertingNodeExists = parsedData?.length > 0;

  return (
    <LineagePreviewerStyled align={align} marginBottom={marginBottom}>
      <StateHandler
        isFetching={isLoading}
        error={error}
        blankSlate={alertBlankSlate}
      >
        {!hasNoLineageAccess && isAnyAlertingNodeExists && lineageExists ? (
          <AlertMessageWrapperStyled>
            <GritterNotification
              message={alertMessage}
              type="error"
              addBorder
              showCancelIcon={false}
            />
          </AlertMessageWrapperStyled>
        ) : (
          <span />
        )}
      </StateHandler>
      <LineageNodesPreviewerStyled className="lineage-previewer">
        <LineageWrapperStyled>
          {filteredKeys?.map((item, index) => {
            const isCurrentNode = data?.[item]?.some(
              (node) => node?.isCurrentNode
            );

            return (
              <LineageSingleSecWrapperStyled
                key={`lineage-prviewer-sec${item}`}
              >
                <LineageSingleSecStyled isCurrent={isCurrentNode}>
                  {data?.[item]?.map((nestItem) => {
                    const { id = "", nodeName = "", nodeType, nodeSubType } =
                      nestItem || {};

                    return (
                      <Flex
                        columnGap={5}
                        key={`${id}:${nodeName}:${nodeSubType}:${nodeType}`}
                      >
                        <div>
                          <CoreNodesCircleIcon
                            nodeType={nodeType}
                            nodeSubType={nodeSubType}
                            width="18px"
                            height="18px"
                            mode={isCurrentNode ? "secondary" : "primary"}
                            fontSize={nodeType === "DSF" ? "8px" : "10px"}
                          />
                        </div>

                        <div title={nodeName} className="current-node">
                          <ConditionalWrapper
                            condition={!isCurrentNode}
                            wrapper={(child): JSX.Element => (
                              <StyledLink
                                title={nodeName}
                                to={
                                  isRefView
                                    ? getNodeUrl(id, nodeType, nodeSubType)
                                    : ""
                                }
                                cutomClassName="lineage-node-link"
                                {...(!isRefView && {
                                  propsOnClick: (): void =>
                                    openDrawerBasedOnNodeType(
                                      id,
                                      nodeType,
                                      nodeSubType
                                    ),
                                })}
                                target={isExtOrDesktop ? "_blank" : "_self"}
                              >
                                {child}
                              </StyledLink>
                            )}
                          >
                            <span> {nodeName}</span>
                          </ConditionalWrapper>
                        </div>
                      </Flex>
                    );
                  })}
                </LineageSingleSecStyled>
                {index + 1 !== filteredKeys?.length && longDownArrow}
              </LineageSingleSecWrapperStyled>
            );
          })}
        </LineageWrapperStyled>
      </LineageNodesPreviewerStyled>
    </LineagePreviewerStyled>
  );
}

export default LineagePreviewer;
