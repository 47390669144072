import { APP_ROUTES } from "../constants";
import { LineagePageUrlParamsType } from "../pages/lineagepage/lineagepage.types";

export const getLineageUrl = (
  queryParams: LineagePageUrlParamsType
): string => {
  const {
    nodes = "",
    enabledLayer,
    lineageLevel = "table",
    lineageType = "functional",
    searchQuery = "",
    mode = "",
    expandedJunctionNodes = "",
    isShowAlert,
    originNodeId = "",
    originNodeName = "",
    originNodeSubType,
    originNodeType = "",
    recalculateLayout = "0",
    resetFitView = "0",
    isFromDqAlert = "0",
  } = queryParams || {};

  const finalNodesParam = encodeURIComponent(nodes);

  return `${APP_ROUTES.private_routes.lineage}?originNodeId=${originNodeId}&originNodeName=${originNodeName}&originNodeType=${originNodeType}&originNodeSubType=${originNodeSubType}&enabledLayer=${enabledLayer}&lineageType=${lineageType}&lineageLevel=${lineageLevel}&searchQuery=${searchQuery}&mode=${mode}&expandedJunctionNodes=${expandedJunctionNodes}&isShowAlert=${isShowAlert}&recalculateLayout=${recalculateLayout}&resetFitView=${resetFitView}&isFromDqAlert=${isFromDqAlert}&nodes=${finalNodesParam}`;
};
