import { useQueryClient } from "react-query";

import { useForm, FormProvider } from "react-hook-form";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";

import {
  AddWidgetFormStyled,
  AddWidgetModalStyled,
  ChartImageWrapperStyled,
  ChartNameAndIconWrapper,
  FilterNameAndDescWrapperStyled,
  FlexDiv,
  FormInputAndChartWrapperStyled,
  FormInputWrapperStyled,
} from "./addwidgetform.styles";

import {
  AddWidgetFormPropsType,
  AddWidgetFormType,
  SelectedWidgetParsedType,
  SubOptionsMappingType,
  WidgetFieldType,
} from "./addwidgetform.types";

import {
  useCancelModal,
  useGetAppState,
  useOpenModal,
  useSetData,
} from "../../../../../../customhooks";

import { useGetRefByType } from "../../../../../../api/refservice";

import { useGetGovernanceViewFields } from "../../../../../../api/listingsservice";
import {
  checkDataTypeOfColumn,
  getTimezone,
  selectFilterOption,
  sortListOnSpecificKeyValue,
  sortObjectsArrayByKey,
} from "../../../../../../utils";

import { editPencilIcon } from "../../../../../../svgs";
import Flex from "../../../../../flex";

import {
  combineRefsIdsAndName,
  COUNT_METRIC,
  createWidgetApiData,
  getMetricValuesFromApi,
  getSlicerValuesFromApi,
  getSortOptions,
  getSortValuesArray,
  WIDGET_FILTER_CRITERIA_ID,
} from "./addwidgetform.utils";

import StateHandler from "../../../../../statehandler/statehandler";
import { FormStyled } from "../../../../../form";

import FormItemLabel from "../../../../../form/formitemlabel/formitemlabel";
import {
  CheckboxField,
  InputField,
  RadioGroupField,
  SelectField,
} from "../../../../../formfields";

import LinkButton from "../../../../../linkbutton/linkbutton";

import {
  CheckboxWrapperStyled,
  FormItemStyled,
} from "../../../../../form/form.styles";

import SelectWithSubOptions from "../../../../../selectwithsuboptions/selectwithsuboptions";
import Button from "../../../../../button";

import { useWidgetCreationPageContext } from "../../../../../../contexts/widgetcreationcontext";
import {
  ChartType,
  getChartIcon,
  getChartImage,
} from "../../../../../../utils/getcharticon";

import { addWidgetFormSchema } from "../../../../../../utils/schemas/addwidgetformschema";

import { WidgetCreationCurrentViewId } from "../../../../../../contexts/widgetcreationcontext/widgetcreationcontext.types";
import { getChartAdhocInfo } from "../../widgetcreationwizard.util";

import { TreeSelectFieldType } from "../../../../../formfields/selectfield/selectfield.types";
import {
  appendQueryParamInUrl,
  useRequestWithMethod,
} from "../../../../../../api";

import { useGetDashboardFilters } from "../../../../../../api/dashboardservcie";

import {
  getFiltersSelectOptions,
  getOptionsForSelectWithoutSubMenu,
  getOptionsForSelectWithSubMenu,
  getSelectSubOptions,
} from "./addwidgetform.renderer";

import {
  AddFilterCriteriaFormModalPropsType,
  AddWidgetFilterCriteriaFormType,
} from "../../../addfiltercriteriaform/addfiltercriteriaform.types";
import { API_CONFIG } from "../../../../../../constants/apiconfig";
import { ELEMENT_IDS } from "../../../../../../constants";

const editPencil = editPencilIcon("11", "11");

const setValueConfig = {
  shouldDirty: true,
  shouldValidate: true,
};

const {
  dash_add_wgt_form_ttl_field: DASH_ADD_WGT_FORM_TTL_FIELD,
  dash_add_wgt_form_mod_field: DASH_ADD_WGT_FORM_MOD_FIELD,
  dash_add_wgt_form_mod_flt_field: DASH_ADD_WGT_FORM_MOD_FLT_FIELD,
  dash_add_wgt_form_chr_typ_field: DASH_ADD_WGT_FORM_CHR_TYP_FIELD,
  dash_add_wgt_form_enb_data_lbl_check: DASH_ADD_WGT_FORM_ENB_DATA_LBL_CHECK,
  dash_add_wgt_form_slic_field: DASH_ADD_WGT_FORM_SLIC_FIELD,
  dash_add_wgt_form_metr_field: DASH_ADD_WGT_FORM_METR_FIELD,
  dash_add_wgt_form_sort_by_field: DASH_ADD_WGT_FORM_SORT_BY_FIELD,
  dash_add_wgt_form_sort_by_prop_field: DASH_ADD_WGT_FORM_SORT_BY_PROP_FIELD,
  dash_add_wgt_save_btn: DASH_ADD_WGT_SAVE_BTN,
} = ELEMENT_IDS;

const AddWidgetForm = (props: AddWidgetFormPropsType): JSX.Element => {
  const { propsOnSuccess } = props;
  const onCancel = useCancelModal();
  const openModal = useOpenModal();
  const onSetData = useSetData();
  const queryClient = useQueryClient();
  const sortOptions = useRef<null | HTMLDivElement>(null);

  const { state, updateState } = useWidgetCreationPageContext();

  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    governanceViewCategories = [],
    isEditMode = false,
    isCloneMode = false,
    dashboardId,
    selectedWidgetConfig,
    selectedWidgetType,
  } = state || {};

  const isWidgetAlreadyExists = isEditMode || isCloneMode;

  const {
    widgetName = "",
    widgetOrder = 1,
    widgetChartType = "COL",
    govViewCategory = "",
    enableDataLabels,
    sortType,
    metricFields: selectedWidgetMetricFields = [],
    slicerFields: selectedWidgetSlicerFields = [],
    sortFieldId,
    initialWidgetFilterCriteria,
    widgetId,
    isWidgetFullScreen = false,
  } = (selectedWidgetConfig as SelectedWidgetParsedType) || {};

  const {
    filterName = "",
    filterSql = "",
    filterJson,
    isTemplateFilter: isAlreadyAddedFilterIsTemplateLevel = false,
  } = initialWidgetFilterCriteria || {};

  const initialSlicerFields =
    selectedWidgetSlicerFields?.map((item: WidgetFieldType) => {
      return getSlicerValuesFromApi(item);
    }) || [];

  const initialMetricFields =
    selectedWidgetMetricFields?.map((item: WidgetFieldType) => {
      return getMetricValuesFromApi(item);
    }) || [];

  const initialSortFields = [...initialMetricFields, ...initialSlicerFields];

  const searchSortField = initialSortFields?.find((item) =>
    item?.includes(String(sortFieldId))
  );

  const initialModuleFilterCriteriaId = filterName
    ? `${WIDGET_FILTER_CRITERIA_ID}-${widgetId}`
    : "";

  const addWidgetForm = useForm<AddWidgetFormType>({
    defaultValues: {
      title: widgetName,
      module: govViewCategory,
      module_filter_criteria: initialModuleFilterCriteriaId,
      chart_type: widgetChartType,
      metric: initialMetricFields,
      slicer: initialSlicerFields,
      sort_by_property: sortFieldId ? searchSortField : "",
      sort_by: sortType || "ASC",
      enable_data_labels: enableDataLabels,
      order_of_widget: widgetOrder,
      widget_filter_criteria_name: filterName,
      is_template_filter: isAlreadyAddedFilterIsTemplateLevel,
      widget_filter_criteria_filters: filterJson,
      is_full_screen: isWidgetFullScreen,
    },
    resolver: yupResolver(addWidgetFormSchema),
    mode: "onChange",
  });

  const {
    watch,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isValid, isDirty },
  } = addWidgetForm || {};

  const {
    module_filter_criteria: moduleFilterId,
    metric: watchedMetric,
    slicer: watchedSlicer,
    chart_type: watchedChartType,
    module: watchedModule,
    sort_by_property: watchSortByProperty,
    widget_filter_criteria_name: widgetFilterCriteriaName,
    is_template_filter: isTemplateFilter,
    widget_filter_criteria_filters: widgetFilterCriteria,
  } = watch();

  const isSlicerOrMetricAdded =
    !!watchedSlicer?.length || !!watchedMetric?.length;

  const isKpiChart = watchedChartType === "KPI";

  const {
    parsedData: filters,
    isFetching: isLoadingFilters,
    error: errorInFilters,
  } = useGetDashboardFilters(watchedModule, widgetId ? String(widgetId) : "");

  const {
    parsedData: chartTypeRefs = [],
    isLoading: isLoadingChartTypeRefs,
    error: errorChartTypeRefs,
  } = useGetRefByType("WIDGET_CHART_TYPE");

  const {
    parsedData: metricTypeRefs = [],
    isLoading: isLoadingMetricTypeRefs,
    error: errorMetricTypeRefs,
  } = useGetRefByType("METRIC_TYPES");

  const {
    parsedData: dateRefs = [],
    isLoading: isLoadingDateRefs,
    error: erroraDateRefs,
  } = useGetRefByType("DATES_BUCKET");

  const {
    parsedData: parsedFields,
    isLoading: isLoadingFields,
    error: errorFields,
  } = useGetGovernanceViewFields(watchedModule, "", true);

  const moduleSelectOptions = useMemo(() => {
    const sortedModuleListing = sortListOnSpecificKeyValue({
      list: governanceViewCategories,
      key: "name",
    });
    return (
      sortedModuleListing?.map((ref) => {
        return {
          label: ref?.name,
          value: String(ref?.id),
        };
      }) || []
    );
  }, [governanceViewCategories]);

  const chartTypeSelectOptions = useMemo(() => {
    const sortedChartListing = sortListOnSpecificKeyValue({
      list: chartTypeRefs,
      key: "name",
    });

    return (
      sortedChartListing?.map((ref) => {
        const refId = ref?.id as ChartType;
        return {
          label: (
            <ChartNameAndIconWrapper
              className={`${refId?.toLocaleLowerCase()}`}
            >
              <span className="icon">{getChartIcon(refId)}</span>
              <span className="label">{ref?.name}</span>
            </ChartNameAndIconWrapper>
          ),
          labelText: ref?.name,
          value: String(ref?.id),
        };
      }) || []
    );
  }, [chartTypeRefs]);

  const parsedChartAdhocInfo = getChartAdhocInfo(
    chartTypeRefs,
    watchedChartType
  );

  const chartLogo = getChartImage(watchedChartType);

  const slicersAllowed = parsedChartAdhocInfo?.slicers_allowed || 0;
  const metricsAllowed = parsedChartAdhocInfo?.metrics_allowed || 0;

  const fields = parsedFields?.filter((field) => !field?.is_field_hidden);

  const attributeColumns =
    fields?.filter((field) => field?.is_attribute && !field?.is_field_hidden) ||
    [];

  const metricColumns = fields?.filter((field) => field?.is_metric) || [];

  const dateColumns =
    fields?.filter((field) => {
      const { isDateColumn } = checkDataTypeOfColumn(field?.field_datatype);
      return isDateColumn;
    }) || [];

  const getFilteredMetricValues = getSortValuesArray(watchedMetric);
  const getFilteredSlicerValues = getSortValuesArray(watchedSlicer);

  const isAllowedNumberOfSlicersAreSelected =
    getFilteredSlicerValues?.length >= slicersAllowed;

  const isAllowedNumberOfMetricsAreSelected =
    getFilteredMetricValues?.length >= metricsAllowed;

  const slicerFields = getOptionsForSelectWithoutSubMenu(
    attributeColumns,
    watchedSlicer,
    isAllowedNumberOfSlicersAreSelected
  );

  const attributeColumnsAsMetric =
    attributeColumns?.filter((item) => item?.is_attribute_as_metric) || [];

  const metricColumnsWithCountMetric = [
    ...metricColumns,
    ...attributeColumnsAsMetric,
    COUNT_METRIC,
  ];

  const sortedMetricColumns = sortListOnSpecificKeyValue({
    list: metricColumnsWithCountMetric,
    key: "field_display_name",
  });

  const metricFields = getOptionsForSelectWithSubMenu(
    sortedMetricColumns,
    watchedMetric,
    isAllowedNumberOfMetricsAreSelected,
    watchedSlicer
  );

  const subOptionsMappingForMetric: SubOptionsMappingType = getSelectSubOptions(
    sortedMetricColumns,
    metricTypeRefs,
    true
  );

  const dateFields = getOptionsForSelectWithSubMenu(
    dateColumns,
    watchedSlicer,
    isAllowedNumberOfSlicersAreSelected
  );

  const subOptionsMapping: SubOptionsMappingType = getSelectSubOptions(
    dateColumns,
    dateRefs
  );

  const isWidgetFilterAdded = widgetFilterCriteriaName;

  const onSetFilterValues = useCallback(
    (values: AddWidgetFilterCriteriaFormType) => {
      const { name, save_as_template, filter_criteria } = values;

      setValue("widget_filter_criteria_name", name, setValueConfig);
      setValue("is_template_filter", save_as_template, setValueConfig);
      setValue(
        "widget_filter_criteria_filters",
        filter_criteria,
        setValueConfig
      );
    },
    []
  );

  const onEditFilterCriteria = useCallback(
    (values: AddWidgetFilterCriteriaFormType) => {
      onSetFilterValues(values);
      setValue("module_filter_criteria", moduleFilterId, setValueConfig);
    },
    [moduleFilterId]
  );

  const onSaveFilterCriteria = useCallback(
    (values: AddWidgetFilterCriteriaFormType) => {
      onSetFilterValues(values);
      setValue(
        "module_filter_criteria",
        WIDGET_FILTER_CRITERIA_ID,
        setValueConfig
      );
    },
    [widgetId]
  );

  const onAddWidgetFilterCriteriaForm = useCallback(() => {
    openModal(
      {
        visible: true,
        modalTitle: "Filter Criteria",
        modalId: "add_widget_filter_criteria_form",
        modalProps: {
          propsOnSave: onSaveFilterCriteria,
          formData: {
            selectedModule: watchedModule,
          },
        } as AddFilterCriteriaFormModalPropsType,
      },
      true
    );
  }, [watchedModule, onSaveFilterCriteria]);

  const onWidgetFilterCriteriaEdit = useCallback(() => {
    openModal(
      {
        visible: true,
        modalTitle: "Edit Filter Criteria",
        modalId: "add_widget_filter_criteria_form",
        modalProps: {
          propsOnSave: onEditFilterCriteria,
          formData: {
            widgetFilterCriteriaName,
            isTemplateFilter,
            widgetFilterCriteria,
            isEditMode: true,
            selectedModule: watchedModule,
          },
        } as AddFilterCriteriaFormModalPropsType,
      },
      true
    );
  }, [
    widgetFilterCriteriaName,
    isTemplateFilter,
    widgetFilterCriteria,
    watchedModule,
    onEditFilterCriteria,
  ]);

  const onWidgetFilterCriteriaOpen = useCallback(
    (selectedFilter) => {
      openModal(
        {
          visible: true,
          modalTitle: "View Filter Criteria",
          modalId: "add_widget_filter_criteria_form",
          modalProps: {
            isReadMode: true,
            formData: {
              widgetFilterCriteriaName: selectedFilter?.filterName,
              widgetFilterCriteria: selectedFilter?.filterCriteria,
              selectedModule: watchedModule,
              isTemplateFilter: selectedFilter?.filterType === "FILTER",
            },
          } as AddFilterCriteriaFormModalPropsType,
        },
        true
      );
    },
    [watchedModule]
  );

  const onModuleFilterCriteriaChanged = useCallback(
    (selectedOption) => {
      const selelectedFilter = filters?.find(
        (item) => Number(item?.filterId) === Number(selectedOption)
      );

      const { filterName = "", filterCriteria = [], filterId = "" } =
        selelectedFilter || {};

      if (filterId) {
        const id = `${WIDGET_FILTER_CRITERIA_ID}-${filterId}`;

        setValue("widget_filter_criteria_name", filterName);
        setValue("widget_filter_criteria_filters", filterCriteria);
        setValue("module_filter_criteria", id);
      }
    },
    [filters]
  );

  const onModuleFilterCriteriaClear = useCallback(() => {
    setValue("widget_filter_criteria_name", "");
    setValue("widget_filter_criteria_filters", []);
  }, []);

  const onModuleChanged = useCallback(() => {
    setValue("widget_filter_criteria_name", "", setValueConfig);
    setValue("widget_filter_criteria_filters", [], setValueConfig);
    setValue("module_filter_criteria", "", setValueConfig);
    setValue("slicer", [], setValueConfig);
    setValue("metric", [], setValueConfig);
    setValue("sort_by_property", "", setValueConfig);
    setValue("sort_by", "ASC", setValueConfig);
  }, []);

  const moduleFilterCriteria = useMemo(() => {
    const sortedFilters = sortListOnSpecificKeyValue({
      key: "filterName",
      list: filters,
    });

    const viewFilters = sortedFilters?.filter(
      (item) => item?.filterType === "FILTER"
    );

    const existingViews = sortedFilters?.filter(
      (item) => item?.filterType === "VIEW"
    );

    const selectedFilter = sortedFilters?.find(
      (item) => item?.filterName === widgetFilterCriteriaName
    );

    const isSelectedItemIsView = selectedFilter?.filterType === "VIEW";

    return [
      ...((widgetFilterCriteriaName && [
        {
          label: (
            <FilterNameAndDescWrapperStyled>
              <div className="name-desc-wrapper">
                <span className="name">
                  Selected {isSelectedItemIsView ? "View" : "Filter"}
                </span>
                <span className="desc">(1)</span>
              </div>
            </FilterNameAndDescWrapperStyled>
          ),
          value: "parent-1",
          title: "parent 1",
          key: "selected-filter",
          children: [
            {
              label: (
                <FilterNameAndDescWrapperStyled>
                  <div className="name-desc-wrapper">
                    <span className="name">{widgetFilterCriteriaName}</span>
                  </div>

                  <LinkButton
                    className="module-filter-criteria-edit-icon"
                    onClick={(): void => onWidgetFilterCriteriaEdit()}
                  >
                    {editPencil}
                  </LinkButton>
                </FilterNameAndDescWrapperStyled>
              ),
              value: String(moduleFilterId),
              key: "selected-filter-view-item",
              labelText: widgetFilterCriteriaName,
              labelDesc: selectedFilter?.filterDesc && (
                <Flex direction="column" rowGap={5}>
                  {selectedFilter?.filterDesc && (
                    <span>{selectedFilter?.filterDesc}</span>
                  )}
                </Flex>
              ),
            },
          ],
        },
      ]) ||
        []),
      {
        label: (
          <FilterNameAndDescWrapperStyled>
            <div className="name-desc-wrapper">
              <span className="name">Existing Filters</span>
              <span className="desc">({viewFilters?.length})</span>
            </div>
          </FilterNameAndDescWrapperStyled>
        ),
        value: "Existing Filters",
        title: "parent 2",
        key: "existing-filters",
        children: getFiltersSelectOptions(
          viewFilters,
          onWidgetFilterCriteriaOpen
        ),
      },
      {
        label: (
          <FilterNameAndDescWrapperStyled>
            <div className="name-desc-wrapper">
              <span className="name">Existing Views</span>
              <span className="desc">({existingViews?.length})</span>
            </div>
          </FilterNameAndDescWrapperStyled>
        ),
        value: "Existing Views",
        title: "parent 3",
        key: "existing-views",
        children: getFiltersSelectOptions(
          existingViews,
          onWidgetFilterCriteriaOpen
        ),
      },
    ];
  }, [filters, widgetFilterCriteriaName, onWidgetFilterCriteriaEdit]);

  const isSlicerMultiSelect = slicersAllowed > 1;
  const isMetricMultiSelect = metricsAllowed > 1;

  const allRefsNameAndIds = combineRefsIdsAndName([
    ...(metricTypeRefs || []),
    ...(dateRefs || []),
  ]);

  const sortByPropertySelectOptions = useMemo(() => {
    // didnt used fields because we are not using all fields, instead filtering out on the basis of need
    const allFieldsBeingUsed = [
      ...attributeColumns,
      ...metricColumnsWithCountMetric,
      ...dateColumns,
    ];

    const slicerFieldsBeingUsed = [...attributeColumns, ...dateColumns];

    const metricFieldsBeingUsed = [...metricColumnsWithCountMetric];

    const filteredMetricsWithoutCount = getFilteredMetricValues?.filter(
      (value) => !value?.includes("CNT")
    );

    const sortOptionsByMetricFields = getSortOptions(
      getFilteredMetricValues,
      metricFieldsBeingUsed,
      allRefsNameAndIds,
      true
    );

    const sortedMetrics = sortObjectsArrayByKey(
      sortOptionsByMetricFields,
      "label"
    );

    const sortOptionsBySlicerFields = getSortOptions(
      getFilteredSlicerValues,
      slicerFieldsBeingUsed,
      allRefsNameAndIds
    );

    const sortedSlicers = sortObjectsArrayByKey(
      sortOptionsBySlicerFields,
      "label"
    );

    return [
      {
        label: "Slicer",
        value: "",
        options: sortedSlicers,
      },
      {
        label: "Metrics",
        value: "",
        options: sortedMetrics,
      },
    ];
  }, [
    attributeColumns,
    metricColumnsWithCountMetric,
    dateColumns,
    getFilteredMetricValues,
    getFilteredSlicerValues,
    allRefsNameAndIds,
  ]);

  const addModuleCriteriaDesc = (
    <Flex columnGap={3}>
      <span className="desc">Copy from existing filter criteria or </span>
      <LinkButton
        onClick={onAddWidgetFilterCriteriaForm}
        className="add-new-filter-criteria-btn"
        disabled={!watchedModule}
      >
        create new criteria
      </LinkButton>
    </Flex>
  );

  const onChartTypeChange = useCallback(() => {
    setValue("slicer", []);
    setValue("metric", []);
    setValue("sort_by_property", "");
    setValue("sort_by", "ASC");
  }, []);

  const onChangeView = (
    selectedViewId: WidgetCreationCurrentViewId
  ) => (): void => {
    updateState({
      ...state,
      currentViewId: selectedViewId,
      selectedWidget: undefined,
    });
  };

  const onMetricSlicerChange = useCallback(() => {
    setValue("sort_by_property", "");
  }, []);

  const slicerField = (
    <FormItemLabel
      label="Slicer"
      width="450px"
      required
      description={`Maximum ${slicersAllowed} slicer${
        isSlicerMultiSelect ? "s" : ""
      } can be selected`}
    >
      <SelectWithSubOptions
        name="slicer"
        width="450px"
        noOfOptionsAllowed={slicersAllowed}
        options={[
          {
            label: "Attributes",
            value: "",
            options: slicerFields,
            key: "slicer-fields",
          },
          {
            label: "Dates",
            value: "",
            options: dateFields,
            key: "date-fields",
          },
        ]}
        subOptionsMap={subOptionsMapping}
        subpanelHeading="Time Grain"
        propOnChange={onMetricSlicerChange}
        fieldId={DASH_ADD_WGT_FORM_SLIC_FIELD}
      />
    </FormItemLabel>
  );

  const metricField = (
    <FormItemLabel
      label="Metric"
      width="450px"
      required
      description={`Maximum ${metricsAllowed} metric${
        isMetricMultiSelect ? "s" : ""
      } can be selected`}
    >
      <SelectWithSubOptions
        name="metric"
        width="450px"
        options={metricFields}
        subOptionsMap={subOptionsMappingForMetric}
        subpanelHeading="Aggregrate By"
        noOfOptionsAllowed={metricsAllowed}
        propOnChange={onMetricSlicerChange}
        fieldId={DASH_ADD_WGT_FORM_METR_FIELD}
      />
    </FormItemLabel>
  );

  const onAddWidgetSaveSuccess = useCallback(
    (response) => {
      onSetData(API_CONFIG.get_dashboard_widgets, response?.data, [
        dashboardId,
      ]);

      if (isEditMode) {
        const timzone = getTimezone() || "";
        const api = API_CONFIG?.get_widget_details?.url;
        const url = appendQueryParamInUrl(api, [
          dashboardId,
          widgetId,
          String(timzone),
        ]);
        queryClient.invalidateQueries(url, { fetching: false });
      } else {
        propsOnSuccess?.(response);
      }

      onCancel();
    },
    [isEditMode, dashboardId, widgetId]
  );

  const {
    isLoading: isAddWidgetSaving,
    error: errorInSavingWidget,
    onExecuteRequest,
  } = useRequestWithMethod(
    isEditMode ? "update_widget" : "add_widget",
    undefined,
    true,
    onAddWidgetSaveSuccess
  );

  const isWidgetPickedFromGallery = selectedWidgetType === "PFG";

  const onSubmit = useCallback(
    (values: AddWidgetFormType) => {
      const payload = createWidgetApiData(values, fields, allRefsNameAndIds);

      onExecuteRequest(
        payload,
        isEditMode ? [dashboardId, widgetId] : [dashboardId]
      );
    },
    [fields, dashboardId, isEditMode, widgetId, allRefsNameAndIds]
  );

  const scrollToBottom = (): void => {
    sortOptions.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (watchSortByProperty) scrollToBottom();
  }, [watchSortByProperty]);

  return (
    <AddWidgetModalStyled>
      <FormProvider {...addWidgetForm}>
        <FormStyled isItemColumnLayout height="100%">
          <AddWidgetFormStyled>
            <StateHandler
              isFetching={
                isLoadingMetricTypeRefs ||
                isLoadingChartTypeRefs ||
                isLoadingDateRefs ||
                isAddWidgetSaving ||
                isLoadingFields ||
                isLoadingFilters
              }
              error={
                errorChartTypeRefs ||
                erroraDateRefs ||
                errorMetricTypeRefs ||
                errorInSavingWidget ||
                errorInFilters ||
                errorFields
              }
              isModal
            >
              <FormInputAndChartWrapperStyled>
                <FormInputWrapperStyled>
                  <FormItemLabel
                    label="Title"
                    key="name"
                    width="450px"
                    required
                  >
                    <InputField
                      control={control}
                      name="title"
                      width="450px"
                      placeholder="Title"
                      type="text"
                      id={DASH_ADD_WGT_FORM_TTL_FIELD}
                    />
                  </FormItemLabel>

                  <FormItemLabel
                    label="Module"
                    key="name"
                    width="450px"
                    required
                  >
                    <SelectField
                      control={control}
                      name="module"
                      width="450px"
                      options={moduleSelectOptions}
                      setValue={setValue}
                      allowClear={false}
                      isAllowClear={false}
                      filterOption={selectFilterOption}
                      showSearch
                      disabled={isWidgetPickedFromGallery}
                      placeholder="Select"
                      propOnChange={onModuleChanged}
                      id={DASH_ADD_WGT_FORM_MOD_FIELD}
                    />
                  </FormItemLabel>

                  <FormItemLabel
                    label="Module Filter Criteria"
                    key="name"
                    width="450px"
                    // required
                    description={addModuleCriteriaDesc}
                  >
                    <SelectField<TreeSelectFieldType>
                      control={control}
                      setValue={setValue}
                      name="module_filter_criteria"
                      placeholder="Select"
                      width="450px"
                      options={moduleFilterCriteria}
                      isGroupedOpt
                      shouldAddDescInLabel
                      tooltipPlacement="right"
                      isTreeSelect
                      propOnChange={onModuleFilterCriteriaChanged}
                      showSearch
                      filterOption={selectFilterOption}
                      treeDefaultExpandedKeys={["existing-views"]}
                      allowClear
                      isAllowClear
                      onClear={onModuleFilterCriteriaClear}
                      id={DASH_ADD_WGT_FORM_MOD_FLT_FIELD}
                    />
                  </FormItemLabel>

                  <FormItemLabel
                    label="Chart Type"
                    key="name"
                    width="450px"
                    required
                  >
                    <SelectField
                      control={control}
                      name="chart_type"
                      width="450px"
                      options={chartTypeSelectOptions}
                      setValue={setValue}
                      allowClear={false}
                      isAllowClear={false}
                      filterOption={selectFilterOption}
                      showSearch
                      placeholder="Select"
                      propOnChange={onChartTypeChange}
                      id={DASH_ADD_WGT_FORM_CHR_TYP_FIELD}
                    />
                  </FormItemLabel>

                  {watchedChartType && watchedModule && (
                    <>
                      {!isKpiChart && (
                        <>
                          <CheckboxWrapperStyled
                            paddingLeft="20px"
                            marginBottom="40px"
                          >
                            <CheckboxField
                              control={control}
                              name="enable_data_labels"
                              mode="secondary"
                              id={DASH_ADD_WGT_FORM_ENB_DATA_LBL_CHECK}
                            >
                              Enable Data Labels
                            </CheckboxField>
                          </CheckboxWrapperStyled>
                          <p className="section-heading">Slicers & Metrics</p>
                          {slicerField}
                        </>
                      )}

                      {metricField}

                      {!isKpiChart && (
                        <FormItemLabel label="Sort By" key="name" width="450px">
                          <SelectField
                            control={control}
                            name="sort_by_property"
                            width="450px"
                            options={sortByPropertySelectOptions}
                            setValue={setValue}
                            allowClear={false}
                            isAllowClear={false}
                            filterOption={selectFilterOption}
                            showSearch
                            disabled={!isSlicerOrMetricAdded}
                            placeholder="Select"
                            id={DASH_ADD_WGT_FORM_SORT_BY_FIELD}
                          />
                        </FormItemLabel>
                      )}

                      {watchSortByProperty && (
                        <div ref={sortOptions}>
                          <FormItemLabel label="" width="450px">
                            <RadioGroupField
                              control={control}
                              name="sort_by"
                              options={[
                                {
                                  label: "Ascending",
                                  value: "ASC",
                                },
                                {
                                  label: "Descending",
                                  value: "DESC",
                                },
                              ]}
                              direction="column"
                              labelDescWidth="512px"
                              id={DASH_ADD_WGT_FORM_SORT_BY_PROP_FIELD}
                            />
                          </FormItemLabel>
                        </div>
                      )}
                    </>
                  )}
                </FormInputWrapperStyled>

                <ChartImageWrapperStyled>
                  {chartLogo ? (
                    <div className="img-text-wrapper">
                      {chartLogo}
                      <span className="img-text">Sample Chart View</span>
                    </div>
                  ) : (
                    <span className="img-text">
                      Select a chart type to preview
                    </span>
                  )}
                </ChartImageWrapperStyled>
              </FormInputAndChartWrapperStyled>
            </StateHandler>
          </AddWidgetFormStyled>

          <FlexDiv />

          <FormItemStyled className="form-actions-sec">
            <Button id="cancel" width="74px" onClick={onCancel}>
              Cancel
            </Button>

            {!isWidgetAlreadyExists && (
              <Button
                id="primary"
                width="74px"
                marginLeft="8px"
                onClick={onChangeView(
                  isWidgetPickedFromGallery ? "PFG" : "COR"
                )}
              >
                Back
              </Button>
            )}

            <Button
              id="primary"
              width="74px"
              marginLeft="8px"
              htmlType="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid}
              elementId={DASH_ADD_WGT_SAVE_BTN}
            >
              Save
            </Button>
          </FormItemStyled>
        </FormStyled>
      </FormProvider>
    </AddWidgetModalStyled>
  );
};

export default AddWidgetForm;
