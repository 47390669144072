import moment from "moment";
import { AxiosResponse } from "axios";

import { KeyValuePairType } from "../../app.types";

import {
  agGridSortDateComparator,
  AgGridSortNumberComparator,
  agGridStringComparator,
  checkIsTableDetailPageDataTab,
  getObjectKeys,
  isJsonString,
  jsonParse,
  maskString,
  multiLevelSortArrayByKeys,
  removeIndex,
  sortObjectsArrayByKeyDescOrder,
  StringToKey,
} from "../../utils";

import { SocialNodeResponse } from "../socialnode/socialnodeparser.types";
import { getFilterByDataType, mapKeys } from "./listingpagesparser.utils";

import {
  CurrentNodeParserReturnType,
  CurrentNodeResponse,
  GovernanceViewsResponse,
  GovernanceViewsParserReturnType,
  GovernanceViewType,
  LayoutFieldType,
  GovernanceViewFiltersResponse,
  GovernanceViewFiltersParserReturnType,
  GovernanceViewFieldsResponse,
  GovernanceViewFieldsParserReturnType,
  FieldType,
  GovernanceViewFieldDataResponse,
  GovernanceViewFieldDataParserReturnType,
  MassEditableFieldsResponse,
  MassEditableParsedFields,
  IsViewFavouriteParserReturnType,
  DownloadViewResponse,
  FieldData999ValuesParserReturnType,
  FieldData999ValuesResponse,
  NodeDataParserReturnType,
  MassDataClassificationFieldsResponse,
  MassDataClassificationParsedFields,
  SchedulersListingResponseType,
  SchedulersListingParsedType,
  AllGovernanceViewsParserReturnType,
  ResponseTypeBotEngagements,
  BotEngagementsReturnType,
  BotTopUsersReturnType,
  ResponseTypeBotTopUsers,
} from "./listingpagesparser.types";

import { ColumnDataType } from "../columnparsers";
import { FilterCriteriaResponseType } from "../tablepage/tablepageparser.types";

import { apiToFormCriteria } from "../../forms/addgovernanceviewform/addgovernanceviewform.utils";
import { checkIsJobDetailPageeDetailsTab } from "../../utils/checkisjobdetailpageexecutionslisting";

export const getParsedCurrentNode = ({
  data,
}: AxiosResponse<CurrentNodeResponse[]>): CurrentNodeParserReturnType => {
  const item = data?.[0] || {};

  const layoutFields: LayoutFieldType[] = isJsonString(
    item?.LAYOUT_FIELDS || ""
  )
    ? JSON.parse(item?.LAYOUT_FIELDS)
    : [];

  const configurations: KeyValuePairType<FieldType> = jsonParse(
    item?.CONFIGURATIONS,
    true
  );

  const mandatoryFieldsIds = getObjectKeys(configurations)
    ?.filter((item) => configurations?.[item]?.is_mandatory_for_listing)
    ?.map((nestItem) => `${configurations?.[nestItem]?.field_id}`);

  const isDetailPageDataTab = checkIsTableDetailPageDataTab();
  const isJobPageExecutionsListing = checkIsJobDetailPageeDetailsTab();

  const isOutsideListing = isDetailPageDataTab || isJobPageExecutionsListing;

  const tableColumns = layoutFields
    ?.sort((a, b) => {
      const item1: number = Number.parseInt(a.display_order);
      const item2: number = Number.parseInt(b.display_order);

      return item1 < item2 ? -1 : item1 > item2 ? 1 : 0;
    })
    ?.filter((field) =>
      isOutsideListing
        ? !configurations?.[field?.field_id || 0]?.is_field_hidden &&
          !configurations?.[field?.field_id || 0]?.is_hidden_for_detail
        : !configurations?.[field?.field_id || 0]?.is_field_hidden
    )
    ?.map((field, index: number): any => {
      const fieldId = field?.field_id || 0;
      const fieldName =
        field?.field_display_name ||
        configurations?.[fieldId]?.field_display_name ||
        "";
      const dataType = configurations?.[fieldId]?.field_datatype || "STR";
      const fieldTechnicalName = configurations?.[fieldId]?.field_name || "";

      // TODO: Use fieldId instead of fieldName for column name
      const columnName = StringToKey(fieldName);

      let column: any = {
        column_id: fieldId,
        field: columnName === "alerts_generated" ? "alert_type" : columnName,
        headerName: fieldName,
        flex: 1,
        minWidth: 240,
        hide: false,
        filter: getFilterByDataType(dataType as ColumnDataType),
        floatingFilter: !!configurations?.[field?.field_id || 0]
          ?.is_field_filterable,
        menuTabs: ["generalMenuTab"],
        filterParams: {
          filterOptions: [
            {
              displayKey: "blanks",
              displayName: "Blanks",
              test(_: any, cellValue: any): boolean {
                return cellValue === "";
              },
              hideFilterInput: true,
            },
            "equals",
            "greaterThan",
            "greaterThanOrEqual",
            "inRange",
            "lessThan",
            "lessThanOrEqual",

            {
              displayKey: "nonBlanks",
              displayName: "Non-Blanks",
              test(_: any, cellValue: any): boolean {
                return cellValue !== undefined;
              },
              hideFilterInput: true,
            },
            "notEqual",
          ],
          buttons: ["apply"],
          selectAllOnMiniFilter: true,
          suppressSorting: true,
          closeOnApply: true,
          inRangeInclusive: true,
          excelMode: "windows",
          showTooltips: true,
        },
      };

      if (dataType === "NUM") {
        column.valueGetter = (e: any): any => {
          const value = e?.data?.[column?.field] ?? "";
          return value === "" ? value : parseFloat(value);
        };
      }

      if (column?.field === "description") {
        column.filterParams.valueFormatter = (params: any): string => {
          const val: string = (params?.value || "")?.replaceAll("\n", " ");

          return val === "" ? "(Blanks)" : val;
        };
      }

      if (dataType === "STR" && fieldTechnicalName !== "tags") {
        column.comparator = agGridStringComparator;
      }

      if (dataType === "STR" && fieldTechnicalName === "tags") {
        column.comparator = (a: string, b: string): number =>
          AgGridSortNumberComparator(
            a?.split(",")?.length,
            b?.split(",")?.length
          );
      }

      if (dataType === "DT") {
        column.comparator = agGridSortDateComparator;

        // it is commented to check if it is still required or simple comparator would do
        // column.comparator = (
        //   filterLocalDateAtMidnight: Date,
        //   cellValue: any
        // ): any => {
        //   const dateAsString = cellValue;
        //   const dateAsString2 = filterLocalDateAtMidnight;

        //   if (dateAsString == null || dateAsString2 == null) return -1;
        //   const cellDate = new Date(
        //     moment.utc(dateAsString).local().format("MM/DD/YYYY hh:mm")
        //   );

        //   const filterLocalDateAtMidnight2 = new Date(
        //     moment.utc(dateAsString2).local().format("MM/DD/YYYY hh:mm")
        //   );

        //   if (filterLocalDateAtMidnight2.getTime() === cellDate.getTime()) {
        //     return 0;
        //   }
        //   if (cellDate < filterLocalDateAtMidnight) {
        //     return -1;
        //   }
        //   if (cellDate > filterLocalDateAtMidnight) {
        //     return 1;
        //   }
        //   return 0;
        // };

        column.valueGetter = (e: any): any => {
          const value = e?.data?.[column?.field] ?? "";
          return value
            ? moment.utc(value).local().format("MM/DD/YYYY hh:mm A")
            : value;
        };
      }

      if (
        fieldName?.match(/definition/i) ||
        fieldName?.match(/description/i) ||
        fieldName?.match(/desc/i) ||
        configurations?.[fieldId]?.is_allow_max_default_width
      ) {
        column.maxWidth = 500;
      }

      if (fieldName?.match(/Linked Columns/i)) {
        column.minWidth = 440;
      }

      if (index === 0 && column !== undefined) {
        column = {
          ...column,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        };
      }

      if (configurations?.[+fieldId]?.is_field_masked) {
        column.valueGetter = (e: any): any => {
          const isColMasked = !!e?.data?.is_col_masked;

          const value = e?.data?.[column?.field] ?? "";
          return isColMasked
            ? maskString(value, column?.field === "column_description")
            : value;
        };
      }
      return column;
    });

  const filters: FilterCriteriaResponseType = isJsonString(
    item?.SEARCH_AURORA_FILTER_JSON || ""
  )
    ? JSON.parse(item?.SEARCH_AURORA_FILTER_JSON)
    : [];

  const isFilterExists = filters?.[0]?.condition?.length || 0;

  const parsedFilters = isFilterExists
    ? (filters || [])?.flatMap((filterCriteriaRow) => {
        const conditionGrpLogicalOperator = filters?.[0]?.next_operator;

        const apiToFormParsedData = apiToFormCriteria(
          filterCriteriaRow?.condition,
          []
        );

        return {
          condition: apiToFormParsedData,
          next_operator: conditionGrpLogicalOperator || "AND",
        };
      })
    : [];

  return {
    node_id: item?.SEARCH_ID || 0,
    selected_view: item?.SEARCH_NAME || "",
    is_default_view: item?.IS_SEARCH_DEFAULT || false,
    filterCriteria: parsedFilters,
    columns: tableColumns,
    configurations: configurations || {},
    access_type: item?.SEARCH_ACCESS_TYPE || "",
    created_by_id: `${item?.CREATED_BY || ""}`,
    dynamic_width_cols: getObjectKeys(configurations)
      ?.filter((item) => configurations?.[item]?.is_dynamic_width)
      ?.reduce(
        (prev, next) => ({
          ...prev,
          [StringToKey(configurations?.[next]?.field_display_name)]:
            configurations?.[next]?.col_width_buffer || 0,
        }),
        {}
      ),
    mandatory_fields_ids: mandatoryFieldsIds || [],
  };
};

export const getParsedCategorisedGovernanceViews = ({
  data,
}: AxiosResponse<
  GovernanceViewsResponse[]
>): GovernanceViewsParserReturnType => {
  const defaultView: GovernanceViewType[] = [];
  const favourites: GovernanceViewType[] = [];
  const createdByMe: GovernanceViewType[] = [];
  const sharedWithMe: GovernanceViewType[] = [];

  data?.forEach((item) => {
    if (item?.SEARCH_ACCESS?.match(/default/i)) {
      defaultView.push({
        id: `${item?.SEARCH_ID || 0}`,
        value: item?.SEARCH_NAME || "",
        // verify in integration phase
        desc: item?.DESC || "",
      });
    } else if (item?.SEARCH_ACCESS?.match(/social_save/i)) {
      favourites.push({
        id: `${item?.SEARCH_ID || 0}`,
        value: item?.SEARCH_NAME || "",
        desc: item?.DESC || "",
      });
    } else if (item?.SEARCH_ACCESS?.match(/gov_owner/i)) {
      createdByMe.push({
        id: `${item?.SEARCH_ID || 0}`,
        value: item?.SEARCH_NAME || "",
        desc: item?.DESC || "",
      });
    } else if (
      item?.SEARCH_ACCESS?.match(/gov_viewer/i) ||
      item?.SEARCH_ACCESS?.match(/gov_editor/i)
    ) {
      sharedWithMe.push({
        id: `${item?.SEARCH_ID || 0}`,
        value: item?.SEARCH_NAME || "",
        desc: item?.DESC || "",
      });
    }
  });

  return { default: defaultView, favourites, createdByMe, sharedWithMe };
};

export const getParsedAllGovernanceViews = ({
  data,
}: AxiosResponse<
  GovernanceViewsResponse[]
>): AllGovernanceViewsParserReturnType => {
  return (
    data?.map((item) => ({
      gov_view_id: `${item?.SEARCH_ID || 0}`,
      gov_view_name: item?.SEARCH_NAME || "",
    })) || []
  );
};

export const getParsedGovernanceViewFilters = ({
  data,
}: AxiosResponse<
  GovernanceViewFiltersResponse[]
>): GovernanceViewFiltersParserReturnType[] => {
  return (
    data
      ?.sort((a, b) =>
        a.REF_DISPLAY_ORDER < b.REF_DISPLAY_ORDER
          ? -1
          : a.REF_DISPLAY_ORDER > b.REF_DISPLAY_ORDER
          ? 1
          : 0
      )
      ?.map((item) => ({
        value: item?.REF_ID || "",
        label: item?.REF_NAME || "",
        elligibleTypes: jsonParse(item?.REF_ADHOC_INFO),
      })) || []
  );
};

export const getParsedGovernanceViewFields = ({
  data,
}: AxiosResponse<GovernanceViewFieldsResponse>): GovernanceViewFieldsParserReturnType[] => {
  const parsedFieldsJson = jsonParse(data?.fields?.[0]?.REF_ADHOC_INFO, true);

  const fields: FieldType[] = Object.values(parsedFieldsJson);

  return (
    fields
      ?.sort((a, b) =>
        a.field_display_name < b.field_display_name
          ? -1
          : a.field_display_name > b.field_display_name
          ? 1
          : 0
      )
      ?.map((field) => {
        const isAtttrAsMetric =
          "is_attribute_as_metric" in field
            ? field.is_attribute_as_metric
            : false;

        return {
          display_order: field?.display_order || 0,
          field_datatype: field?.field_datatype || "",
          field_id: `${field?.field_id}` || "",
          field_name: field?.field_name || "",
          field_display_name: field?.field_display_name || "",
          is_attribute: field?.is_attribute || false,
          field_id_field: field?.field_id_field || "",
          delimiter: field?.field_data_delimiter || "",
          is_field_hidden: field?.is_field_hidden || false,
          field_type_id: field?.field_datatype || 0,
          field_distinct_count: field?.field_distinct_count || 0,
          includes_exclues_exists: true,
          is_field_filterable: !!field?.is_field_filterable,
          is_metric: !!field?.is_metric,
          is_attribute_as_metric: isAtttrAsMetric,
        };
      }) || []
  );
};

export const getParsedFieldData = ({
  data,
}: AxiosResponse<GovernanceViewFieldDataResponse>): GovernanceViewFieldDataParserReturnType[] => {
  return (
    data?.fields?.map((field) => {
      const keys: string[] = Object.keys(field);

      const value: string = `${field?.[keys?.[keys?.length > 1 ? 1 : 0]] || 0}`;
      const keyLabel: string = field?.[keys?.[0]] || "";
      const key: string = `${value}-${keyLabel}`;

      return {
        value,
        key,
        label: keyLabel,
      };
    }) || []
  );
};

export function getParsedMassEditableFields({
  data,
}: AxiosResponse<MassEditableFieldsResponse>): MassEditableParsedFields {
  const editableFields = getObjectKeys(data);

  return {
    field_names: editableFields,
    fields:
      editableFields?.map((field, index) => {
        const currentField = data?.[field] || {};
        const fieldJson = currentField?.field_json || {};

        return {
          field_display_name: fieldJson?.field_display_name || "",
          field_name: field,
          field_type: fieldJson?.is_multi_value_allowed
            ? "multi-select-dropdown"
            : fieldJson?.is_attribute
            ? "dropdown"
            : "input",
          field_data:
            multiLevelSortArrayByKeys(currentField?.field_values, [
              "ROOT_NAME",
              "PARENT_NAME",
              "VALUE_NAME",
            ])?.map((val) => ({
              id: `${val?.ID || ""}`,
              root_name: val?.ROOT_NAME || "",
              parent_name: val?.PARENT_NAME || "",
              value_name: val?.VALUE_NAME || "",
              value_type: val?.VALUE_TYPE,
              is_multi_level_option: !!(val?.ROOT_NAME || val?.PARENT_NAME),
              desc: val?.DESC || "",
            })) || [],
          field_index: index + 1,
        };
      }) || [],
  };
}

export function getParsedMassDataClassificationFields({
  data,
}: AxiosResponse<MassDataClassificationFieldsResponse>): MassDataClassificationParsedFields {
  const editableFields = getObjectKeys(data);
  return {
    field_names: editableFields,
    fields:
      editableFields?.map((field, index) => {
        const currentField = data?.[field] || {};
        const fieldJson = currentField?.field_json || {};

        return {
          field_display_name: fieldJson?.field_display_name || "",
          field_name: field,
          field_type: fieldJson?.is_multi_value_allowed
            ? "multi-select-dropdown"
            : fieldJson?.is_attribute
            ? "dropdown"
            : "input",
          field_data:
            currentField?.field_values?.map((val) => ({
              id: `${val?.ID || ""}`,
              value_name: val?.NODE_NAME || "",
              desc: val?.TAG_DESC || "",
            })) || [],
          field_index: index + 1,
        };
      }) || [],
  };
}

export const getParsedIsViewFavourite = ({
  data,
}: AxiosResponse<SocialNodeResponse[]>): IsViewFavouriteParserReturnType => {
  const socialSaveRecord = data?.filter(
    (item) => item?.NODE_REL_TYPE === "SOCIAL_SAVE"
  );

  return { isFavourite: socialSaveRecord?.length > 0 || false };
};

export const getParsedDownloadView = ({
  data,
}: AxiosResponse<DownloadViewResponse>): string => {
  return data?.download_link || "";
};

export const getParsedFieldData999Values = ({
  data,
}: AxiosResponse<FieldData999ValuesResponse>): FieldData999ValuesParserReturnType => {
  let fieldValues = data?.fields?.map((field) => {
    const keys = getObjectKeys(field);
    const value: string = keys.length > 0 ? field[keys[0]] || "" : "";

    return value !== "" ? value : null;
  });

  const blanksIndex: number = fieldValues?.findIndex((field) => field === null);

  if (blanksIndex >= 0) {
    fieldValues = [null, ...removeIndex(fieldValues, blanksIndex)];
  }

  return fieldValues;
};

export const getParsedNodeData = ({
  data,
}: AxiosResponse<KeyValuePairType<any>>): NodeDataParserReturnType => {
  return {
    data: mapKeys(data?.data) || [],
    summary: mapKeys(data?.summaryCount) || [],
  };
};

export const getParsedSchedulersList = ({
  data,
}: AxiosResponse<
  SchedulersListingResponseType[]
>): SchedulersListingParsedType[] => {
  const sortedJobs = sortObjectsArrayByKeyDescOrder(data, "CREATED_ON");

  return (
    sortedJobs?.map((item) => ({
      jobDefId: item?.JOB_DEF_ID || 0,
      jobName: item?.JOB_NAME || "",
      jobDesc: item?.JOB_DESC || "",
      jobCategory: item?.JOB_CATEGORY,
    })) || []
  );
};

export const getParsedBotEngagements = ({
  data,
}: AxiosResponse<ResponseTypeBotEngagements>): BotEngagementsReturnType => {
  return (data || [])?.map((item) => ({
    name: item?.CREATED_ON || "",
    Users: item?.USERS_CNT || 0,
    Questions: item?.QUESTIONS_CNT || 0,
  }));
};

export const getParsedBotTopUsers = ({
  data,
}: AxiosResponse<ResponseTypeBotTopUsers>): BotTopUsersReturnType => {
  return (data || [])?.map((item) => ({
    id: `${item?.USER_ID || 0}`,
    name: item?.USER_NAME || "",
    value: item?.QUESTIONS_CNT || 0,
  }));
};
