import styled from "styled-components";
import { hexToRgbA } from "../../utils";

export const StickySectionStyled = styled.div<{
  isCollapsed: boolean;
  top?: string;
  isRemoveStyling?: boolean;
}>`
  width: 100%;
  &.sticky-section {
    background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
    padding: ${({ isRemoveStyling }): string =>
      isRemoveStyling ? "" : "10px 20px"};
    position: fixed;
    left: ${(props): string => (props.isCollapsed ? "43px" : "190px")};
    width: ${(props): string =>
      props.isCollapsed ? "calc(100vw - 55px)" : "calc(100vw - 202px)"};
    top: ${(props): string => props?.top || "45px"};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    box-shadow: ${({ theme, isRemoveStyling }): string =>
      isRemoveStyling
        ? ""
        : `0px 4px 8px 0px ${hexToRgbA(theme.bgColors.LIGHT_GREY_11, 0.1)}`};

    & > * {
      width: 100%;
      margin: 0 !important;
    }
  }

  &.sticky-section-trans {
    top: ${(props): string => props?.top || "45px"};
    transition: top 0.3s;
  }
`;

export const VerticalSpacerStyled = styled.div<{
  height: string; // height in px
}>`
  height: ${(props): string => props?.height};
`;
