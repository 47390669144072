import styled from "styled-components";

import { hexToRgbA } from "../../../utils";

export const AgGridServerSidePaginationWithSelectStyled = styled.div<{
  isNoRecordSelected: boolean;
}>`
  background: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_25};
  padding: 11px;

  .left,
  .right {
    flex: 1;
  }

  .left {
    color: ${({ theme }): string => theme.textColors.DARK_GREY_10};

    .records-count-number {
      font-weight: bold;
    }

    .hidden-records-checkbox {
      border-left: 1px solid
        ${({ theme }): string => theme.borderColors.GREY_10};
      padding-left: 10px;
      margin-left: 20px;
      color: ${({ theme }): string => theme.borderColors.GREY_24};
      .ant-checkbox-wrapper {
        margin-right: 10px;
      }
    }

    .selected-records-count-panel {
      display: flex;
      gap: 8px;
      align-items: center;

      .select-all-btn {
        border-radius: 0px;
        line-height: 13.6px;
        border-left: 1px solid
          ${({ theme }): string => theme.borderColors.GREY_10};
        padding-left: 8px;
      }
    }
  }

  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;

    .ag-paging-description {
      display: flex;
      align-items: center;
      column-gap: 5px;
      margin: 0px;
      font-size: 13px;
      color: ${({ theme }): string =>
        hexToRgbA(theme.textColors.BLACK_20, 0.7)};
      border: 1px solid ${({ theme }): string => theme.borderColors.GREY_10};
      border-top: none;
      border-bottom: none;
      height: 13.6px;
      padding: 0px 10px;
      margin: 0px 5px;
    }

    .ag-paging-number {
      color: ${({ theme }): string =>
        hexToRgbA(theme.textColors.BLACK_20, 0.7)};

      input {
        width: 42px;
        text-align: center;
        padding: 4px 2px;
        font-size: 13px;
        color: ${({ theme }): string =>
          hexToRgbA(theme.textColors.BLACK_20, 0.7)};
      }

      input,
      .ant-select-selector {
        height: 25px;
        padding: 0px 5.6px;
        font-size: 13px;
        border-radius: 0;
        border: 1px solid
          ${({ theme }): string => theme.borderColors.LIGHT_GREY_40};
      }

      .ant-select-selector {
        overflow: hidden;
      }

      .ant-select {
        width: 160px;

        .ant-select-arrow {
          color: ${({ theme }): string => theme.textColors.DARK_GREY_100};
          right: 5.6px;

          svg {
            vertical-align: middle;
            width: 8px;
          }
        }
      }

      .ant-select-item,
      .ant-select-selection-item {
        font-weight: normal;
        color: ${({ theme }): string =>
          hexToRgbA(theme.textColors.BLACK_20, 0.7)} !important;
        font-size: 13px;
        font-family: OpenSans;
      }

      .ant-select-item {
        min-height: unset;
        padding: 0px 18px;
      }
    }

    .ag-paging-row-summary-panel {
      display: flex;
      column-gap: 4px;
      color: ${({ theme }): string =>
        hexToRgbA(theme.textColors.BLACK_20, 0.7)};
      font-size: 13px;
      padding-left: 8px;
      line-height: 13.6px;
      border-left: ${({ theme, isNoRecordSelected }): string =>
        isNoRecordSelected
          ? `1px solid ${theme.borderColors.GREY_10}`
          : "none"};

      .ag-paging-row-summary-panel-number {
        font-weight: normal;
      }
    }

    .ag-paging-page-summary-panel {
      display: flex;
      gap: 4px;

      .ag-paging-button {
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid
          ${({ theme }): string => theme.borderColors.LIGHT_GREY_40};
        border-radius: 50%;

        svg path {
          fill: ${({ theme }): string => theme.textColors.DARK_GREY_1};
        }

        &.ag-disabled {
          pointer-events: none;
          svg path {
            fill: ${({ theme }): string => theme.textColors.GREY_34};
          }
        }
      }
      .min-max-btn {
        svg {
          path {
            stroke: ${({ theme }): string => theme.textColors.DARK_GREY_1};
          }
        }
      }

      .ag-paging-button,
      .ag-paging-description {
        margin: 0 4px;
      }
    }
  }

  .right-wrapper {
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .right {
      &.disabled {
        pointer-events: none;
      }
    }
  }
`;
