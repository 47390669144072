import ETLCodeSource from "../../../etlcodesource/etlcodesource";
import { ETLSourceCodeModalWrapperStyled } from "./etlsourcecodemodal.styles";

const ETLSourceCodeModal = (): JSX.Element => {
  const parsedEtlCodeInfo = {
    metaInfo: [
      {
        title: "Last Updated By",
        value: "Bob Smith",
      },

      {
        title: "Last Updated On",
        value: "",
      },
    ],
    code: [
      {
        title: "Source Code",
        value: "Select * from customers",
      },
    ],
  };

  return (
    <ETLSourceCodeModalWrapperStyled>
      <ETLCodeSource
        metaInfo={parsedEtlCodeInfo?.metaInfo}
        code={parsedEtlCodeInfo?.code}
        showRedirectIcon={false}
      />
    </ETLSourceCodeModalWrapperStyled>
  );
};

export default ETLSourceCodeModal;
