import { Edge, MarkerType } from "reactflow";
import { THEME_COLORS } from "../../components/themeprovider/themeprovider.constant";

import {
  EdgeDataType,
  LineageNode,
  LineageResponse,
  LineageResponseRuleInfo,
  PagesInfoType,
  ResponsePagesInfoType,
  XFormInfoType,
} from "./lineageparser.types";

import { ProminentTagType } from "../../components/genericprominenttag/genericprominenttag.types";
import { CustomDataSetTag } from "../parser.types";

import {
  getObjectKeys,
  getProfilingInfo,
  getTimeDifference,
  jsonParse,
  removeDotfromTagName,
  utcTOLocalTimeZone,
} from "../../utils";

export const edgeStyles = {
  strokeWidth: 1,
  zIndex: 10,
  stroke: THEME_COLORS.light.textColors.LIGHT_GREY_16,
};

export const edgeCommonProperties = {
  markerEnd: {
    type: MarkerType.ArrowClosed,
    color: THEME_COLORS.light.textColors.LIGHT_GREY_16,
    strokeWidth: 2.5,
  },
  type: "floating",
};

export const getUniqueDataOfNode = (
  data: LineageResponse = [],
  key: keyof LineageResponse[number]
): LineageResponse => {
  return [...new Map(data.map((node) => [node[key], node])).values()]?.filter(
    (item) => item?.[key]
  );
};

export const createSRCGroupNode = (
  srcNodes: LineageResponse,
  id?: string,
  isGroupChildJustDataSources?: boolean
): LineageNode[] => {
  return (
    srcNodes?.map((item) => ({
      id: id || `${item?.SRC_ID || ""}`,
      type: "srcGroupNode",
      draggable: true,
      position: { x: 100, y: 300 },
      data: {
        nodeId: `${item?.SRC_ID || ""}`,
        nodeType: "SRC",
        nodeName: item?.SRC_NAME || "",
        level: 1,
        isDataSourceOrReportGroupNode: !!id,
        isGroupChildJustDataSources,
      },
      style: {
        width: 200,
        height: 120,
        border: "0.7px solid #f8f8f8",
        zIndex: -1,
      },
    })) || []
  );
};

export const getParsedTags = (tagsInfo: string): ProminentTagType[] => {
  return jsonParse(tagsInfo)
    ?.map((item: CustomDataSetTag) => {
      return (item?.value || [])
        ?.filter((tag) => tag?.is_prominent)
        ?.map((tag) => ({
          id: `${tag?.tag_id || 0}`,
          name: removeDotfromTagName(tag?.tag_name || ""),
          type: tag?.color_code || "RED",
          tagDetails: {
            tagName: removeDotfromTagName(tag?.tag_name || ""),
            tagsetId: `${item?.tagset_id || 0}`,
            tagsetName: item?.tagset_name || "",
            tagNote: tag?.tag_comments || "",
            tagsetDesc: tag?.tag_desc || "",
            showDetailInfo: true,
            updatedBy: tag?.tag_created_by || "",
            updatedOn: tag?.tag_created_on,
            tagsetPolicyInfo: "",
          },
        }));
    })
    .flat();
};

export const createTableNode = (
  srcNodes: LineageResponse,
  parentId?: string,
  uniqueColumns?: LineageResponse
): LineageNode[] => {
  return (
    srcNodes?.map((item) => {
      const parsedPages = jsonParse(
        item?.PAGES_INFO,
        true
      ) as ResponsePagesInfoType;

      const tableDqRulesInfo = item?.TBL_DQ_RULES || "";

      const parsedRules = jsonParse(
        tableDqRulesInfo
      ) as LineageResponseRuleInfo[];

      const nodeRulesInfo =
        parsedRules?.map((item) => {
          return {
            rule_name: item?.rule_name || "",
            rule_type: item?.rule_type,
            is_alerting: item?.is_alerting || false,
            desc: item?.rule_desc || "",
            id: `${item?.rule_id || ""}`,
            run_date: item?.rule_exec_on || "",
            col_name: "City",
            rule_id: `${item?.rule_id || ""}`,
          };
        }) || [];

      return {
        id: `${item?.NODE_ID || ""}`,
        type: "tableNode",
        position: { x: 0, y: 0 },
        draggable: true,
        data: {
          nodeId: `${item?.NODE_ID || ""}`,
          nodeType: item?.NODE_TYPE,
          nodeSubType: item?.NODE_SUB_TYPE,
          nodeName: item?.NODE_NAME || "",
          lastRefreshed: item?.NODE_LAST_REFRESHED_ON
            ? getTimeDifference(
                utcTOLocalTimeZone(item?.NODE_LAST_REFRESHED_ON || "")
              )
            : "--",
          recordCount: item?.NODE_RECORD_CNT || "",
          tags: getParsedTags(item?.NODE_TAGS || "") || [],
          level: 1,
          parentNodeId: parentId || "",
          parentNodeName: item?.SRC_NAME || "",
          parentNodeType: "SRC",
          sourceTypeId: item?.SRC_TYPE_ID,
          isTablePartOfColumnLineage: false,
          lineageLevel: item?.LINEAGE_LEVEL || 1,
          countOfUpstreamNodes: item?.UP_CNT || 0,
          countOfDownstreamNodes: item?.DOWN_CNT || 0,
          rules: nodeRulesInfo,
          nodeStatus: item?.NODE_STATUS,
          pagesInfo: {
            lastExecutionOn: getTimeDifference(
              parsedPages?.[0]?.pl_last_exec_on || ""
            ),
            lastExecutionStatus: parsedPages?.[0]?.pl_last_exec_status || "",
          },
        },
        style: {
          width: 180,
          height: 50,
        },
      };
    }) || []
  );
};

export const createColumnNodes = (
  columnNodes: LineageResponse
): LineageNode[] => {
  return (
    columnNodes?.map((item) => {
      const parentNodeId = `${item?.NODE_ID || ""}`;

      const columnDqRulesInfo = item?.COL_DQ_RULES || "";

      const parsedRules = jsonParse(
        columnDqRulesInfo
      ) as LineageResponseRuleInfo[];

      const nodeRulesInfo =
        parsedRules?.map((rule) => {
          return {
            rule_name: rule?.rule_name || "",
            rule_type: rule?.rule_type,
            is_alerting: rule?.is_alerting || false,
            desc: rule?.rule_desc || "",
            id: `${rule?.rule_id || ""}`,
            run_date: rule?.rule_exec_on || "",
            col_name: item?.COL_NAME || "",
            rule_id: `${rule?.rule_id || ""}`,
          };
        }) || [];

      return {
        id: `${item?.COL_ID || ""}`,
        type: "columnNode",
        extent: "parent",
        position: { x: 0, y: 0 },
        parentNode: `${item?.NODE_ID || ""}`,
        draggable: false,
        data: {
          nodeId: `${item?.COL_ID || ""}`,
          nodeType: item?.COL_TYPE,
          nodeName: item?.COL_NAME || "",
          recordCount: item?.COL_RECORD_CNT?.toString() || "",
          tags: getParsedTags(item?.COL_TAGS || "") || [],
          colProgress: getProfilingInfo(
            item?.COL_RECORD_CNT || 0,
            item?.COL_DIST_CNT || 0,
            item?.COL_BLANK_CNT || 0
          ),
          level: 2,
          lastRefreshed: item?.NODE_LAST_REFRESHED_ON
            ? getTimeDifference(
                utcTOLocalTimeZone(item?.NODE_LAST_REFRESHED_ON || "")
              )
            : "--",
          parentNodeId: `${item?.NODE_ID || ""}`,
          parentNodeName: item?.NODE_NAME || "",
          parentNodeType: item?.NODE_TYPE,
          isParentNodeExpanded: false,
          colDataType: item?.COL_DATA_TYPE || "STR",
          lineageLevel: item?.LINEAGE_LEVEL || 1,
          countOfUpstreamNodes: item?.UP_CNT || 0,
          countOfDownstreamNodes: item?.DOWN_CNT || 0,
          rules: nodeRulesInfo,
          sourceTypeId: item?.SRC_TYPE_ID,
        },
        style: { width: 180, height: 50 },
      };
    }) || []
  );
};

function createEdge(item: LineageResponse[number]): Edge[] {
  const xFormInfo = item?.XFORM_INFO || "";

  const parsedXInfo = jsonParse(xFormInfo);

  const edgeXFormInfo: XFormInfoType = parsedXInfo?.xform;

  const edgeData = {
    nodeCodeInfo: edgeXFormInfo || "",
  } as EdgeDataType;

  return [
    {
      id: `${item?.PARENT_SRC_ID}:${item?.SRC_ID}`,
      source: `${item?.PARENT_SRC_ID || "--"}`,
      target: `${item?.SRC_ID || "--"}`,
      ...edgeCommonProperties,
      style: { ...edgeStyles, display: "none" },
      data: edgeData,
    },
    {
      id: `${item?.PARENT_NODE_ID}:${item?.NODE_ID}`,
      source: `${item?.PARENT_NODE_ID || "--"}`,
      ...edgeCommonProperties,
      target: `${item?.NODE_ID || "--"}`,
      style: { ...edgeStyles },
      data: edgeData,
    },
    item?.COL_TYPE === "COL" || item?.COL_TYPE === "DSF"
      ? {
          id: `${item?.PARENT_COL_ID}:${item?.COL_ID}`,
          source: `${item?.PARENT_COL_ID || "--"}`,
          ...edgeCommonProperties,
          target: `${item?.COL_ID || "--"}`,
          style: { ...edgeStyles },
          data: {
            ...edgeData,
            isLevel2Edge: true,
          },
        }
      : {
          id: `--`,
          source: `--`,
          ...edgeCommonProperties,
          target: `--`,
          style: { ...edgeStyles },
          data: edgeData,
        },
  ].filter(
    (item) =>
      !Number.isNaN(Number(item?.source)) &&
      !Number.isNaN(Number(item?.target)) &&
      // this check is added to avoid recursive loop on FE
      item?.source !== item?.target
  );
}

export const createEdges = (data: LineageResponse): Edge[] => {
  const edges: Edge[] = data
    .map((item) => {
      return createEdge(item);
    })
    .flat();

  return Array.from(new Map(edges.map((item) => [item.id, item])).values());
};

export const createReportNodesAndEdges = (
  data: LineageResponse = []
): { nodes: LineageNode[]; edges: Edge[] } => {
  const nodesAndEdges = data?.map((item) => {
    const parsedePages = jsonParse(item?.PAGES_INFO, true) as PagesInfoType;
    const pagesInfo = getObjectKeys(parsedePages);

    return pagesInfo?.map((page) => {
      const currentPage = parsedePages?.[page];

      const parentNodeId = `${item?.NODE_ID || ""}`;

      const node: LineageNode = {
        id: currentPage?.sheet_id || "",
        parentNode: `${item?.NODE_ID || ""}`,
        position: { x: 0, y: 0 },
        type: "columnNode",
        draggable: false,
        data: {
          nodeId: currentPage?.sheet_id || "",
          nodeName: currentPage?.sheet_name || "",
          nodeType: item?.NODE_TYPE,
          nodeSubType: item?.NODE_SUB_TYPE,
          level: 2,
          isReportPageNode: true,
          parentNodeId: `${item?.NODE_ID || ""}`,
          sourceTypeId: item?.SRC_TYPE_ID,
        },
      };

      const edge: Edge = {
        id: `${item?.PARENT_COL_ID || "--"}:${currentPage?.sheet_id || "--"}`,
        source: `${item?.PARENT_COL_ID || "--"}`,
        target: `${currentPage?.sheet_id || "--"}`,
        style: { ...edgeStyles },
        ...edgeCommonProperties,
      };

      return { node, edge };
    });
  });

  const flatResults = nodesAndEdges?.flat() || [];

  const nodes = flatResults?.map((item) => item?.node);
  const edges = flatResults?.map((item) => item?.edge);

  const uniqueNodes = [
    ...new Map(nodes?.map((item) => [item?.id, item]))?.values(),
  ];

  const uniqueEdges = [
    ...new Map(edges?.map((item) => [item?.id, item]))?.values(),
  ]?.filter((item) => item?.source !== "--" && item?.target !== "--");

  return { nodes: uniqueNodes, edges: uniqueEdges };
};
