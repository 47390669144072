import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";
import { RepresentModeType } from "./represents.types";

export const RepresentsStyled = styled.div<{
  isRefView?: boolean;
  mode?: RepresentModeType;
}>`
  .represents-values {
    display: flex;
    flex-wrap: wrap;

    .represent-item {
      display: flex;
      align-items: center;
      width: 100%;

      .represent-item-value {
        cursor: ${({ isRefView }): string => (isRefView ? "default" : "")};
        margin-left: 4.5px;
        color: ${({ theme, mode }): string =>
          mode === "primary"
            ? theme.textColors.DARK_BLUE
            : theme?.textColors?.grey_40};
        min-width: 90px;
        max-width: 110px;
        display: inline-block;
        ${ellipsis};

        &.link-text {
          cursor: pointer;
        }

        div {
          ${ellipsis};
        }

        &.col-value {
          min-width: 60px;
        }
      }
      .seperator-right-icon {
        margin: 0 9.6px;
      }

      .flex-1 {
        flex: 1;
      }

      svg {
        path {
          fill: ${({ theme, mode }): string =>
            mode === "primary"
              ? theme.bgColors.BLUE_116
              : theme.bgColors.GREY_18};
        }
      }

      .delete-icon {
        margin-left: 20px;
        svg {
          cursor: pointer;
          path {
            fill: ${({ theme }): string => theme.bgColors.RED_100};
          }
        }
      }
    }
  }

  .empty-represents {
    font-weight: 600;
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }

  .more-items-count {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    padding: 0 10px;
    min-width: 20px;
    height: 20px;
    font-size: 10px;
    font-family: OpenSansBold;
    background: ${({ theme }): string => theme.bgColors.LIGHT_GREY_27};
    color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
    border: 1px solid ${({ theme }): string => theme.borderColors.LIGHT_GREY_40};
  }
`;
