import { useDispatch } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  doubleArrowLeft,
  doubleArrowRight,
  maximizeIcon,
  minimizedIcon,
  singleArrowLeft,
  singleArrowRight,
} from "../../../svgs";
import Flex from "../../flex";
import { Input } from "../../inputs";
import LinkButton from "../../linkbutton";
import Checkbox from "../../checkbox/checkbox";
import { SelectDropdown } from "../../selectdropdowns";

import { AgGridServerSidePaginationWithSelectProps } from "./aggridserversidepaginationwithselect.types";

import { AgGridServerSidePaginationWithSelectStyled } from "./aggridserversidepaginationwithselect.styles";
import { numberFormatter } from "../../../utils";

import { useGetAppState } from "../../../customhooks";
import { setExpandableGridProps } from "../../../reducers/appreducer/appreducer";

const collapseGridIcon = minimizedIcon("12", "12");
const expandGridIcon = maximizeIcon("12", "12");

const AgGridServerSidePaginationWithSelect = (
  props: AgGridServerSidePaginationWithSelectProps
): JSX.Element => {
  const {
    setPaginationState,
    totalCount = 0,
    paginationState: { currentPage, pageSize },
    selectionState,
    hiddenRecordsSelected,
    customHiddenRecordText,
    onSelectAll,
    onClear,
    onHiddenRecordCheckboxChange,
    isGridInExpandedView,
    // onToggleGridView,
  } = props;

  const dispatch = useDispatch();

  const {
    expandableGridProps: { isGridExpanded = false } = { isGridExpanded: false },
  } = useGetAppState();

  const onToggleGridView = useCallback(() => {
    dispatch(setExpandableGridProps({ isGridExpanded: !isGridExpanded }));
  }, [isGridExpanded]);

  const { isSelectAll = false, selectedRecords = 0 } = selectionState || {};

  const [changePage, setChangePage] = useState(currentPage);

  const totalPages = Math.ceil(totalCount / pageSize);

  const onPageSizeChanged = useCallback(
    (pageSize) => {
      setPaginationState((st) => ({
        ...st,
        pageSize: Number(pageSize),
        currentPage: 1,
      }));
    },
    [props]
  );

  const onChangePage = useCallback(
    (page) => {
      if (!Number.isNaN(Number(page))) {
        setChangePage(Number(page));
      } else {
        setChangePage(currentPage);
      }
    },
    [props, changePage]
  );

  const onEnter = useCallback((): void => {
    const isValidPage = changePage < totalPages && changePage > 0;

    setPaginationState((st) => ({
      ...st,
      currentPage: isValidPage ? changePage : changePage === 0 ? 1 : totalPages,
    }));

    setChangePage(isValidPage ? changePage : changePage === 0 ? 1 : totalPages);
  }, [props, changePage]);

  const onPageNavBtnHandler = useCallback(
    (pageNavType) => {
      switch (pageNavType) {
        case "first":
          setPaginationState((st) => ({ ...st, currentPage: 1 }));
          break;
        case "last":
          setPaginationState((st) => ({ ...st, currentPage: totalPages }));
          break;
        case "next":
          setPaginationState((st) => ({
            ...st,
            currentPage: st?.currentPage + 1,
          }));
          break;
        case "prev":
          setPaginationState((st) => ({
            ...st,
            currentPage: st?.currentPage - 1,
          }));
          break;
        default:
          break;
      }
    },
    [props, totalPages]
  );

  const startsFrom = useMemo(() => {
    if (currentPage === 1 && !totalCount) {
      return 0;
    }

    return (currentPage - 1) * pageSize + 1;
  }, [currentPage, pageSize, totalCount]);

  const isFirstPage = useMemo(() => {
    return currentPage <= 1;
  }, [currentPage]);

  const isLastPage = useMemo(() => {
    return currentPage >= totalPages;
  }, [currentPage, totalPages]);

  const endFrom = useMemo(() => {
    if (currentPage === 1 && !totalCount) {
      return 0;
    }

    const endLimit = startsFrom + (pageSize - 1);
    return endLimit > totalCount ? totalCount : endLimit;
  }, [pageSize, currentPage, startsFrom, totalCount]);

  const isNoRecordSelected: boolean = !selectedRecords && !isSelectAll;
  const isShowSelectAllBtn: boolean =
    selectedRecords === endFrom - startsFrom + 1 && !isSelectAll;

  useEffect(() => {
    setChangePage(totalCount ? currentPage : 0);
  }, [currentPage, totalCount]);

  return (
    <AgGridServerSidePaginationWithSelectStyled
      isNoRecordSelected={isNoRecordSelected}
    >
      <Flex>
        <div className="left">
          {isNoRecordSelected ? (
            <>
              Total records{" "}
              <span className="records-count-number">
                {numberFormatter(`${totalCount}`)}
              </span>
              {!!customHiddenRecordText && (
                <span className="hidden-records-checkbox">
                  <Checkbox
                    checked={hiddenRecordsSelected}
                    onChange={onHiddenRecordCheckboxChange}
                  />
                  {customHiddenRecordText}
                </span>
              )}
            </>
          ) : (
            <div className="selected-records-count-panel">
              <div>
                <span className="records-count-number">
                  {numberFormatter(`${selectedRecords}`)}
                </span>
                {isSelectAll
                  ? " records in this view are selected"
                  : " record(s) selected"}
              </div>
              <LinkButton isTextUnderLine onClick={onClear}>
                Clear
              </LinkButton>
              {isShowSelectAllBtn && (
                <LinkButton
                  className="select-all-btn"
                  isTextUnderLine
                  onClick={onSelectAll}
                >
                  Select all records in this view
                </LinkButton>
              )}
            </div>
          )}
        </div>

        <div className={`right-wrapper ${!isNoRecordSelected && "disabled"}`}>
          <div className={`right ${!isNoRecordSelected && "disabled"}`}>
            {isNoRecordSelected && (
              <div className="ag-paging-number">
                <SelectDropdown
                  value={`${pageSize} records per page`}
                  onChange={onPageSizeChanged}
                  height="25px"
                  options={["5", "10", "20", "50", "100"].map(
                    (op: string): any => ({
                      label: `${op} records per page`,
                      value: op,
                    })
                  )}
                />
              </div>
            )}

            <div className="ag-paging-row-summary-panel" aria-hidden="true">
              <div className="ag-paging-row-summary-panel-number">
                {numberFormatter(`${startsFrom || 0}`)}
              </div>
              <div>-</div>
              <div className="ag-paging-row-summary-panel-number">
                {numberFormatter(`${endFrom || 0}`)}
              </div>
              <div>of</div>
              <div className="ag-paging-row-summary-panel-number">
                {numberFormatter(`${totalCount || 0}`)}
              </div>
            </div>

            <div className="ag-paging-page-summary-panel">
              {/* Previous arrows */}
              <LinkButton
                aria-label="First Page"
                className={`ag-paging-button first-btn${
                  isFirstPage ? " ag-disabled" : ""
                }`}
                onClick={(): void => onPageNavBtnHandler("first")}
              >
                {doubleArrowLeft("11px", "12px")}
              </LinkButton>
              <LinkButton
                className={`ag-paging-button prev-btn${
                  isFirstPage ? " ag-disabled" : ""
                }`}
                aria-label="Previous Page"
                onClick={(): void => onPageNavBtnHandler("prev")}
              >
                {singleArrowLeft("7px", "12px")}
              </LinkButton>

              {/* Current page number */}
              <div className="ag-paging-description" aria-hidden="true">
                <div>Page</div>
                <div className="ag-paging-number">
                  <Input
                    type="text"
                    value={changePage}
                    onChange={(e): void => onChangePage(e?.target?.value)}
                    onPressEnter={onEnter}
                  />
                </div>
                <div>of</div>
                <div>{numberFormatter(`${totalPages || 0}`)}</div>
              </div>

              {/* Next arrows */}
              <LinkButton
                className={`ag-paging-button next-btn${
                  isLastPage ? " ag-disabled" : ""
                }`}
                aria-label="Next Page"
                onClick={(): void => onPageNavBtnHandler("next")}
              >
                {singleArrowRight("7px", "12px")}
              </LinkButton>
              <LinkButton
                className={`ag-paging-button last-btn${
                  isLastPage ? " ag-disabled" : ""
                }`}
                aria-label="Last Page"
                onClick={(): void => onPageNavBtnHandler("last")}
              >
                {doubleArrowRight("11px", "12px")}
              </LinkButton>

              <LinkButton
                className="ag-paging-button last-btn min-max-btn"
                aria-label="Last Page"
                onClick={onToggleGridView}
                tooltipProps={{
                  title: isGridExpanded ? "Collapse Grid" : "Expand Grid",
                  placement: "topRight",
                }}
              >
                {isGridExpanded ? collapseGridIcon : expandGridIcon}
              </LinkButton>
            </div>
          </div>
        </div>
      </Flex>
    </AgGridServerSidePaginationWithSelectStyled>
  );
};

export default AgGridServerSidePaginationWithSelect;
