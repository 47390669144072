import { Tooltip } from "antd";
import { useCallback, useMemo, useState } from "react";

import { APP_ROUTES, DVSUM_TOOLTIP_CLASS_NAME } from "../../constants";
import { useGetAppType, useOpenDrawer } from "../../customhooks";
import { useOpenLinkInNewTab } from "../../customhooks/useopenlinlinnewtab";

import { chevronRight, databaseIcon, deleteIcon } from "../../svgs";
import { getTableDetailPageUrl, numberFormatter } from "../../utils";
import CoreNodesCircleIcon from "../corenodetypescircleicon";

import { RepresentsStyled } from "./represents.styles";
import {
  Represents as RepresentsType,
  RepresentsProps,
} from "./represents.types";

import StyledLink from "../styledlink/styledlink";
import LinkButton from "../linkbutton";

const nodeIconProps = { width: "16px", height: "16px", fontSize: "11px" };

function Represents(props: RepresentsProps): JSX.Element {
  const openDrawer = useOpenDrawer();
  const openLinkInNewTab = useOpenLinkInNewTab();
  const { isExtOrDesktop } = useGetAppType();

  const {
    moreItemsCount,
    moreItemsTitle = "",
    mode = "primary",
    moreItemsJsx,
  } = props;

  const openLink = useCallback((e, url) => {
    isExtOrDesktop ? window.open(url) : openLinkInNewTab(e, url);
  }, []);

  const onClick = useCallback(
    (e, id: "col" | "tbl" | "src", item: RepresentsType) => {
      if (id === "col") {
        item?.isRefView
          ? console.log("not implemented")
          : openDrawer({
              drawerId: "column_ref",
              visible: true,
              drawerProps: { id: item?.colId },
            });
      } else if (id === "tbl") {
        item?.isRefView
          ? openLink(e, getTableDetailPageUrl(`${item?.tblId}`))
          : openDrawer({
              drawerId: "table_ref",
              visible: true,
              drawerProps: { id: item?.tblId },
            });
      } else {
        openLink(e, `${APP_ROUTES.private_routes.sources}/${item?.dbId}`);
      }
    },
    [props]
  );

  const openDrawerOnNodeClick = useCallback(
    (id: "col" | "tbl", item: RepresentsType) => {
      const isTbl = id === "tbl";

      const nodeId = isTbl ? item?.tblId : item?.colId;
      openDrawer({
        drawerId: isTbl ? "table_ref" : "column_ref",
        visible: true,
        drawerProps: { id: nodeId },
        zIndex: 1071,
      });
    },
    []
  );

  const getNodeUrl = useCallback((id: "tbl" | "src", item: RepresentsType) => {
    const isTbl = id === "tbl";
    const isSrc = id === "src";

    if (isTbl) return getTableDetailPageUrl(`${item?.tblId}`);
    if (isSrc) return `${APP_ROUTES.private_routes.sources}/${item?.dbId}`;

    return "";
  }, []);

  const isRefView = useMemo(
    () => props?.represents?.some((item) => item?.isRefView),
    [props?.represents]
  );

  return (
    <RepresentsStyled
      isRefView={isRefView}
      className="represents-container"
      mode={mode}
    >
      <div className="represents-values">
        {props?.represents?.map((represent, index) => {
          const {
            dbName = "",
            schemaName = "",
            tblName = "",
            colName = "",
            dbIcon,
            tblIcon,
            colIcon,
            colId = "",
            tblId = "",
            isTblSelection = false,
          } = represent || {};
          const isDbOrSchemaExists = dbName || schemaName;

          return (
            <div className="represent-item" key={`key-${dbName}-${index}`}>
              {dbName && (
                <>
                  <div>{dbIcon || databaseIcon}</div>
                  <StyledLink
                    to={getNodeUrl("src", represent)}
                    cutomClassName="represent-item-value link-text"
                  >
                    <div title={dbName}>{dbName}</div>
                  </StyledLink>
                </>
              )}

              {schemaName && (
                <>
                  {dbName && (
                    <div className="seperator-right-icon">{chevronRight}</div>
                  )}
                  <div title={schemaName}>{schemaName}</div>
                </>
              )}

              {tblName && (
                <>
                  {isDbOrSchemaExists && (
                    <div className="seperator-right-icon">{chevronRight}</div>
                  )}
                  <div>
                    {tblIcon || (
                      <CoreNodesCircleIcon nodeType="TBL" {...nodeIconProps} />
                    )}
                  </div>

                  <StyledLink
                    to={isRefView ? getNodeUrl("tbl", represent) : ""}
                    cutomClassName="represent-item-value link-text"
                    title={tblName}
                    {...(!isRefView && {
                      propsOnClick: (): void =>
                        openDrawerOnNodeClick("tbl", represent),
                    })}
                  >
                    <div title={tblName}>{tblName}</div>
                  </StyledLink>
                </>
              )}

              {colName && (
                <>
                  <div className="seperator-right-icon">{chevronRight}</div>
                  <div>
                    {colIcon || (
                      <CoreNodesCircleIcon nodeType="COL" {...nodeIconProps} />
                    )}
                  </div>

                  <StyledLink
                    to=""
                    cutomClassName="represent-item-value col-value"
                    title={colName}
                    {...(!isRefView && {
                      propsOnClick: (): void =>
                        openDrawerOnNodeClick("col", represent),
                    })}
                  >
                    <div title={colName}>{colName}</div>
                  </StyledLink>
                </>
              )}
              {props?.onRemove && <div className="flex-1" />}
              {props?.onRemove && (
                <div
                  className="delete-icon"
                  role="button"
                  onClick={(): void => {
                    props?.onRemove &&
                      props?.onRemove(isTblSelection ? tblId : colId);
                  }}
                >
                  {deleteIcon}
                </div>
              )}
              {!!moreItemsCount && (
                <Tooltip
                  overlayClassName={`${DVSUM_TOOLTIP_CLASS_NAME} more-items-tooltip`}
                  placement="rightTop"
                  trigger={["click"]}
                  title={moreItemsJsx ?? ""}
                  overlayInnerStyle={{
                    maxHeight: 225,
                    overflowY: "auto",
                    minWidth: 475,
                    maxWidth: 475,
                  }}
                  overlayStyle={{ maxWidth: 475 }}
                  getPopupContainer={(trigger): HTMLElement =>
                    trigger.parentNode as HTMLElement
                  }
                >
                  <LinkButton
                    className="more-items-count"
                    onClick={(e): void => e?.stopPropagation()}
                    title={moreItemsTitle}
                  >
                    +{numberFormatter(`${moreItemsCount}`)}
                  </LinkButton>
                </Tooltip>
              )}
            </div>
          );
        })}
      </div>
    </RepresentsStyled>
  );
}

export default Represents;
