import { UserPermissionsType } from "../app.types";
import { getLoggedInUserEmail } from "./getloggedinuseremail";
import { getPostLoginData } from "./getpostlogindata";

export const getUserPermissions = (): UserPermissionsType => {
  const postLoginData = getPostLoginData();

  const {
    admin_access_type: adminAccessType,
    glossary_access_type: glossaryAccessType,
    catalog_access_type: catalogAccessType,
    is_data_security_admin: isDataSecurityAdmin,
    is_owner_user: isAccountOwner,
    admin_access: adminAccess,
    lineage_access: lineageAccess,
    lineage_access_type: lineageAccessType,
  } = postLoginData?.user_info || {
    admin_access_type: "NOA",
    glossary_access_type: "VWR",
    catalog_access_type: "VWR",
    is_owner_user: false,
  };

  const { is_finegrain_enabled: isFinegrainEnabled = false } =
    adminAccess || {};

  const { level: lineageGrainLevelAccess = "ENT" } = lineageAccess || {};

  const hasLinAccess = lineageAccessType !== "NOA";
  const hasTechLinAccces = lineageAccessType === "TCH";
  const hasFuncLinAccces = lineageAccessType === "FNC";
  const hasFunctAttrLevelAccess = lineageGrainLevelAccess === "ATR";

  return {
    is_account_admin: adminAccessType === "ACT" && !isFinegrainEnabled,
    is_user_admin: adminAccessType === "ACT" && isFinegrainEnabled,
    is_not_admin: adminAccessType === "NOA",
    is_catalog_viewer: catalogAccessType === "VWR",
    is_catalog_editor: catalogAccessType === "EDT",
    is_catalog_admin: catalogAccessType === "ADM",
    has_no_catalog_access: catalogAccessType === "NOA",
    is_glossary_viewer: glossaryAccessType === "VWR",
    is_glossary_editor: glossaryAccessType === "EDT",
    is_glossary_admin: glossaryAccessType === "ADM",
    has_no_glossary_access: glossaryAccessType === "NOA",
    is_data_security_admin: !!isDataSecurityAdmin,
    is_account_owner: !!isAccountOwner,
    has_no_lineage_access: !hasLinAccess,

    is_funct_lineage_enabled: hasFuncLinAccces,
    is_tech_lineage_enabled: hasTechLinAccces,
    is_funct_attr_level: hasFunctAttrLevelAccess,
  };
};
