import { useElementSize } from "usehooks-ts";
import { useGetAppState } from "../../customhooks";
import {
  StickySectionStyled,
  VerticalSpacerStyled,
} from "./stickysection.styles";

const StickySection = ({
  children,
  isScrolled,
  elemId,
  top,
  isRemoveStyling,
}: {
  children: JSX.Element;
  isScrolled: boolean;
  elemId: string;
  top?: string;
  isRemoveStyling?: boolean;
}): JSX.Element => {
  const { sideBarPosition } = useGetAppState();
  const isCollapsed = sideBarPosition === "collapsed";

  const [elementRef, size] = useElementSize();
  return (
    <>
      <StickySectionStyled
        isCollapsed={isCollapsed}
        className={isScrolled ? "sticky-section sticky-section-trans" : ""}
        top={top}
        isRemoveStyling={isRemoveStyling}
        ref={elementRef}
      >
        {children}
      </StickySectionStyled>
      <div id={elemId} />
      {isScrolled && size && (
        <VerticalSpacerStyled height={`${size?.height ?? 0}px`} />
      )}
    </>
  );
};

export default StickySection;
