import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";
import { CENTER_ALIGN_HEADER_CELL_CLS_NAME } from "./aggridtable.constants";
import { hexToRgbA } from "../../utils";

export const AgGridStyled = styled.div<{
  maxHeight?: string;
  isEmpty?: boolean;
}>`
  &.table-container {
    font-family: OpenSans;
    width: 100%;

    border-left: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;

    .ag-root-wrapper {
      border: none;
      .ag-overlay:not(.ag-hidden) {
        font-size: 14px;
        font-family: OpenSans;
        color: ${(props): string => props.theme.textColors.BLACK_17};
        opacity: 0.7;
      }
      .ag-row {
        font-size: 13px;
        background-color: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
      }
      .ag-row-hover {
        :hover {
          background: ${({ theme }): string => theme.bgColors.BLUE_23};
        }
      }
      .ag-row-selected {
        background: ${({ theme }): string => theme.bgColors.BLUE_23};
        :hover {
          background: ${({ theme }): string => theme.bgColors.BLUE_23};
        }
      }

      .ag-sort-order {
        display: none;
      }

      .ag-header,
      .ag-body-viewport {
        .ag-header-cell,
        .ag-cell {
          .ag-checkbox-input-wrapper {
            font-size: 13px;
            width: 13px;
            height: 13px;
            line-height: normal;

            ::after {
              color: ${(props): string => props.theme.bgColors.BLUE_115};
            }

            :hover {
              ::after {
                color: ${(props): string => props.theme.bgColors.BLUE_120};
              }
            }
            :active,
            :focus-within {
              box-shadow: none;
              outline: none;
            }
          }

          .ag-checkbox-input-wrapper:not(.ag-checked) {
            ::after {
              content: " ";
              border: 1px solid
                ${(props): string => props.theme.borderColors.DARK_GREY_100};
              width: 13px;
              height: 13px;
              border-radius: 2px;
            }
          }
        }

        .ag-header-cell {
          .ag-header-icon {
            .ag-icon-menu {
              display: none;
            }
          }
        }

        .ag-center-cols-clipper {
          min-height: unset;
        }
      }

      .ag-header {
        border: none;
        background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};

        .ag-header-viewport {
          background-color: ${({ theme }): string => theme.bgColors.BLUE_11};
        }

        .ag-pinned-left-header {
          border-right: none;
        }
        .ag-pinned-right-header {
          border-left: none;
          border-top: 1px solid #e1e1e1;
          background-color: ${({ theme }): string => theme.bgColors.BLUE_11};

          .ag-header-row-column {
            border-left: none;
            :after {
              display: none;
            }
          }
        }
        .ag-header-container {
          /* background-color: ${({ theme }): string =>
            theme.bgColors.WHITE_COLOR} !important; */

          background-color: ${({ theme }): string => theme.bgColors.BLUE_11};

          border-top: 1px solid #e1e1e1;
          border-bottom: 1px solid #e1e1e1;

          .ag-header-row {
            color: ${(props): string => props.theme.textColors.BLACK_COLOR};
            font-size: 14px;
            font-family: OpenSansSemiBold;
            .ag-header-cell {
              &:nth-child(1) {
                padding-left: 12.4px;
                .ag-header-select-all {
                  height: 100%;
                  padding-right: 9px;
                  margin-right: 30px;
                }
              }

              .ag-header-cell-sorted-desc,
              .ag-header-cell-sorted-asc {
                .ag-header-cell-text {
                  color: ${({ theme }): string => theme.textColors.BLUE_116};
                }
              }

              .ag-header-cell-resize {
                :after {
                  content: "";
                  position: absolute;
                  z-index: 1;
                  display: block;
                  width: 2px;
                  height: 30%;
                  top: calc(50% - 15%);
                  background-color: ${({ theme }): string =>
                    theme.bgColors.GREY_35};
                }
              }
            }
          }
        }
      }

      .ag-body-viewport {
        font-family: OpenSans;
        font-size: 13px;
        .ag-row {
          border-bottom-width: 0px;
          .ag-cell {
            border-bottom: 1px solid
              ${({ theme }): string => theme.borderColors.GREY_35};
            color: ${(props): string => props.theme.textColors.GREY_65};

            &.ag-cell-last-left-pinned {
              border-right: none;
            }
            &.ag-cell-first-right-pinned {
              border-left: none;
            }

            &:nth-child(1) {
              padding-left: 12.4px;
              .ag-cell-wrapper {
                .ag-selection-checkbox {
                  padding-right: 9px;
                  margin-right: 30px;
                }
              }
            }
            ${ellipsis}

            .ellipsis {
              ${ellipsis}
            }
          }
        }

        .ag-pinned-right-cols-container {
          border-left: none;
          box-shadow: 0px 4px 8px 0px
            ${({ theme }): string =>
              hexToRgbA(theme.bgColors.LIGHT_GREY_11, 0.1)};
        }
      }

      .ag-body-horizontal-scroll {
        .ag-horizontal-left-spacer {
          display: none;
        }
        .ag-horizontal-right-spacer {
          border-left: 1px solid
            ${({ theme }): string => theme.borderColors.GREY_35};
          background: transparent;
          overflow-x: auto;
        }
      }

      .ag-paging-panel {
        height: 52px;
        background: ${(props): string => props.theme.bgColors.LIGHT_BLUE_25};
        display: flex;
        justify-content: center;
        flex-direction: row-reverse;
        border-bottom: 1px solid
          ${({ theme }): string => theme.borderColors.GREY_35};
        color: ${(props): string => props.theme.textColors.GREY_65};
        .ag-paging-page-summary-panel {
          flex: 1;
          justify-content: center;
          .ag-paging-button {
            padding: 2px;
            border: 1px solid #e0e3e6;
            border-radius: 50%;
          }
          .ag-paging-description {
            padding: 2px;
            border-left: 2px solid #e0e3e6;
            padding-left: 5px;
            border-right: 2px solid #e0e3e6;
            padding-right: 5px;
            span:nth-of-type(2) {
              padding: 3px;
              padding-left: 15px;
              padding-right: 15px;
              border: 1px solid #e0e3e6;
              background: ${(props): string =>
                props.theme.bgColors.WHITE_COLOR};
              visibility: hidden;
              :active {
                border-color: ${(props): string =>
                  props.theme.borderColors.YELLOW_RD};
              }
            }
          }
        }
      }
    }
    .input-container {
      position: relative;
      width: 30px;
      margin: auto;
      margin-top: -36px;
      outline: none;
      left: -46px;

      input {
        width: inherit;
        border-radius: 0px;
        height: 25px;
        padding: 0px;
        text-align: center;
        margin-top: 9px;
      }
    }
    .pages-select-container {
      text-align: center;
      position: relative;
      width: 100px;
      margin: auto;
      margin-top: -27px;
      left: 129px;
      select {
        color: ${(props): string => props.theme.textColors.GRAY_70};
        border: 1px solid #e0e3e6;
        background: ${(props): string => props.theme.bgColors.WHITE_COLOR};
        width: 54px;
        padding: 3px;
        outline: none;
        :active {
          border-color: ${(props): string =>
            props.theme.borderColors.YELLOW_RD};
        }
        :focus {
          border-color: ${(props): string =>
            props.theme.borderColors.YELLOW_RD};
        }
      }
    }
  }

  .ag-header-cell.${CENTER_ALIGN_HEADER_CELL_CLS_NAME} {
    .ag-header-cell-label {
      justify-content: center;
    }

    .ag-header-cell-text {
      text-align: center;
    }

    .ag-react-container {
      > div {
        justify-content: center;
      }
    }
  }

  .ag-body-viewport {
    max-height: ${({ maxHeight }): string => maxHeight || "450px"};
    ${({ isEmpty }): any => isEmpty && `min-height: 120px;`}
    overflow-y: auto;
  }

  .ag-paging-number {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 25px;
    }
  }
`;

export const ActionRendererStyled = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
`;
