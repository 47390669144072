import { useGetAppState } from "../../customhooks";

import Header from "../header";
import NavBar from "../navbar";

import { LayoutStyled } from "./layout.styles";
import BackToTopButtton from "../backtotopbtn";

function Layout({ children }: { children: React.ReactNode }): JSX.Element {
  const {
    sideBarPosition,
    expandableGridProps: { isGridExpanded = false },
  } = useGetAppState();

  return (
    <LayoutStyled isSideBarCollapsed={sideBarPosition === "collapsed"}>
      <Header />

      <NavBar />
      <div className="page-content">
        <div className="page-content-layout">{children}</div>
      </div>

      {!isGridExpanded && <BackToTopButtton />}
    </LayoutStyled>
  );
}
export default Layout;
