import styled from "styled-components";
import LinkButton from "../../components/linkbutton/linkbutton";

export const DrawerContentWrapperStyled = styled.div`
  height: 100%;
`;

export const RulesListingEditRefDrawerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

export const DrawerTitleStyled = styled.div`
  color: ${({ theme }): string => theme.textColors.BLUE_116};
  padding: 20px 25px;
  border-bottom: 1px solid ${({ theme }): string => theme.borderColors.GREY_56};
  font-size: 19px;
  font-family: OpenSansSemiBold;
  background-color: white;

  position: fixed;
  top: 40px;
  z-index: 2;
  width: 100%;
`;

export const RulesInputWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 20px;
  padding: 20px 25px;
  flex: 1;
  z-index: 1;
  max-height: calc(100% - 120px);
  overflow-y: auto;
  margin-top: 65px;

  .dvsum-select-field
    .multiselect-dropdown-dvsum
    .rc-virtual-list-holder
    .ant-select-item-option {
    padding-left: 10px;
  }
`;

export const LabelAndInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  row-gap: 5px;

  div {
    width: 100%;
  }

  .select-field-container {
    width: 100%;

    .ant-select {
      .ant-select-selector {
        height: 40px;

        width: 100%;
      }
    }
  }
  .input-field-container {
    .ant-input {
      height: 40px;
    }
  }
`;

export const LabelStyled = styled.div`
  font-family: OpenSansSemiBold;
  font-size: 14px;
`;

export const DescriptionStyled = styled.div`
  color: ${({ theme }): string => theme.textColors.GREY_24};
  font-size: 12px;
`;

export const FilterWrapperStyled = styled(LinkButton)`
  display: flex;
  column-gap: 8px;

  svg {
    path {
      fill: ${({ theme }): string => theme.bgColors.BLUE_116};
    }
  }

  .filter-label-value {
    display: flex;
    row-gap: 5px;
    flex-direction: column;
    align-items: flex-start;

    p {
      margin: 0;
    }

    .label {
      font-style: italic;
      color: ${({ theme }): string => theme.textColors.GREY_210};
      font-size: 12px;
    }

    .value {
      color: ${({ theme }): string => theme.textColors.GREY_24};
      font-size: 12px;
      word-break: break-word;
      white-space: pre-line;
      text-align: left;
    }
  }
`;

export const RulesFooterStyled = styled.div`
  background-color: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_25};
  border-top: 1px solid
    ${({ theme }): string => theme.borderColors.LIGHT_BLUE_45};
  padding: 8px 20px 8px 18px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  position: fixed;
  bottom: 0;
  z-index: 2;
  width: -webkit-fill-available;
`;

export const FilterConditionModalFooterStyled = styled.div`
  background-color: ${({ theme }): string => theme.bgColors.LIGHT_BLUE_25};
  border-top: 1px solid
    ${({ theme }): string => theme.borderColors.LIGHT_BLUE_45};
  padding: 8px 18px 8px 18px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const FilterConditionModalStyled = styled.div`
  .label {
    padding: 30px 80px 50px 30px;
    font-size: 14px;
    text-align: flex-start;
  }
`;

export const FilterConditionWrapperStyled = styled.div`
  padding: 0 45px;
  min-height: 350px;
  max-height: 600px;
  overflow-y: auto;
  margin: 45px 0;
`;

export const ColumnNameAndTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
  }

  svg {
    height: 25px;
    path {
      fill: ${({ theme }): string => theme.textColors.GREY_18};
    }
  }
`;

export const ColumnSequenceWrapperStyled = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  .col-seq-divider {
    background: ${({ theme }): string => theme.textColors.GREY_56};
  }

  .text-heading {
    font-family: OpenSansSemiBold;
    font-size: 13.6px;
    color: ${({ theme }): string => theme.textColors.BLUE_116};
    margin: 15px 0;
  }
`;
