import { useCancelModal, useGetAppState } from "../../../../customhooks";
import ContentPreviewer from "../../../contentpreviewer";

import { ViewQueryCodeModalWrapperStyled } from "./viewquerycodemodal.styles";
import { ViewQueryCodeModalPropsType } from "./viewquerycodemodal.types";

const ViewQueryCodeModal = (): JSX.Element => {
  const {
    secondaryModal: { modalProps = {} },
  } = useGetAppState();

  const {
    sqlQuery = "",
    queryPreviewTitle = "Source Code",
    expandedModalTitle,
  } = modalProps as ViewQueryCodeModalPropsType;

  return (
    <ViewQueryCodeModalWrapperStyled>
      {expandedModalTitle?.()}

      <div className="node-code-info">
        <ContentPreviewer
          title={queryPreviewTitle}
          showCrossIcon={false}
          marginBottom="0"
          sqlQuery={sqlQuery}
          addScroll
          showCollapseIcon
          showExpandIcon={false}
        />
      </div>
    </ViewQueryCodeModalWrapperStyled>
  );
};

export default ViewQueryCodeModal;
