import styled from "styled-components";
import { hexToRgbA } from "../../../../../../utils";

export const ExcelSourceUploadSecStyled = styled.div<{ isEmpty?: boolean }>`
  margin: ${({ isEmpty }): string => (isEmpty ? "20px 0px" : "")};

  padding: ${({ isEmpty }): string => (isEmpty ? "" : "32px 31px 32px 31px")};

  ${({ isEmpty }): any =>
    isEmpty &&
    `
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
  `}

  position: relative;

  .file-upload-empty-sec {
    width: 90%;
  }

  .empty-state-upload-file {
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }): string => theme.borderColors.BLUE_116};
    border-style: dashed;
    border-radius: 12px;
    background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};
  }

  .upload-files {
    width: 100%;
    height: 125px;
    border: 1px solid ${({ theme }): string => theme.borderColors.BLUE_116};
    border-style: dashed;
    border-radius: 12px;
    background: ${({ theme }): string => theme.bgColors.WHITE_COLOR};

    .static-text {
      color: ${({ theme }): string => theme.textColors.GREY_24};
      font-size: 15px;
    }
  }

  .ant-upload-disabled {
    opacity: 0.5;
  }

  .error-message {
    position: absolute;
    color: ${({ theme }): string => theme.textColors.RED};
  }

  .empty-state-error-message {
    color: ${({ theme }): string => theme.textColors.RED};
    position: absolute;
    bottom: -26px;
  }
`;
