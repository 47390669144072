import { useCallback, useMemo } from "react";

import { ContentPreviewerStyled } from "./contentpreviewer.styles";
import { ContentPreviewerProps } from "./contentpreviewer.types";

import { convertQueryToHtmlQuery, parseStringToHtml } from "../../utils";
import {
  copyTextIcon,
  crossIcon1,
  maximizeIcon,
  minimizedIcon,
} from "../../svgs";

import LinkButton from "../linkbutton/linkbutton";
import {
  useCancelSecondaryModal,
  useCopyToClipboard,
  useOpenModal,
} from "../../customhooks";

import { VerticalDividerStyled } from "../dividers/dividers.styles";
import { ViewQueryCodeModalPropsType } from "../modalwrapper/modals/viewquerycodemodal/viewquerycodemodal.types";

const expandIcon = maximizeIcon("15", "15");
const collapseIconSvg = minimizedIcon("15", "15");

const ContentPreviewer = ({
  sqlQuery = "",
  addScroll = false,
  onClose,
  showCrossIcon = true,
  title = "",
  showExpandIcon = false,
  showCollapseIcon = false,
  allowCopying = true,
  marginBottom = "",
  customJsx,
  expandedModalTitle,
}: ContentPreviewerProps): JSX.Element => {
  const copyQuery = useCopyToClipboard();
  const openModal = useOpenModal();
  const closeModal = useCancelSecondaryModal();

  const queryHtml = useMemo(() => {
    return parseStringToHtml(convertQueryToHtmlQuery(sqlQuery));
  }, [sqlQuery]);

  const onCopy = useCallback(() => {
    copyQuery(sqlQuery);
  }, [sqlQuery, copyQuery]);

  const verticalDivider = (
    <VerticalDividerStyled
      height="8px"
      width="1px"
      marginLeft="5px"
      marginRight="5px"
    />
  );

  const onOpenCodeInFullScreen = useCallback(() => {
    openModal(
      {
        modalId: "view_query_code_modal",
        visible: true,
        modalProps: {
          sqlQuery,
          expandedModalTitle,
          queryPreviewTitle: title,
        } as ViewQueryCodeModalPropsType,
      },
      true
    );
  }, [sqlQuery, title, expandedModalTitle]);

  return (
    <ContentPreviewerStyled
      data-testid="analysis-detail-page-query-block-query-results-sql-query-text-sec"
      addScroll={addScroll}
      marginBottom={marginBottom}
      className="sql-query-preview-container"
    >
      {title && (
        <div className="title-copy-icon-wrapper">
          <div className="query-title">{title}</div>
          <div className="sql-query-action-icons">
            {allowCopying && (
              <LinkButton className="copy-icon" onClick={onCopy}>
                {copyTextIcon("15", "15")} Copy Code
              </LinkButton>
            )}

            {showExpandIcon && (
              <>
                {verticalDivider}
                <LinkButton
                  className="expand-icon"
                  onClick={onOpenCodeInFullScreen}
                >
                  {expandIcon}
                </LinkButton>
              </>
            )}

            {showCollapseIcon && (
              <>
                {verticalDivider}
                <LinkButton className="collapse-icon" onClick={closeModal}>
                  {collapseIconSvg}
                </LinkButton>
              </>
            )}

            {showCrossIcon && (
              <LinkButton
                className="cross-icon-container"
                onClick={onClose}
                data-testid="analysis-detail-page-overview-sec-cross-btn"
              >
                {crossIcon1("22", "22")}
              </LinkButton>
            )}
          </div>
        </div>
      )}

      <div
        className="query-wrapper"
        data-testid="analysis-detail-page-query-block-query-results-sql-query-text"
      >
        {customJsx || queryHtml}
      </div>
    </ContentPreviewerStyled>
  );
};

export default ContentPreviewer;
