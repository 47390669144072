import { useCallback, useEffect, useMemo, useState } from "react";
import { DataNode } from "antd/lib/tree";
import { useFormContext } from "react-hook-form";

import { CheckboxWrapperStyled } from "../../../components/form/form.styles";
import { CheckboxField } from "../../../components/formfields";

import { SectionContainerStyled } from "../addnewjobform.styles";
import { AddNewJobFormType } from "../addnewjobform.types";

import { TreeTransfer } from "../../../components";

import {
  getDvSumInformativeText,
  jsonParse,
  jsonStringify,
} from "../../../utils";
import FormItemLabel from "../../../components/form/formitemlabel/formitemlabel";

import { AddJobNotificationFormPropsType } from "./addjobnotificationform.types";
import {
  EmailEditorWrapperStyled,
  EmailTagStyled,
  EmailTagWrapperStyled,
} from "./addjobnotificationform.styles";

import { crossIcon } from "../../../svgs";
import LinkButton from "../../../components/linkbutton/linkbutton";

import { getInitialTreeDataForUsersandGroups } from "../addnewjobform.utils";
import { ParsedEnabledObjectType } from "../../../parsers/userroles/userrolesparser.types";

import { getSourceAndDestinationDataWithEnabledObjects } from "../../addroleform/addroleform.utils";

const cross = crossIcon("10", "10");

const validateEmail = (email: string): RegExpMatchArray | null => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const AddJobNotificationForm = (
  props: AddJobNotificationFormPropsType
): JSX.Element => {
  const { control, watch, setValue } = useFormContext<AddNewJobFormType>();
  const { jobCategory, sendScheduleEmailCompletion } = watch();
  const [inputValue, setInputValue] = useState("");
  const [errorText, setErrorText] = useState("");

  const {
    externalRecipients,
    destinationData,
    setDestinationData,
    whitelistedDomains,
    users,
    groups,
    internalRecipients,
    isEdit,
    setExternalRecipients,
    initialTreeData,
    treeData,
    setTreeData,
    sourceData,
    setSourceData,
    updateTreeData,
    updateSourceData,
    updateDestinationData,
  } = props;

  const isExternalRecipientsExists = externalRecipients?.length;

  const [showPlaceholder, setShowPlaceholder] = useState(
    !isExternalRecipientsExists
  );

  // const updateTreeData = useCallback((data: DataNode[]) => {
  //   setTreeData(data);
  // }, []);

  // const updateSourceData = (data: DataNode[]): void => {
  //   setSourceData(data);
  // };

  // const updateDestinationData = (data: DataNode[]): void => {
  //   setDestinationData(data);
  // };

  const onKeyDownHandler = useCallback(
    (event) => {
      setShowPlaceholder(false);
      if (
        event?.key === "Enter" &&
        inputValue?.trim() !== "" &&
        !externalRecipients?.includes(inputValue)
      ) {
        const domain = inputValue?.split("@")?.[1];

        if (validateEmail(inputValue)) {
          setExternalRecipients((st) => {
            return [...st, inputValue];
          });
          // addExternalRecipients(inputValue);
          setInputValue("");
          if (!whitelistedDomains?.includes(domain)) {
            setValue("errorInExternalRecipients", true);
            setErrorText("This domain is not whitelisted.");
          }
        } else {
          setValue("errorInExternalRecipients", true);
          setErrorText("The email entered is invalid.");
          setInputValue("");
        }
      }
    },
    [inputValue, externalRecipients, whitelistedDomains]
  );

  const validateAllEmails = useCallback((list: string[]) => {
    const hasInvalidEmail = list?.some((email) => {
      const domain = email?.split("@")?.[1];

      return !validateEmail(email) || !whitelistedDomains?.includes(domain);
    });

    if (hasInvalidEmail) {
      setValue("errorInExternalRecipients", true);
    } else {
      setValue("errorInExternalRecipients", false);
      setErrorText("");
    }
  }, []);

  const onRemoveEmail = useCallback(
    (item: string) => (): void => {
      setErrorText("");

      const updatedList = externalRecipients?.filter((node) => node !== item);

      setExternalRecipients(updatedList);
      if (updatedList?.length) {
        validateAllEmails(updatedList);
      } else {
        setValue("errorInExternalRecipients", false);
      }
    },
    [externalRecipients]
  );

  const handleInputChange = useCallback((event) => {
    const addedEmail = event?.target?.value?.trim();
    setInputValue(addedEmail);
    if (addedEmail) setErrorText("");
    setShowPlaceholder(addedEmail === "");
  }, []);

  const {
    datdict_addrule_not_ext_recip: {
      description: datdictAddruleNotExtRecip = "",
    } = {},
  } = getDvSumInformativeText();

  // const enabledObjects: ParsedEnabledObjectType[] = useMemo(() => {
  //   return internalRecipients?.map((item) => {
  //     const enabledObj = {
  //       id: `${item?.id}`,
  //       key:
  //         item?.type === "USR"
  //           ? `user-data-${item?.id}`
  //           : `group-data-${item?.id}`,
  //       node_type: item?.type,
  //     };
  //     return enabledObj;
  //   });
  // }, [internalRecipients]);

  // useEffect(() => {
  //   // if (isEdit) {
  //   const {
  //     treeData,
  //     sourceData: usersGrpsSourceData,
  //     destinationData: usersGrpsDestinationData,
  //   } = getSourceAndDestinationDataWithEnabledObjects(
  //     enabledObjects,
  //     initialTreeData
  //   );

  //   updateTreeData(treeData);
  //   updateSourceData(usersGrpsSourceData);
  //   // if (!destinationData) updateDestinationData(usersGrpsDestinationData);
  //   updateDestinationData(usersGrpsDestinationData);
  //   // }
  // }, [initialTreeData?.length, enabledObjects?.length]);
  // // }, []);

  return (
    <SectionContainerStyled marginTop="0px">
      <span className="section-title">Email Recipients</span>

      <CheckboxWrapperStyled marginBottom="8px" paddingLeft="0">
        <CheckboxField
          control={control}
          name="sendScheduleEmailCompletion"
          mode="primary"
        >
          Send schedule completion email
        </CheckboxField>
      </CheckboxWrapperStyled>

      {jobCategory === "RLS" && sendScheduleEmailCompletion && (
        <CheckboxWrapperStyled marginBottom="10px" paddingLeft="0">
          <CheckboxField control={control} name="sendEmailOnAlerts">
            Send email only on alerts
          </CheckboxField>
        </CheckboxWrapperStyled>
      )}

      {sendScheduleEmailCompletion && (
        <FormItemLabel
          label="Internal Recipients"
          paddingLeft="0"
          marginBottom="0"
          marginTop="25px"
        >
          <TreeTransfer
            sourceTitle="Available"
            destinationTitle="Selected"
            enableLevelOfAccess={false}
            showChildrenCount
            treeData={treeData}
            sourceData={sourceData}
            updateSourceData={(sourceData): void => {
              updateSourceData?.(sourceData);
            }}
            destinationData={destinationData}
            updateDestinationData={updateDestinationData}
            leftSideHeight={334}
            rightSideHeight={334}
          />

          <FormItemLabel
            label="External Recipients"
            paddingLeft="0"
            marginBottom="0"
            marginTop="26px"
            description={datdictAddruleNotExtRecip}
          >
            <EmailEditorWrapperStyled>
              <EmailTagWrapperStyled onKeyDown={onKeyDownHandler}>
                {externalRecipients?.map((item) => {
                  return (
                    <EmailTagStyled key={`email-tag-${item}`}>
                      <span className="email"> {item}</span>
                      <LinkButton
                        className="cross-icon"
                        onClick={onRemoveEmail(item)}
                      >
                        {cross}
                      </LinkButton>
                    </EmailTagStyled>
                  );
                })}

                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  className="email-input-field"
                  placeholder={
                    showPlaceholder
                      ? "example@email.com, example@email.com"
                      : ""
                  }
                />
              </EmailTagWrapperStyled>
            </EmailEditorWrapperStyled>
            <span className="error">{errorText}</span>
          </FormItemLabel>
        </FormItemLabel>
      )}
    </SectionContainerStyled>
  );
};
