//             <---Styles--->
import styled from "styled-components";
import { ellipsis } from "../../styles/app.styles";
//             <---Styles--->

//             <---Types--->
import { AlignLineageItems } from "./lineagepreviewer.types";
//             <---Types--->

export const LineagePreviewerStyled = styled.div<{
  align?: AlignLineageItems;
  marginBottom?: string;
}>`
  display: flex;
  flex-direction: column;
  background: ${({ theme }): string => theme?.bgColors?.LIGHT_GREY_22};
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: ${({ marginBottom }): string => marginBottom || ""};
  padding: 10px;
  row-gap: 0;
`;

export const LineageNodesPreviewerStyled = styled.div<{
  align?: AlignLineageItems;
  marginBottom?: string;
}>`
  display: flex;
  flex-direction: column;
  background: ${({ theme }): string => theme?.bgColors?.LIGHT_GREY_22};
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${({ marginBottom }): string => marginBottom || ""};
  padding: 10px;
  row-gap: 0;
  width: 100%;
`;

export const LineageWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }): string => theme?.bgColors?.LIGHT_GREY_22};
  align-items: center;
  justify-content: center;
  min-height: 210px;
  padding: 20px;
`;

export const LineageSingleSecStyled = styled.div<{ isCurrent?: boolean }>`
  width: 220px;
  padding: 14px;
  border-radius: 4px;
  border: solid 1px ${({ theme }): string => theme?.borderColors?.LIGHT_GREY_5};
  background-color: ${({ theme }): string => theme?.bgColors?.WHITE_COLOR};
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  color: ${({ theme }): string => theme.textColors.BLACK_COLOR};
  font-family: ${({ isCurrent }): string => (isCurrent ? "OpenSansBold" : "")};

  .current-node {
    display: flex;
    align-items: center;
    min-width: 0px;

    .lineage-node-link {
      min-width: 0px;
    }

    span {
      ${ellipsis};
    }
  }
`;

export const LineageSingleSecWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AlertMessageStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 5px;

  .issues-count {
    font-family: OpenSansSemiBold;
    margin-right: 4px;
  }
`;
export const AlertMessageWrapperStyled = styled.div`
  /* position: absolute;
  top: 15px;
  left: 62px;
  width: 94%; */
  z-index: 1;
  width: 100%;

  .gritter-notification {
    align-items: flex-start;
    justify-content: flex-start;
  }
`;
