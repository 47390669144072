import { THEME_COLORS } from "../../components/themeprovider/themeprovider.constant";
import { hexToRgbA } from "../../utils";
import {
  LineagePageUrlParamsType,
  NodeDefaultValuesType,
} from "./lineagepage.types";

// export const LINEAGE_LEVEL_1_NODE_WIDTH = 300;
export const LINEAGE_LEVEL_1_NODE_WIDTH = 370;

export const LINEAGE_LEVEL_2_NODE_NORMAL_VIEW_HEIGHT = 34;

// export const LINEAGE_LEVEL_2_NODEE_WIDTH = 248;
export const LINEAGE_LEVEL_2_NODEE_WIDTH = 320;

export const LINEAGE_LEVEL_2_NODE_BOX_SPACING = 31.2;

export const SPACING_BETWEEN_LEVEL_1_NODE_AND_LEVEL_2_NODE = 13;

export const SPACING_BETWEEN_LEVEL_2_NODE = 8;

export const LINEAGE_LEVEL_3_NODE_WIDTH = 250;

export const SPACING_OF_DRILL_DOWN_NODE_2_TOP_BOTTOM = 20;

export const SPACING_OF_DRILL_DOWN_NODE_1_TOP_BOTTOM = 16;

export const SPACING_BETWEEN_DRILL_DOWN_LEVEL_2_LEVEL_1_NODE = 13;

export const SPACING_BETWEEN_DRILL_DOWN_LEVEL_2_NODE = 6;

export const DQ_ALERT_NOTIFICATION_HEIGHT = 50;

export const LINEAGE_EDGE_ARROW_DIM_COLOR =
  THEME_COLORS.light.textColors.GREY_36;

export const LINEAGE_EDGE_STROKE_DIM_COLOR = hexToRgbA(
  THEME_COLORS.light.textColors.GREY_36,
  0.25
);

export const LINEAGE_EDGE_PROMINENT_COLOR =
  THEME_COLORS.light.textColors.BLUE_123;

export const LINEAGE_EDGE_NORMAL_COLOR =
  THEME_COLORS.light.textColors.LIGHT_GREY_16;

export const COMPONENT_HEIGHTS = {
  etlBaseHeight: 100, // Base height for ETL node
  baseHeight: 82, // Base height for source name + node name
  lastRefresh: 20, // Height for last refresh or last run
  dsrLastRefresh: 10, // Height for last refresh or last run
  recordCount: 20, // Height for record count
  footer: 43, // Footer height for focused mode
  dqLayer: 40, // DQ Layer height if applicable
  childNodeHeight: 7.5, // Height per child node
  drillDownSpacing: 20, // Spacing for drill-down (top + bottom combined)
  borderHeight: 5, // Additional height if node is part of searched query
  columnsCount: 30, // Height for columns count
};

export const CHILD_COMPONENT_HEIGHTS = {
  baseHeight: 30,
  lastRefresh: 20,
  recordCount: 20,
  dqLayer: 35,
  profilingInfo: 40,

  nodeHeight: 35,
  nodeHeightExpanded: 115,
};

export const LINEAGE_NOT_AVALIABLE_TEXT =
  "Attribute level technical lineage is not available.";

export const DEFAULT_LINEAGE_TYPE = "functional";

export const DEFAULT_NODE_VALUES: NodeDefaultValuesType = {
  isExpanded: false,
  oneUpstream: "",
  oneDownstream: "",
  allUpstream: "",
  allDownstream: "",
};

export const DEFAULT_BASE_NODE_VALUES: NodeDefaultValuesType = {
  isExpanded: false,
  oneUpstream: "show",
  oneDownstream: "show",
};

export const DEFAULT_LEVEL2_NODE_VALUES: NodeDefaultValuesType = {
  ...DEFAULT_BASE_NODE_VALUES,
  isFocused: true,
};

export const DEFAULT_LINEAGE_URL_PARAMS: LineagePageUrlParamsType = {
  resetFitView: "0",
  recalculateLayout: "0",
};
