import {
  ViewSelectorStyled,
  ViewSelectorToggleButtonStyled,
} from "./viewselector.styles";

import { ViewSelectorProps } from "./viewselector.types";

const ViewSelector = (props: ViewSelectorProps): JSX.Element => {
  const { label = "", viewSelectorOptions = [] } = props;

  return (
    <ViewSelectorStyled>
      {label && <div className="label">{label}: </div>}

      <div className="view-selector-btns-wrapper">
        {viewSelectorOptions?.map((option) => {
          const {
            id = "",
            iconOrLabelJsx,
            tooltipProps,
            isActive = false,
            isDisabled = false,
            className = "",
            onClick,
            elementId = "",
          } = option;

          return (
            <ViewSelectorToggleButtonStyled
              key={`view-selector-button-${id}`}
              data-testid={`view-selector-button-${id}`}
              isActive={isActive}
              isDisabled={isDisabled}
              onClick={(): void => (!isDisabled ? onClick?.(id) : undefined)}
              tooltipProps={tooltipProps}
              className={`${className} view-selector-button ${
                isActive ? "active" : ""
              }`}
              elementId={elementId}
            >
              {iconOrLabelJsx}
            </ViewSelectorToggleButtonStyled>
          );
        })}
      </div>
    </ViewSelectorStyled>
  );
};
export default ViewSelector;
