import { useEffect } from "react";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { APP_ROUTES } from "../constants";
import { useQueryParams } from "../customhooks";
import { setExpandableGridProps } from "../reducers/appreducer/appreducer";

// we dont have to reset the grid expanded state
//  in analytics tab of chat bot section
const notApplicableRoutes = [APP_ROUTES.private_routes.analytics];

export default function ResetExpandedGrids(): any {
  const { pathname } = useLocation();
  const params = useQueryParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const isAllowed = notApplicableRoutes?.every(
      (item) => !pathname?.includes(item)
    );

    if (isAllowed) {
      dispatch(setExpandableGridProps({ isGridExpanded: false }));
    }
  }, [pathname, params?.get("column")]);

  return null;
}
