import { Link } from "react-router-dom";
import styled from "styled-components";
import { StyledLinkType } from "./styledlink.types";

export const StyledLinkComponent = styled(Link)<{ type?: StyledLinkType }>`
  display: inline-flex;
  color: ${({ theme, type }): string =>
    type === "link"
      ? theme?.textColors?.BLUE_116
      : type === "disabled"
      ? theme?.textColors?.GREY_34
      : theme?.textColors?.BLACK_13};

  cursor: ${({ type }): string =>
    type === "disabled" ? "default" : "pointer"};

  :hover {
    color: ${({ theme, type }): string =>
      type === "link"
        ? theme?.textColors?.BLUE_116
        : type === "disabled"
        ? theme?.textColors?.GREY_34
        : theme?.textColors?.BLACK_13};
  }
`;
