import { Timeline } from "antd";
import styled from "styled-components";

export const CommentHistoryDrawerWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .drawer-title-wrapper {
    padding: 10px 25px;

    .drawer-title {
      margin-bottom: 0;
      font-size: 20px;
      font-family: OpenSansBold;
    }
  }

  .activity-history-wrapper {
    padding: 5px 25px 20px 25px;
    max-height: calc(100vh - 270px);
    overflow-y: auto;
  }

  .show-older-activities {
    text-align: start;
    padding-bottom: 25px;
    padding-left: 25px;
  }

  .no-activities {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    span {
      font-size: 16px;
    }
  }

  .node-activity-history {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
    padding-top: 15px;
  }
`;
export const FlexGrow = styled.div`
  flex-grow: 1;
`;

export const TimelineItemStyled = styled(Timeline.Item)`
  .timeline-item {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .activity-details {
      display: flex;
      column-gap: 5px;
      font-size: 13.6px;
      color: ${({ theme }): string => theme.textColors.GREY_40};
    }

    .activity-desc {
      background-color: ${({ theme }): string =>
        theme.textColors.LIGHT_GREY_22};
      font-size: 13.6px;
      font-style: italic;
      padding: 8px;
    }
  }
`;

export const TimelineItemDescStyled = styled.div<{ isComment?: boolean }>`
  background-color: ${({ theme, isComment }): string =>
    isComment ? theme.textColors.LIGHT_GREY_22 : "transparent"};
  font-size: 13.6px;
  font-style: italic;
  padding: 8px;
`;

export const CommentSection = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px 25px 20px 25px;

  .form-style {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .form-label {
      margin-bottom: 0;
      color: ${({ theme }): string => theme.textColors.GREY_24};
      font-size: 15px;
      font-family: OpenSansSemiBold;
    }

    .form-components {
      width: 100%;
      display: flex;
      gap: 10px;
    }

    .ant-input {
      padding: 19px 14.4px;
      min-height: 60.8px;
      max-height: 110px !important;
    }
  }

  .textarea-field-container {
    width: 100%;
  }

  .send-comment {
    border: solid 1px ${({ theme }): string => theme.borderColors.GREY_56};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 50px;
  }
`;
