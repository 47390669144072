import { useCallback } from "react";
import { lightCrossIcon, noAlertExistsIcon } from "../../svgs";
import { getEnvVariables } from "../../utils";

import LinkButton from "../linkbutton/linkbutton";
import { GritterNotificationStyled } from "./gritternotification.styles";

import { GritterNotificationTypes } from "./gritternotification.types";

const cancelIcon = lightCrossIcon("10", "10");

const GritterNotification = ({
  message,
  showIcon = true,
  type = "warning",
  actionTitle = "",
  customJsx,
  showCancelIcon = false,
  onCancelClick,
  addBorder = false,
}: GritterNotificationTypes): JSX.Element => {
  const { REACT_APP_CONTACT_US_EMAIL } = getEnvVariables();

  const onCrossIconClick = useCallback(
    (event) => {
      event?.stopPropagation();
      onCancelClick?.();
    },
    [onCancelClick]
  );

  return (
    <GritterNotificationStyled
      className="gritter-notification"
      type={type}
      addBorder={addBorder}
    >
      {showIcon && (
        <div className="notification-icon">{noAlertExistsIcon("12", "12")}</div>
      )}

      <div className="notification-message">
        {message}

        <div className="notification-link">
          {customJsx ||
            (actionTitle && (
              <a
                className="steward-email"
                href={`mailto:${REACT_APP_CONTACT_US_EMAIL}`}
              >
                {actionTitle}
              </a>
            ))}
        </div>
      </div>

      {showCancelIcon && (
        <LinkButton
          className="notification-cancel-icon"
          onClick={(event): void => onCrossIconClick(event)}
        >
          {cancelIcon}
        </LinkButton>
      )}
    </GritterNotificationStyled>
  );
};

export default GritterNotification;
