import { Edge } from "reactflow";

//        <--Constants Start-->
import {
  LINEAGE_EDGE_ARROW_DIM_COLOR,
  LINEAGE_EDGE_NORMAL_COLOR,
  LINEAGE_EDGE_PROMINENT_COLOR,
  LINEAGE_EDGE_STROKE_DIM_COLOR,
} from "../lineagepage.constants";
//        <--Constants End-->

//        <--Parser-->
//        <--Parser-->

//        <--Types Start-->
import { UpdateEdgesParameteres } from "./lineagepage.utils.types";

import { edgeCommonProperties } from "../../../parsers/lineageparser/lineageparser.util";
//        <--Types End-->

function updateBirectionalEdges({
  edges = [],
  ids = [],
}: UpdateEdgesParameteres): Edge[] {
  return edges?.map((item) => {
    const isNotContainingId =
      !ids?.includes(item?.target) || !ids?.includes(item?.source);

    return {
      ...item,
      data: {
        ...item?.data,
        codeOpacity: isNotContainingId ? 0 : 1,
      },

      markerEnd: {
        ...edgeCommonProperties?.markerEnd,
        color: isNotContainingId
          ? LINEAGE_EDGE_ARROW_DIM_COLOR
          : LINEAGE_EDGE_PROMINENT_COLOR,
      },
      style: {
        ...item?.style,
        stroke: isNotContainingId
          ? LINEAGE_EDGE_STROKE_DIM_COLOR
          : LINEAGE_EDGE_PROMINENT_COLOR,
      },
      // Reverted lineage drill down enhancement changes
      // hidden: isDrillDown && isNotContainingId,
    };
  });
}

function updateUniDirectionalEdges({
  edges = [],
  ids = [],
  direction,
}: UpdateEdgesParameteres): Edge[] {
  const isBackwardDirection = direction === "BWD";
  return edges?.map((item) => {
    const isNotContainingId = !ids?.includes(
      isBackwardDirection ? item?.target : item?.source
    );
    return {
      ...item,
      markerEnd: {
        ...edgeCommonProperties?.markerEnd,
        color: isNotContainingId
          ? LINEAGE_EDGE_ARROW_DIM_COLOR
          : LINEAGE_EDGE_PROMINENT_COLOR,
      },
      style: {
        ...item?.style,
        stroke: isNotContainingId
          ? LINEAGE_EDGE_STROKE_DIM_COLOR
          : LINEAGE_EDGE_PROMINENT_COLOR,
      },
      // Reverted lineage drill down enhancement changes
      // hidden: isDrillDown && isNotContainingId,
    };
  });
}

function updateEdgesToDefaultState({
  edges = [],
}: UpdateEdgesParameteres): Edge[] {
  return edges.map((item) => ({
    ...item,
    markerEnd: {
      ...edgeCommonProperties?.markerEnd,
      color: LINEAGE_EDGE_NORMAL_COLOR,
    },
    style: {
      ...item?.style,
      stroke: LINEAGE_EDGE_NORMAL_COLOR,
    },
    // Reverted lineage drill down enhancement changes
    // hidden: false,
  }));
}

export function updateEdges(params: UpdateEdgesParameteres): Edge[] {
  switch (params?.mode) {
    case "bi-directional":
      return updateBirectionalEdges(params);
    case "uni-directional":
      return updateUniDirectionalEdges(params);
    default:
      return updateEdgesToDefaultState(params);
  }
}
