import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetAppState } from "../../../../customhooks";

import { getViewOnlyChatQuestionPageUrl } from "../../../../utils/getviewonlychatpageurl";
import { ReviewCaddiFeedbacksModalStyled } from "./reviewcaddifeedbacksmodal.styles";

import {
  FeedbackQstPreviewIframePostMessage,
  ReviewCaddiFeedbacksModalProps,
} from "./reviewcaddifeedbacksmodal.types";

import {
  ReviewCaddiFeedbackContentHeader,
  ReviewCaddiFeedbacksModalList,
  ReviewCaddiFeedbacksModalListItemProps,
} from "./reviewcaddifeedbacksmodal.components";

import StateHandler from "../../../statehandler/statehandler";

import {
  CaddiAnalyticsStatusIdType,
  ReviewCaddiFeedbacksListItemType,
  getParsedReviewCaddiFeedbacksList,
} from "../../../../parsers";
import WorkflowHOC from "../../../workflowhoc/workflowhoc";

import { useRequestWithMethod } from "../../../../api";
import { getTimezone, openNotification } from "../../../../utils";
import SuccessNotificationMessage from "../../../successnotificationmessagerendrer";

const ReviewCaddiFeedbacksModal = (): JSX.Element => {
  const { modal: { modalProps = {} } = {} } = useGetAppState();

  const {
    botId = "",
    chatId = 0,
    queryId = 0,
    updateFeedbackStatusConfig,
    getDataApiCommonProps,
  } = modalProps as ReviewCaddiFeedbacksModalProps;

  const { commonApiProps, sortedFeedbackStatuses, populateData } =
    updateFeedbackStatusConfig || {};

  const {
    nodeType,
    currentNodeId,
    isSelectAll,
    countFilter,
    filterModel,
  } = commonApiProps;

  const [selectedQuestionId, setSelectedQuestionId] = useState(`${queryId}`);
  const [isIFrameLoaded, setIsIFrameLoaded] = useState(false);

  const [feedbacksList, setFeedbacksList] = useState<
    ReviewCaddiFeedbacksListItemType[]
  >([]);

  const onGetReviewCaddiFeedbacksListSuccess = useCallback((res) => {
    const parsedRes = getParsedReviewCaddiFeedbacksList(res);
    setFeedbacksList(parsedRes);
  }, []);

  const { onExecuteRequest, isLoading, error } = useRequestWithMethod(
    "get_review_caddi_feedbacks_list",
    [currentNodeId, botId],
    true,
    onGetReviewCaddiFeedbacksListSuccess
  );

  const {
    onExecuteRequest: onExecuteRequestUpdateStatus,
    isLoading: isLoadingUpdateStatus,
    error: errorUpdateStatus,
  } = useRequestWithMethod(
    "update_field_data",
    [nodeType, currentNodeId],
    false
  );

  useEffect(() => {
    const { payload } = getDataApiCommonProps({});
    onExecuteRequest({
      ...payload,
      timezone: getTimezone(),
    });
  }, []);

  const onChangeSelection = useCallback(
    (id: string) => setSelectedQuestionId(id),
    []
  );

  const reviewFeedbacksList = useMemo(
    (): ReviewCaddiFeedbacksModalListItemProps[] =>
      feedbacksList?.map((item: ReviewCaddiFeedbacksListItemType) => ({
        id: item?.query_id,
        feedbackType: item?.feedbackType,
        question: item?.question,
        chatTime: item?.chat_time,
        isSelected: selectedQuestionId === item?.query_id,
        onClick: (): void => onChangeSelection(item?.query_id),
      })),
    [selectedQuestionId, feedbacksList, onChangeSelection]
  );

  const selectedQuestionData = useMemo(
    () =>
      (feedbacksList?.find(
        (item: ReviewCaddiFeedbacksListItemType) =>
          item?.query_id === selectedQuestionId
      ) as ReviewCaddiFeedbacksListItemType) || {},
    [selectedQuestionId, feedbacksList]
  );

  const onUpdateStatus = useCallback(
    (qryId: string, statusId: CaddiAnalyticsStatusIdType, status: string) => {
      onExecuteRequestUpdateStatus(
        [
          {
            field: "qry_ids",
            qry_fdbk_status: statusId,
            qry_ids: [qryId],
            is_mass_update: false,
            isSelectAll,
            filterModel,
            countFilter,
          },
        ],
        undefined,
        (): void => {
          setFeedbacksList((st) =>
            st?.map((item) => ({
              ...item,
              status_id: item?.query_id === qryId ? statusId : item?.status_id,
              status: item?.query_id === qryId ? status : item?.status,
            }))
          );
          populateData({});
          openNotification(
            <SuccessNotificationMessage message="Status has been updated!" />
          );
        }
      );
    },
    [isSelectAll, filterModel, countFilter, setFeedbacksList, populateData]
  );

  const viewOnlyChatQuestionURL = getViewOnlyChatQuestionPageUrl();

  useEffect(() => {
    if (
      selectedQuestionData?.query_id &&
      selectedQuestionData?.chat_id &&
      isIFrameLoaded
    ) {
      const iframe = document.getElementById("qryQstPreview");
      const iframeWindow = (iframe as any)?.contentWindow;

      const stateData: FeedbackQstPreviewIframePostMessage = {
        qryId: selectedQuestionData?.query_id,
        chatId: selectedQuestionData?.chat_id,
        id: "FEEDBACK_QST_PREVIEW",
      };

      iframeWindow.postMessage(stateData, "*"); // '*' allows any origin (consider a specific origin for security)
    }
  }, [selectedQuestionData?.query_id, isIFrameLoaded]);

  const onIframeLoad = useCallback(() => {
    setIsIFrameLoaded(true);
  }, []);

  return (
    <StateHandler
      isFetching={isLoading || isLoadingUpdateStatus}
      error={error || errorUpdateStatus}
      showSpinner={isLoading}
      isModal={isLoadingUpdateStatus || !isLoading}
    >
      <ReviewCaddiFeedbacksModalStyled>
        <ReviewCaddiFeedbacksModalList
          reviewFeedbacksList={reviewFeedbacksList}
        />

        <div className="review-caddi-feedback-content-sec">
          <WorkflowHOC nodeType="QRY" nodeId={selectedQuestionId}>
            <ReviewCaddiFeedbackContentHeader
              qstId={selectedQuestionId}
              selectedQuestionData={selectedQuestionData}
              sortedFeedbackStatuses={sortedFeedbackStatuses}
              onUpdateStatus={onUpdateStatus}
            />
          </WorkflowHOC>

          <StateHandler
            isFetching={!isIFrameLoaded && !isLoading}
            showSpinner={!isIFrameLoaded && !isLoading}
            error={false}
          >
            <div />
          </StateHandler>

          <iframe
            id="qryQstPreview"
            title={`chat-${chatId}`}
            src={viewOnlyChatQuestionURL}
            onLoad={onIframeLoad}
          />
        </div>
      </ReviewCaddiFeedbacksModalStyled>
    </StateHandler>
  );
};

export default ReviewCaddiFeedbacksModal;
