import { LineagePreviewerProps } from "../../components/lineagepreviewer/lineagepreviewer.types";
import { lineageDocResponse } from "../parser.types";

function getParsedLineageData(
  lineageValues: lineageDocResponse["current"]["value"] = [],
  isCurrent?: boolean
): LineagePreviewerProps["data"]["level2"] {
  return (
    lineageValues?.map((item) => ({
      id: `${item?.node_id}`,
      nodeName: item?.node_name || "",
      nodeType: item?.node_type,
      nodeSubType: item?.node_sub_type,
      isCurrentNode: !!isCurrent,
    })) || []
  );
}

export function getLineagePreviewData(
  lineageData: lineageDocResponse
): LineagePreviewerProps {
  const level1 = getParsedLineageData(lineageData?.parent?.value);
  const level2 = getParsedLineageData(lineageData?.parent_or_btwn?.value);
  const level3 = getParsedLineageData(lineageData?.current?.value, true);
  const level4 = getParsedLineageData(lineageData?.end_or_btwn?.value);
  const level5 = getParsedLineageData(lineageData?.end?.value);

  // undo after be
  return {
    data: {
      level1,
      level2,
      level3,
      level4,
      level5,
    },
    lineageExists: !!(
      level1.length ||
      level2.length ||
      level4.length ||
      level5.length
    ),
  };
}
