import { AxiosResponse } from "axios";
import { UserGroupResponse } from "..";

import {
  GetAllUserGroupsParserReturnType,
  EnabledObjectsResponse,
  ReturnTypeOfEnabledObjects,
  ReturnTypeOfUserRoleInfo,
  UserRoleInfoResponse,
  UserRolesParserReturnType,
  UserRolesResponseType,
  AccessTypesResponse,
  GetAllAccessTypesInGroupParserReturnType,
  GetAllAccessTypesParserReturnType,
  DvSumAccessTypesIds,
  AdminAccessEnabledObjsType,
  LineageAccessTypeIds,
} from "./userrolesparser.types";

import { apiToFormCriteria } from "../../forms/addgovernanceviewform/addgovernanceviewform.utils";

import { isJsonString, jsonParse } from "../../utils";
import { DEFAULT_ADMIN_ACCESS_ENABLED_OBJS } from "./userrolesparser.constants";

export function getParsedUserRoles({
  data,
}: AxiosResponse<UserRolesResponseType[]>): UserRolesParserReturnType[] {
  return data?.map((item, index) => {
    const lineageEntityLevel = jsonParse<{
      level: LineageAccessTypeIds;
    }>(item?.LINEAGE_ACCESS, true)?.level;

    const isFunctionalLineage = item?.LINEAGE_ACCESS_TYPE_ID === "FNC";
    const isTechnicalLineage = item?.LINEAGE_ACCESS_TYPE_ID === "TCH";

    const isEntityLevelOnly = lineageEntityLevel === "ENT";

    const entityLabel =
      isFunctionalLineage || isTechnicalLineage
        ? isEntityLevelOnly
          ? "Entity Level"
          : "Entity-Attribute Level"
        : "";
    return {
      value: item?.ROLE_ID,
      label: item?.ROLE_NAME || "",
      id: item?.ROLE_ID,
      name: item?.ROLE_NAME || "",
      description: item?.ROLE_DESC || "",
      is_predefined_role: item?.IS_PREDEFINED,
      admin_access_type: item?.ADMIN_ACCESS_TYPE || "",
      catalog_access_type: item?.CATALOG_ACCESS_TYPE || "",
      glossary_access_type: item?.GLOSSARY_ACCESS_TYPE || "",
      admin_access_type_id: item?.ADMIN_ACCESS_TYPE_ID || "",
      catalog_access_type_id: item?.CATALOG_ACCESS_TYPE_ID || "",
      glossary_access_type_id: item?.GLOSSARY_ACCESS_TYPE_ID || "",
      createdBy: item?.CREATED_BY || "",
      modifiedBy: item?.UPDATED_BY || "",
      groupIds: item?.USER_GROUP_IDS?.length
        ? item?.USER_GROUP_IDS?.split(",")
        : [],
      assignedUserGroups: item?.USER_GROUPS || "",
      modifiedOn: item?.UPDATED_ON || "",
      action: "",
      lineage_access_type: item?.LINEAGE_ACCESS_TYPE || "",
      lineage_access_type_id: item?.LINEAGE_ACCESS_TYPE_ID,
      lineage_entity_level: lineageEntityLevel,
      admin_access: isJsonString(item?.ADMIN_ACCESS)
        ? jsonParse(item?.ADMIN_ACCESS).enabled_objects
        : [],
      lineage: `${item?.LINEAGE_ACCESS_TYPE || ""} ${
        entityLabel ? `(${entityLabel})` : ""
      }`,
      lineage_entity_label: entityLabel,
    };
  });
}

export function getParsedUserGroups({
  data,
}: AxiosResponse<UserGroupResponse[]>): GetAllUserGroupsParserReturnType[] {
  return data?.map((item) => ({
    key: `${item?.USER_GROUP_ID}`,
    name: item?.USER_GROUP_NAME || "",
    description: item?.ROLE_NAME || "",
    disabled: item?.IS_PREDEFINED ?? false,
  }));
}

export function getParsedAccessTypes({
  data = [],
}: AxiosResponse<
  AccessTypesResponse[]
>): GetAllAccessTypesInGroupParserReturnType {
  //For entity level and entity-attribute level
  const lineageSubOptions = [
    {
      MODULE_NAME: "USERROLE_LINEAGE_LEVEL",
      REF_ID: "ENT",
      REF_NAME: "Entity Level",
      REF_DISPLAY_ORDER: 4,
    },

    {
      MODULE_NAME: "USERROLE_LINEAGE_LEVEL",
      REF_ID: "ATR",
      REF_NAME: "Entity-Attribute Level",
      REF_DISPLAY_ORDER: 5,
    },
  ];

  const finalData = [...data, ...lineageSubOptions];

  return finalData
    ?.map(
      (item): GetAllAccessTypesParserReturnType => ({
        id: item?.REF_ID?.trim() as DvSumAccessTypesIds,
        moduleName: item?.MODULE_NAME || "",
        displayName: item?.REF_NAME || "",
        displayOrder: item?.REF_DISPLAY_ORDER,
      })
    )
    .sort(
      (
        prev: GetAllAccessTypesParserReturnType,
        curr: GetAllAccessTypesParserReturnType
      ) => prev.displayOrder - curr.displayOrder
    )
    .reduce(
      (
        prev: { [key: string]: GetAllAccessTypesParserReturnType[] },
        curr: GetAllAccessTypesParserReturnType
      ) => {
        if (prev[`${curr.moduleName}`]) {
          prev[`${curr.moduleName}`]?.push(curr);

          return prev;
        }

        prev[`${curr.moduleName}`] = [curr];

        return prev;
      },
      {}
    );
}

export function getParsedEnabledObjects({
  data,
}: AxiosResponse<EnabledObjectsResponse>): ReturnTypeOfEnabledObjects {
  return (
    data?.map((node) => ({
      id: `${node?.node_id || 0}`,
      node_access: node?.node_access || "",
      key: node?.additional_info?.node_key || "",
      schema_keys: node?.additional_info?.schema_keys || "",
      source_key: node?.additional_info?.source_key || "",
      domain_key: node?.additional_info?.domain_key || "",
      node_type: node?.node_type,
    })) || []
  );
}

export function getParsedUserRoleInfo({
  data,
}: AxiosResponse<UserRoleInfoResponse>): ReturnTypeOfUserRoleInfo {
  const adminAccess = jsonParse(data?.role_settings?.ADMIN_ACCESS);
  const catalogAccess = jsonParse(data?.role_settings?.CATALOG_ACCESS);
  const glossaryAccess = jsonParse(data?.role_settings?.GLOSSARY_ACCESS);

  const catalogAccessFilterLength = !!catalogAccess?.filter_criteria?.TBL
    ?.filter?.length;
  const glossaryAccessFilterLength = !!glossaryAccess?.filter_criteria?.TRM
    ?.filter?.length;

  return {
    admin_access_info: {
      default_flag: adminAccess?.default_flag || false,
      is_finegrain_enabled: adminAccess?.is_finegrain_enabled || false,
      permissions: DEFAULT_ADMIN_ACCESS_ENABLED_OBJS?.map((item) => {
        const selectedValue = adminAccess?.enabled_objects
          ? adminAccess?.enabled_objects?.find(
              (enabledObject: AdminAccessEnabledObjsType) =>
                enabledObject?.tab_name === item?.name
            )?.access_type
          : item?.selectedValue;

        return {
          ...item,
          selectedValue,
        };
      }),
    },
    catalog_access_info: {
      default_flag: catalogAccess?.default_flag || false,
      is_finegrain_enabled: catalogAccess?.is_finegrain_enabled || false,
      is_edit_mode: catalogAccessFilterLength || false,
      is_citeria_exists: catalogAccessFilterLength || false,
      filter_criteria:
        apiToFormCriteria(catalogAccess?.filter_criteria?.TBL?.filter) || [],
    },
    glossary_access_info: {
      default_flag: glossaryAccess?.default_flag || false,
      is_finegrain_enabled: glossaryAccess?.is_finegrain_enabled || false,
      is_edit_mode: glossaryAccessFilterLength || false,
      is_citeria_exists: glossaryAccessFilterLength || false,
      filter_criteria:
        apiToFormCriteria(glossaryAccess?.filter_criteria?.TRM?.filter) || [],
    },
  };
}
