import styled from "styled-components";

export const ViewQueryCodeModalWrapperStyled = styled.div`
  padding: 15px 20px 20px 20px;

  .node-code-info {
    margin-top: 8px;
  }

  .query-wrapper {
    max-height: calc(100vh - 145px);
    min-height: calc(100vh - 145px);
  }

  .collapse-icon {
    height: 19px;
  }
`;
