//        <--Types Start-->
import { LineagePageUrlParamsType } from "../lineagepage.types";
//        <--Types End-->

export function getLineagePageQueryParams(
  params: URLSearchParams
): LineagePageUrlParamsType {
  const urlQueryParamsKey: { [key: string]: string } = {};

  params.forEach((val, key: string) => {
    urlQueryParamsKey[key] = val;
  });

  return urlQueryParamsKey;
}
