import { Edge } from "reactflow";
import { LineageNode } from "../../../parsers/lineageparser/lineageparser.types";

export const splitIds = (urlExpandedId: string): string[] => {
  try {
    const splitIds = urlExpandedId?.split(",")?.filter(Boolean); // Split and remove empty values
    return splitIds?.length ? splitIds : [];
  } catch (error) {
    return [];
  }
};

export const checkIfNodeIdExists = (urlId: string, nodeId: string): boolean => {
  const expandedNodeIds = splitIds(urlId);
  return expandedNodeIds?.includes(nodeId);
};

export const getUpdatedIds = (
  urlId: string,
  nodeId: string,
  filterOutAlreadyExistingId = true
): {
  updatedUrlIds: string;
  isCurrentNodeIdAlreadyInUrl: boolean;
  isAnyNodeAlreadyExists: boolean;
} => {
  const nodeIdsArray = splitIds(urlId);
  const isAnyNodeAlreadyExists = nodeIdsArray?.length >= 1;

  const isCurrentNodeIdAlreadyInUrl = nodeIdsArray?.includes(nodeId); // Check if the node is already expanded

  const updatedUrlIdsArray = isCurrentNodeIdAlreadyInUrl
    ? filterOutAlreadyExistingId
      ? nodeIdsArray?.filter((item) => item !== nodeId)
      : [...nodeIdsArray]
    : [...nodeIdsArray, nodeId];

  const updatedUrlIds = updatedUrlIdsArray?.join(",");

  return {
    updatedUrlIds,
    isCurrentNodeIdAlreadyInUrl,
    isAnyNodeAlreadyExists,
  };
};

export const onToggleExpandedNodeColumnsVisibility = (
  nodes: LineageNode[],
  expandedIds: string
): LineageNode[] => {
  const updatedChildNodes =
    nodes?.map((item) => {
      const isColumnNode = item?.data?.level === 2; //column
      const parentNodeId = item?.parentNode || "";
      const isParentNodeExpanded = expandedIds?.includes(parentNodeId);

      return {
        ...item,
        hidden: isColumnNode ? !isParentNodeExpanded : item?.hidden,
        data: {
          ...item?.data,
        },
      };
    }) || [];

  return updatedChildNodes;
};

export const onToggleExpandedNodeEdgesVisibility = (
  nodes: LineageNode[],
  edges: Edge[]
): Edge[] => {
  const updatedEdges = edges?.map((edge) => {
    const sourceNode = nodes?.find((node) => node?.id === edge?.source);
    const targetNode = nodes?.find((node) => node?.id === edge?.target);

    const isSourceHidden = sourceNode?.hidden;
    const isTargetHidden = targetNode?.hidden;

    return {
      ...edge,
      hidden: isSourceHidden || isTargetHidden, // Hide edge if either connected node is hidden
    };
  });

  return updatedEdges;
};

// remove this
export const getExpandedNodeChildColumns = (
  nodes: LineageNode[],
  nodeId: string
): LineageNode[] => {
  const updatedChildNodes =
    nodes?.map((item) => {
      const isParentNodeExpanded = Number(item?.parentNode) === Number(nodeId);

      return {
        ...item,
        hidden: isParentNodeExpanded ? !item?.hidden : item?.hidden,
        data: {
          ...item?.data,
        },
      };
    }) || [];

  return updatedChildNodes;
};
