import { useMemo } from "react";
import { ETL_SECTIONS } from "../../constants/labelconstants/etlconfig";

import { codeIcon } from "../../svgs";

import ContentPreviewer from "../contentpreviewer/contentpreviewer";

import { Section } from "../section";

import { etlCodeRefDrawer } from "./etlcodesource.renderer";

import {
  ETLCodeRefDrawerHeaderStyled,
  ETLCodeRefDrawerSectionWrapperStyled,
  ETLCodeRefDrawerStyled,
  RefDrawerHeaderWrapperStyled,
} from "./etlcodesource.styles";

import { EtlCodeSourcePropsType } from "./etlcodesource.types";

const edgeCodeIcon = codeIcon();

const titleSecProps = {
  className: "col-ref-sec-titile",
  showBorderBottom: false,
  isShowIcon: false,
};

const ETLCodeSource = (props: EtlCodeSourcePropsType): JSX.Element => {
  const etlCodeRefDrawerRendrer = useMemo(() => {
    return etlCodeRefDrawer;
  }, []);

  const { metaInfo, code = "" } = props;

  const refDrawerHeaderJsx = (): JSX.Element => (
    <RefDrawerHeaderWrapperStyled>
      <div className="icon">{edgeCodeIcon}</div>
      <span className="etl-name">Edge Information</span>
    </RefDrawerHeaderWrapperStyled>
  );

  return (
    <ETLCodeRefDrawerStyled>
      <ETLCodeRefDrawerHeaderStyled>
        {refDrawerHeaderJsx()}
      </ETLCodeRefDrawerHeaderStyled>
      <ETLCodeRefDrawerSectionWrapperStyled>
        <div className="etl-metadata-info">
          <Section
            secTitle={{
              title: ETL_SECTIONS?.metaInfo,
              ...titleSecProps,
            }}
            secRowData={metaInfo?.map((item) => ({
              ...item,
              customJSX: etlCodeRefDrawerRendrer?.[item?.title]?.(item),
            }))}
          />
        </div>
        <div className="etl-code-info">
          <ContentPreviewer
            title="Source Code"
            showCrossIcon={false}
            marginBottom="0"
            sqlQuery={code}
            addScroll
            showCollapseIcon={false}
            showExpandIcon
            expandedModalTitle={refDrawerHeaderJsx}
          />
        </div>
      </ETLCodeRefDrawerSectionWrapperStyled>
    </ETLCodeRefDrawerStyled>
  );
};

export default ETLCodeSource;
