import { checkDataTypeOfColumn } from "../../../../utils";
import {
  FieldBase,
  RadioFieldMapType,
  SchemaType,
  SectionField,
} from "../../dynamicform/dynamicform.types";
import { ValueRangeFormConfigType } from "./addvaluerangeform.types";

export const addValueRangeFormType = ({
  watchSpecify,
  watchColumnType,
  isFromRuleSet,
  onColumnValueChange,
  onSpecifyComparisonTypeChange,
  refDictSelectOptions,
  columnSelectOptions,
  fieldDictionarySelect,
  dictListKeys,
  dictListValues,
  isSelectedDictionaryHierarchial,
  onDictionaryChange,
  minMaxReferenceColumSelectFieldOptions,
  specifySelectFieldOptions,
  existingFilters,
  isEdit,
  intialSpecifyFields,
  isDictInEditMode,
  setValue,
}: ValueRangeFormConfigType): SchemaType => {
  const { isLoading, values } = dictListValues || {};

  const includeCase = watchColumnType === "STR";

  const { isDateColumn: isDateFieldColumn } = checkDataTypeOfColumn(
    watchColumnType
  );

  const COLUMN_SELECT_FIELD_MAP: FieldBase = {
    id: "column",
    type: "select",
    name: "column",
    label: "Column",
    required: true,
    onChange: onColumnValueChange,
    options: columnSelectOptions,
    placeholder: "Select",
    showSearch: true,
  };

  const SPECIFY_INPUT_FIELD_MAP: FieldBase = {
    id: "specify",
    type: "select",
    name: "specify",
    label: "",
    compareLabel: "Specify",
    options: specifySelectFieldOptions || [],
    child: true,
    onChange: onSpecifyComparisonTypeChange,
    propOnClear: () => {
      setValue?.("specify", "RV");
    },
  };

  const DEFINE_INPUT_FIELD_MAP: FieldBase = {
    id: "define",
    type: "select",
    name: "define",
    label: "",
    compareLabel: "to define",
    options: [
      { value: "invalid", label: "Invalid Values" },
      { value: "valid", label: "Valid Values" },
    ],
    child: true,
  };

  const COMPARISON_TYPE_FIELD_MAP: FieldBase = {
    id: "comparision_type",
    type: "comparision",
    name: "comparision_type",
    label: "Comparison Type",
    required: true,
    children: [SPECIFY_INPUT_FIELD_MAP, DEFINE_INPUT_FIELD_MAP],
  };

  const ABSOLUTE_MIN_VALUE_FIELD_MAP: FieldBase = {
    id: "min_value",
    label: "",
    name: "min_value",
    type: isDateFieldColumn ? "date" : "input",
    placeholder: "Input",
    child: true,
  };

  const ABSOLUTE_MAX_VALUE_FIELD_MAP: FieldBase = {
    id: "max_value",
    label: "",
    name: "max_value",
    type: isDateFieldColumn ? "date" : "input",
    placeholder: "Input",
    child: true,
  };

  const EXPRESSION_MIN_VALUE_FIELD_MAP: FieldBase = {
    id: "min_expr",
    label: "",
    name: "min_expr",
    type: "input",
    placeholder: "Input",
    child: true,
  };

  const EXPRESSION_MAX_VALUE_FIELD_MAP: FieldBase = {
    id: "max_expr",
    label: "",
    name: "max_expr",
    type: "input",
    placeholder: "Input",
    child: true,
  };

  const minOperatorSelectOptions = [
    ...((watchSpecify === "SV" && [
      { value: "NEQ", label: "(!=) Does Not Equal" },
      { value: "EQ", label: "(=) Equal To" },
    ]) ||
      []),
    { value: "GTH", label: "(>) Greater than" },
    { value: "GTE", label: "(>=) Greater than Equal To" },
    ...((watchSpecify === "SV" && [
      { value: "LTH", label: "(<) Less than" },
      { value: "LTE", label: "(<=) Less than Equal To" },
    ]) ||
      []),
  ];

  const MIN_OPERATOR_SELECT_FIELD_MAP: FieldBase = {
    id: "min_oper",
    type: "select",
    name: "min_oper",
    label: "",
    compareLabel: "",
    placeholder: "Select Operator",
    options: minOperatorSelectOptions || [],
    child: true,
  };

  const MIN_COLUMN_ID_SELECT_FIELD_MAP: FieldBase = {
    id: "min_column_id",
    type: "select",
    name: "min_column_id",
    label: "",
    compareLabel: "",
    placeholder: "Select Column",
    options: minMaxReferenceColumSelectFieldOptions || [],
    child: true,
  };

  const MAX_OPERATOR_SELECT_FIELD_MAP: FieldBase = {
    id: "max_oper",
    type: "select",
    name: "max_oper",
    label: "",
    compareLabel: "",
    placeholder: "Select Operator",
    options: [
      { value: "LTH", label: "(<) Less than" },
      { value: "LTE", label: "(<=) Less than Equal To" },
    ],
    child: true,
  };

  const MAX_COLUMN_ID_SELECT_FIELD_MAP: FieldBase = {
    id: "max_column_id",
    type: "select",
    name: "max_column_id",
    label: "",
    compareLabel: "",
    placeholder: "Select Column",
    options: minMaxReferenceColumSelectFieldOptions || [],
    child: true,
  };

  const SINGLE_VALUE_RADIO_FIELDS_MAP: RadioFieldMapType = [
    {
      value: "ABS",
      label: "Absolute Value",
      // labelDesc: "datDictDatQualAddNewRleCrsSysInrJoin",
      children: ABSOLUTE_MIN_VALUE_FIELD_MAP,
    },
    {
      value: "EXP",
      label: "Expression",
      // labelDesc: "datDictDatQualAddNewRleCrsSysOutJoin",
      children: EXPRESSION_MIN_VALUE_FIELD_MAP,
    },
    {
      value: "REF",
      label: "Reference Column",
      // labelDesc: "datDictDatQualAddNewRleCrsSysOutJoin",
      children: {
        id: "range_value_min_comparision",
        label: "",
        name: "range_value_min_comparision",
        type: "comparision",
        children: [
          MIN_OPERATOR_SELECT_FIELD_MAP,
          MIN_COLUMN_ID_SELECT_FIELD_MAP,
        ],
        placeholder: "Select",
      },
    },
  ];

  const SINGLE_VALUE_RADIO_FIELDS_WRAPPER_MAP: FieldBase = {
    id: "min_value_type",
    type: "radio_group",
    name: "min_value_type",
    label: "Value",
    options: SINGLE_VALUE_RADIO_FIELDS_MAP,
  };
  const RANGE_VALUE__MIN_RADIO_FIELDS_WRAPPER_MAP: FieldBase = {
    id: "min_value_type",
    type: "radio_group",
    name: "min_value_type",
    label: "Min Value",
    options: SINGLE_VALUE_RADIO_FIELDS_MAP,
  };

  const RANGE_VALUE_MAX_RADIO_FIELDS_MAP: RadioFieldMapType = [
    {
      value: "ABS",
      label: "Absolute Value",
      // labelDesc: "datDictDatQualAddNewRleCrsSysInrJoin",
      children: ABSOLUTE_MAX_VALUE_FIELD_MAP,
    },
    {
      value: "EXP",
      label: "Expression",
      // labelDesc: "datDictDatQualAddNewRleCrsSysOutJoin",
      children: EXPRESSION_MAX_VALUE_FIELD_MAP,
    },
    {
      value: "REF",
      label: "Reference Column",
      // labelDesc: "datDictDatQualAddNewRleCrsSysOutJoin",
      children: {
        id: "range_value_max_comparision",
        label: "",
        name: "range_value_max_comparision",
        type: "comparision",
        children: [
          MAX_OPERATOR_SELECT_FIELD_MAP,
          MAX_COLUMN_ID_SELECT_FIELD_MAP,
        ],
        placeholder: "Select",
      },
    },
  ];

  const RANGE_VALUE_RADIO_FIELDS_WRAPPER_MAP: FieldBase = {
    id: "max_value_type",
    type: "radio_group",
    name: "max_value_type",
    label: "Max Value",
    options: RANGE_VALUE_MAX_RADIO_FIELDS_MAP,
  };

  const INCLUDE_BLANKS_FIELD_MAP: FieldBase = {
    id: "include_blanks",
    type: "checkbox",
    name: "include_blanks",
    label: "Include Empty or Null",
    // description: datDictDatQualAddNewRleFndStrProc,
  };

  const INCLUDE_CASE_FIELD_MAP: FieldBase = {
    id: "ignore_case",
    type: "checkbox",
    name: "ignore_case",
    label: "Ignore Case",
    // description: datDictDatQualAddNewRleFndStrProc,
  };

  const FILTER_CONDITION_FIELD_MAP: FieldBase = {
    id: "filter_criteria",
    type: "filter",
    name: "filter_criteria",
    label: "Filter Condition",
    // description: datDictDatQualAddNewRleDtaDifFlt,
    existingFilters,
  };

  return [
    ...((!isFromRuleSet &&
      !isEdit && [
        {
          id: "basic",
          type: "section",
          fields: [
            {
              id: "rule_type",
              type: "select",
              name: "rule_type",
              label: "Rule Type",
              disabled: true,
              required: true,
            },
            {
              id: "rule_description",
              type: "text_area",
              name: "rule_description",
              label: "Rule Description",
              placeholder: "Description",
              description: "",
            },
          ],
        } as SectionField,
      ]) ||
      []),

    {
      id: "basic_input_SV",
      type: "section",
      title: isFromRuleSet ? "" : "Basic Input",
      fields: [
        ...((!isFromRuleSet && [COLUMN_SELECT_FIELD_MAP]) || []),
        COMPARISON_TYPE_FIELD_MAP,
        SINGLE_VALUE_RADIO_FIELDS_WRAPPER_MAP,
        INCLUDE_BLANKS_FIELD_MAP,
        ...((includeCase && [INCLUDE_CASE_FIELD_MAP]) || []),
      ],
    },
    {
      id: "basic_input_RV",
      type: "section",
      title: isFromRuleSet ? "" : "Basic Input",
      fields: [
        ...((!isFromRuleSet && [COLUMN_SELECT_FIELD_MAP]) || []),
        COMPARISON_TYPE_FIELD_MAP,
        RANGE_VALUE__MIN_RADIO_FIELDS_WRAPPER_MAP,
        RANGE_VALUE_RADIO_FIELDS_WRAPPER_MAP,
        INCLUDE_BLANKS_FIELD_MAP,
        ...((includeCase && [INCLUDE_CASE_FIELD_MAP]) || []),
      ],
    },
    {
      id: "basic_input_FD",
      type: "section",
      title: isFromRuleSet ? "" : "Basic Input",
      fields: [
        ...((!isFromRuleSet && [COLUMN_SELECT_FIELD_MAP]) || []),

        COMPARISON_TYPE_FIELD_MAP,
        {
          id: "select_values",
          type: "choice_box",
          name: "select_values",
          label: "Select Values",
          options: fieldDictionarySelect,
        },
        INCLUDE_BLANKS_FIELD_MAP,
        ...((includeCase && [INCLUDE_CASE_FIELD_MAP]) || []),
      ],
    },
    {
      id: "basic_input_RD",
      type: "section",
      title: isFromRuleSet ? "" : "Basic Input",
      fields: [
        ...((!isFromRuleSet && [COLUMN_SELECT_FIELD_MAP]) || []),
        COMPARISON_TYPE_FIELD_MAP,
        {
          id: "dictionary",
          type: "dictionary",
          name: "dictionary",
          label: "Dictionary",
          required: true,
          options: refDictSelectOptions,
          isSelectedDictionaryHierarchial,
          dictListKeys,
          dictListValues: values,
          dictListLoading: isLoading,
          placeholder: "Select",
          onChange: onDictionaryChange,
          showSearch: true,
          intialSpecifyFields,
          isDictionaryInEditMode: isDictInEditMode,
        },
        INCLUDE_BLANKS_FIELD_MAP,
        ...((includeCase && [INCLUDE_CASE_FIELD_MAP]) || []),
      ],
    },
    ...((!isFromRuleSet && [
      {
        id: "filter",
        type: "section",
        title: "",
        fields: [FILTER_CONDITION_FIELD_MAP],
      } as SectionField,
    ]) ||
      []),
  ];
};
