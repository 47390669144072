import { Tooltip } from "antd";
import { useController } from "react-hook-form";
import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../constants";
import {
  CheckboxFieldDescStyled,
  CheckboxFieldStyled,
} from "./checkboxfield.styles";
import { CheckboxFieldProps } from "./checkboxfield.types";
import InformativeText from "../../informativetext";

function CheckboxField({
  name = "",
  control,
  children,
  tooltipProps = { title: "" },
  disabled,
  description,
  ...props
}: CheckboxFieldProps): JSX.Element {
  const { overlayClassName } = tooltipProps;

  const {
    field: { ref, value, ...checkboxProps },
  } = useController({ name, control });

  return (
    <Tooltip
      overlayClassName={`${DVSUM_TOOLTIP_CLASS_NAME} ${overlayClassName ?? ""}`}
      {...tooltipProps}
    >
      <CheckboxFieldStyled
        disabled={disabled}
        {...props}
        {...checkboxProps}
        onChange={(e): void => {
          props?.onChange?.(e);
          checkboxProps?.onChange?.(e);
        }}
        ref={ref}
        checked={value}
      >
        {children}
      </CheckboxFieldStyled>
      <CheckboxFieldDescStyled>
        <InformativeText desc={description || ""} width="100%" />
      </CheckboxFieldDescStyled>
    </Tooltip>
  );
}

export default CheckboxField;
