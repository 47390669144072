import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { useRequestWithMethod } from "../../api";
import { useGetAllDataSourceTypes } from "../../api/sourcesservice/sourceservice";

import { Input } from "../../components/inputs";
import StateHandler from "../../components/statehandler/statehandler";

import { useCancelModal, useGetAppState } from "../../customhooks";
import { SourceTypeParserReturnType } from "../../parsers/sources/sourcesparser.types";

import { setModal } from "../../reducers/appreducer/appreducer";
import { crossIcon, searchIconLight } from "../../svgs";

import { AddSourceFormStyled } from "./addsourceform.styled";
import { getPostLoginData, sortObjectsArrayByKey } from "../../utils";

import {
  useGetAllSAWS,
  useGetSAWSDownloadLink,
} from "../../api/accountsettingsservice/sawsservice";
import { SourceTypes } from "../../app.types";
import { ELEMENT_IDS } from "../../constants";
import { AddSourceModalProps } from "./addsourceform.types";
import LinkButton from "../../components/linkbutton/linkbutton";
import { GritterNotification } from "../../components";

const sawsNotRequiredSources: SourceTypes[] = ["TBU", "EXL", "ADL", "PBI"];

const AddSourceForm = (): JSX.Element => {
  const {
    modal: { modalProps },
  } = useGetAppState();

  const { user_info: userInfo } = getPostLoginData();

  const { source_type_limit: sourceTypeLimit = "" } = userInfo;

  const { sourcesAvailable } = modalProps as AddSourceModalProps;

  const [searchText, setSearchText] = useState("");
  const [isGatewayInstalled, setIsGatewayInstalled] = useState(false);

  const {
    parsedData: sawsDownloadLink,
    isLoading: isLoadingDownloadLink,
  } = useGetSAWSDownloadLink();

  const {
    isFetching: isLoadingWebServices,
    error: sawsFetchingError,
    parsedData: parsedAllSAWS,
  } = useGetAllSAWS();

  const onChangeSearchText = useCallback(
    (event) => {
      setSearchText(event?.target?.value);
    },
    [searchText]
  );

  const {
    parsedData: parsedSourceTypes,
    isLoading: isLoadingSourceTypes,
    error: errorGettingSourceTypes,
  } = useGetAllDataSourceTypes();

  const isAnySourceDisabled = useMemo(
    () => sourcesAvailable?.length < parsedSourceTypes?.length,
    [sourcesAvailable, parsedSourceTypes]
  );

  const sortedSourceTypes = useMemo(
    () =>
      sortObjectsArrayByKey(
        parsedSourceTypes,
        "displayOrder"
      ) as SourceTypeParserReturnType[],
    [parsedSourceTypes]
  );
  const onCancel = useCancelModal();

  const dispatch = useDispatch();

  const isAnyActiveGatewayExist = useCallback((sawsNonParsedData): boolean => {
    return (
      sawsNonParsedData?.filter(
        (item: any) =>
          item?.saws_status === "ACT" || item?.saws_status === "CNF"
      ) || []
    )?.length;
  }, []);

  const openGatewayInstallerModal = useCallback(
    (sourceType: SourceTypeParserReturnType) => {
      dispatch(
        setModal({
          modalId: "download_gateway_installer_dialog",
          visible: true,
          modalTitle: "",
          modalProps: {
            sawsDownloadLink: sawsDownloadLink?.downloadLink,
            selectedSource: sourceType,
          },
        })
      );
    },
    [sawsDownloadLink]
  );

  const openAddSourceFieldsForm = useCallback(
    (sourceType: SourceTypeParserReturnType) => {
      dispatch(
        setModal({
          modalId: "add_source_fields",
          modalTitle: "Add Source",
          visible: true,
          modalProps: {
            selectedSource: sourceType,
          },
        })
      );
    },
    []
  );

  const onSourceTypeSelect = useCallback(
    (sourceType: SourceTypeParserReturnType) => {
      if (!sawsNotRequiredSources?.includes(sourceType?.id as SourceTypes)) {
        if (!isGatewayInstalled) {
          if (!isAnyActiveGatewayExist(parsedAllSAWS)) {
            openGatewayInstallerModal(sourceType);
          } else {
            setIsGatewayInstalled(true);
            openAddSourceFieldsForm(sourceType);
          }
        } else {
          openAddSourceFieldsForm(sourceType);
        }
      } else {
        openAddSourceFieldsForm(sourceType);
      }
    },
    [isGatewayInstalled, sawsDownloadLink, parsedAllSAWS]
  );

  return (
    <StateHandler
      isFetching={
        isLoadingSourceTypes || isLoadingDownloadLink || isLoadingWebServices
      }
      error={errorGettingSourceTypes || sawsFetchingError}
      isModal
    >
      <AddSourceFormStyled isFormContainsTwoItem={false}>
        <div className="first-screen">
          <div className="section-modal-title-bar">
            <div className="section-modal-title">Select Source</div>
            <div className="section-modal-title-close">
              <button
                type="button"
                aria-label="Close"
                className="ant-modal-close"
                onClick={onCancel}
              >
                <span className="ant-modal-close-x">
                  {crossIcon("20px", "20px")}
                </span>
              </button>
            </div>
          </div>
          <div className="section-header">
            <div className="left-bar">
              Choose a source type with which you want to proceed to
              configurations.
            </div>
            <div className="right-bar">
              <Input
                className="search-box"
                placeholder="Search"
                prefix={searchIconLight()}
                value={searchText}
                onChange={onChangeSearchText}
                fontSize="13px"
                width="248px"
                disabled={isLoadingSourceTypes}
              />
            </div>
          </div>
          {isAnySourceDisabled && (
            <GritterNotification
              message={`You have reached maximum unique source type limit. You can add upto ${sourceTypeLimit} unique sources. `}
              actionTitle="Contact DvSum Support to change"
            />
          )}
          <div className="section-body">
            <div className="sources-container">
              {sortedSourceTypes
                ?.filter(
                  (sourceType: SourceTypeParserReturnType) =>
                    searchText?.length === 0 ||
                    sourceType?.displayName
                      ?.toLowerCase()
                      .indexOf(searchText?.toLowerCase()) > -1
                )
                .map((sourceType: SourceTypeParserReturnType) => (
                  <LinkButton
                    className="source-tile"
                    data-sourceType={sourceType.id}
                    disabled={
                      !sourcesAvailable?.includes(sourceType?.sourceTypeId)
                    }
                    onClick={(): void => onSourceTypeSelect(sourceType)}
                    elementId={ELEMENT_IDS?.datsrc_src_add_src_id?.replace(
                      "**",
                      sourceType?.adhocInfo?.sourceTypeId?.toLocaleLowerCase() ||
                        ""
                    )}
                    key={`add-source-form-source-btn-${sourceType?.id}`}
                  >
                    <img src={`${sourceType?.adhocInfo?.logoUrl}`} />
                  </LinkButton>
                ))}
            </div>
          </div>
        </div>
      </AddSourceFormStyled>
    </StateHandler>
  );
};

export default AddSourceForm;
