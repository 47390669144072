import { filledCrossIcon, tickIcon } from "../svgs";

export const getEtlRunStatusIcon = (status: string): JSX.Element => {
  switch (status) {
    case "PSS":
      return tickIcon("12", "12");

    case "FAL":
      return filledCrossIcon("12", "12");

    default:
      return tickIcon();
  }
};

export const getEtlRunStatusText = (status: string): string => {
  switch (status) {
    case "PSS":
      return "Passed";

    case "FAL":
      return "Failed";

    default:
      return "";
  }
};
