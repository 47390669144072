import AgGridWithoutPagination from "../aggridwithoutpagination";
import {
  AgGridServerSidePaginationWithSelect,
  AgGridLocalPaginationWithSelect,
} from "../aggridpaginationwithselect";

import { TableWithFilterTilesProps } from "./tablewithfiltertiles.types";

import {
  FilterStyled,
  TableWithFilterTilesStyled,
} from "./tablewithfiltertiles.styles";
import { numberFormatter } from "../../utils";
import { ELEMENT_IDS } from "../../constants";
import AgGridTable from "../aggridtable/aggridtable";

const {
  allcat_lst_lst_grid: ALLCAT_LST_LST_GRID,
  allcat_lst_lst_grid_select: ALLCAT_LST_LST_GRID_SELECT,
} = ELEMENT_IDS;

function TableWithFilterTiles<T>({
  selectedFilter,
  tableData,
  paginationData,
  filters,
  sortProps,
  handleChangeFilter,
  renderFilters = false,
  isFetchingData,
  gridApi,
  isOutSideListingViews,
  isExpandedGridView = false,
  onToggleGridView,
}: TableWithFilterTilesProps<T>): JSX.Element {
  const {
    customHiddenRecordText,
    hiddenRecordsSelected,
    onHiddenRecordCheckboxChange,
    selectionState,
  } = paginationData || {};

  const hiddenRecordsSelectionState = {
    customHiddenRecordText,
    hiddenRecordsSelected,
    onHiddenRecordCheckboxChange,
  };

  const { isSelectAll = false, selectedRecords = 0 } = selectionState || {};

  return (
    <TableWithFilterTilesStyled>
      {isOutSideListingViews && gridApi ? (
        <AgGridLocalPaginationWithSelect
          selectionState={{
            isSelectAll,
            selectedRecords,
          }}
          hiddenRecordsState={hiddenRecordsSelectionState}
          onClear={paginationData?.onClear}
          gridApi={gridApi}
          showExpandCollapse
        />
      ) : (
        <AgGridServerSidePaginationWithSelect
          {...paginationData}
          isGridInExpandedView={isExpandedGridView}
          onToggleGridView={onToggleGridView}
        />
      )}
      {!selectedRecords && renderFilters && !isExpandedGridView && (
        <div className="filters">
          {filters.map((item) => (
            <FilterStyled
              className="filter"
              isSelected={selectedFilter === item?.id}
              onClick={(): void => handleChangeFilter?.(item?.id)}
              key={item?.id}
              id={item?.elemId || ""}
            >
              <div>
                {item?.icon && (
                  <div className={`icon ${item?.iconClass || ""}`}>
                    {item?.icon}
                  </div>
                )}
                <div className="title">{item?.title}</div>
                <div className="value" title={item?.value}>
                  {numberFormatter(item?.value)}
                </div>
              </div>
            </FilterStyled>
          ))}
        </div>
      )}
      <div id={isFetchingData ? "" : ALLCAT_LST_LST_GRID}>
        <div id={isFetchingData ? "" : ALLCAT_LST_LST_GRID_SELECT}>
          {isOutSideListingViews ? (
            <AgGridTable
              {...tableData}
              isShowPaginationComponent={false}
              pageSize={50}
            />
          ) : (
            <AgGridWithoutPagination<T>
              tableProps={tableData}
              gridSortProps={sortProps}
            />
          )}
        </div>
      </div>
    </TableWithFilterTilesStyled>
  );
}

export default TableWithFilterTiles;
