import { Tooltip } from "antd";
// <--- Import Statements Start --->
import { yupResolver } from "@hookform/resolvers/yup";

import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";

import { useQueryClient } from "react-query";
// // <--- Import Statements End --->

// <--- Constants Start --->
import { API_CONFIG } from "../../constants/apiconfig";
// <--- Constants End --->

// <--- Components Start --->
import { Button } from "../../components";
import {
  CheckboxField,
  InputField,
  SelectField,
  TextAreaField,
} from "../../components/formfields";

import StateHandler from "../../components/statehandler/statehandler";

import MultiSelectUsersField from "../../components/formfields/multiselectusersfield";
// <--- Components End --->

// <--- Custom Hooks Start --->
import { useCancelModal, useGetAppState } from "../../customhooks";
// <--- Custom Hooks End --->

// <--- Services Start --->
import { useRequestWithMethod } from "../../api";
import { useGetUserAndGroups } from "../../api/tablesservice";

import { useGetGlossaryDomainsForSelect } from "../../api/glossarydomainsservice";
// <--- Services End --->

// <--- Styles Start --->
import { FormItemStyled, FormStyled } from "../../components/form";
import { AddGlossaryCategoryFormStyled } from "./addglossarycategoryform.styles";
// <--- Styles End --->

// <--- Types Start --->
import {
  AddGlossaryCategoryFormProps,
  AddGlossaryCategoryType,
} from "./addglossarycategoryform.types";

import { GlossaryCategoriesGridColumnType } from "../../pages/glossarycategoriespage/glossarycategoriespage.types";
import { NodeType } from "../../app.types";
// <--- Types End --->

// <--- Utils Start --->
import { addGlossaryCategorySchema } from "../../utils/schemas/glossarycategorieschemas";
import { getUniqueUsersForGlossary, getNodeName } from "../../utils";
import { useGetWorkflowTemplates } from "../../api/termservice/termservice";
import { CheckboxWrapperStyled } from "../../components/form/form.styles";
import IconWithText from "../../components/iconwithtext/iconwithtext";
import { infoIcon, workflowIcon } from "../../svgs";
import {
  CAT_GOVERNANCE_NOT_ENABLED,
  DOMAIN_GOVERNANCE_NOT_ENABLED,
  ENABLE_WORKFLOW_TOOLTIP,
} from "../../constants/formconstants";
import { CheckboxTooltipWrapperStyled } from "../addglossarydomainform/addglossarydomainform.styles";
// <--- Utils End --->

const workflow = workflowIcon("14", "14");

const AddGlossaryCategoryForm = (
  props: AddGlossaryCategoryFormProps
): JSX.Element => {
  const { isEditMode } = props;

  const {
    modal: { modalProps = {} },
  } = useGetAppState();
  const {
    id = 0,
    name = "",
    description = "",
    data_owners_obj = [],
    data_stewards_obj = [],
    domain_id = "",
    priority = "",
    data_approvers_obj = [],
    categorySettings,
  } = modalProps as GlossaryCategoriesGridColumnType;

  const { is_gov_enabled: isGovEnabled = false } = categorySettings || {};

  const {
    parsedData: parsedDomains = [],
    isLoading: isLoadingDomains,
    error: errorDomains,
  } = useGetGlossaryDomainsForSelect();

  const domainSelectOptions = useMemo(() => {
    return (
      parsedDomains
        ?.sort((a, b) =>
          a?.label?.localeCompare(b?.label, undefined, { sensitivity: "base" })
        )
        ?.map((domainInfo) => {
          const selectOptionObject = {
            label: (
              <>
                {domainInfo?.label || ""}
                {domainInfo?.isWorkflowEnabled && (
                  <span className="workflow-icon">{workflow}</span>
                )}
              </>
            ),
            labelText: domainInfo?.label || "",
            value: `${domainInfo?.value || 0}`,
          };
          return selectOptionObject || {};
        }) || []
    );
  }, [parsedDomains]);

  const {
    parsedData: parsedUsers,
    isLoading: isLoadingUsersAndGroups,
    error: errorUsersAndGroups,
  } = useGetUserAndGroups(true);

  const {
    parsedData: parsedAdminEditorUsers,
    isLoading: isLoadingUsers,
    error: errorUsers,
  } = useGetUserAndGroups(true, true, true);

  const {
    parsedData: workflowTemplates,
    isLoading: workflowTemplateLoading,
    error: errorWorkflowTemplate,
  } = useGetWorkflowTemplates();

  const onCancel = useCancelModal();
  const queryClient = useQueryClient();

  const dataOwners = useMemo(() => {
    return data_owners_obj?.map((user) =>
      getNodeName(user?.name, user?.email, user?.type as NodeType)
    );
  }, [data_owners_obj]);

  const dataStewards = useMemo(() => {
    return data_stewards_obj?.map((user) =>
      getNodeName(user?.name, user?.email, user?.type as NodeType)
    );
  }, [data_stewards_obj]);

  const dataApprovers = useMemo(() => {
    return (
      data_approvers_obj?.map((user) =>
        getNodeName(user?.name, user?.email, user?.type as NodeType)
      ) || []
    );
  }, [data_stewards_obj]);

  const workflowOptions = useMemo(() => {
    return workflowTemplates?.length
      ? [
          {
            label: "Select Workflow",
            value: "",
            options: workflowTemplates,
          },
        ]
      : [];
  }, [workflowTemplates]);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
    setError,
    watch,
  } = useForm<AddGlossaryCategoryType>({
    defaultValues: {
      name,
      description,
      data_owners: isEditMode || !!dataOwners.length ? dataOwners : undefined,
      data_stewards:
        isEditMode || !!dataStewards.length ? dataStewards : undefined,
      domain: isEditMode ? `${domain_id}` : undefined,
      priority:
        isEditMode && priority !== ""
          ? priority?.slice(0, 3)?.toUpperCase()
          : "NML",
      approvers: isEditMode || !!dataApprovers?.length ? dataApprovers : [],
      wt_id: isEditMode ? modalProps?.workflow_id || undefined : undefined,
      enable_workflow: isEditMode ? modalProps?.is_workflow_enabled : undefined,
    },
    resolver: yupResolver(addGlossaryCategorySchema),
    mode: "onChange",
  });

  const { domain, enable_workflow: workflowEnabled } = watch();

  const selectedDomainInfo = useMemo(
    () => parsedDomains?.find((item) => item?.value === domain),
    [parsedDomains, domain]
  );

  const excludeApprovers = watch("data_stewards");
  const excludeApproversIdsList = selectedDomainInfo?.data_stewards_list?.map(
    (item) => `${item?.id}`
  );

  const excludeDataStewards = watch("approvers");

  const onDomainNameChange = useCallback(
    (domainId) => {
      const selectedDomain = parsedDomains?.find(
        (item) => item?.value === domainId
      );

      if (selectedDomain?.isWorkflowEnabled) {
        const approversList =
          selectedDomain?.data_approvers_obj?.map((user) =>
            getNodeName(user?.name, user?.email, user?.type as NodeType)
          ) || [];
        setValue("enable_workflow", !!selectedDomain?.isWorkflowEnabled);
        setValue("wt_id", selectedDomain?.workflow_id);
        setValue("approvers", approversList);
      } else {
        setValue("enable_workflow", false);
        setValue("wt_id", null);
        setValue("approvers", []);
      }
    },
    [parsedDomains]
  );

  // APIs Integration
  const onSuccess = useCallback(() => {
    const api = API_CONFIG?.get_all_glossary_categories?.url;
    queryClient.invalidateQueries(api, { fetching: false });
    onCancel();
  }, [modalProps]);

  const { isLoading, error, onExecuteRequest } = useRequestWithMethod(
    isEditMode ? "update_glossary_category" : "add_glossary_category",
    isEditMode ? [id] : undefined,
    true,
    onSuccess
  );

  // Callbacks
  const onSubmit = useCallback(
    (values) => {
      const dataOwners = getUniqueUsersForGlossary(
        parsedUsers,
        values?.data_owners,
        "USER_OWNER",
        "CAT"
      );

      const dataStewards = getUniqueUsersForGlossary(
        parsedAdminEditorUsers,
        values?.data_stewards,
        "USER_STEWARD",
        "CAT"
      );

      const dataApprovers = getUniqueUsersForGlossary(
        parsedUsers,
        values?.approvers,
        "USER_APPROVER",
        "CAT"
      );
      const priority = values?.priority?.toUpperCase() || "";

      onExecuteRequest({
        name: String(values?.name)?.trim() || "",
        description: values?.description || "",
        priority: priority === "NML" ? "" : priority,
        domainId: Number.parseInt(values?.domain) || 0,
        ownerData: dataOwners || [],
        stewardData: dataStewards || [],
        approverData: values?.enable_workflow ? dataApprovers || [] : undefined,
        wt_id: values?.enable_workflow === true ? values?.wt_id : undefined,
        termsId: [],
      });
    },
    [parsedUsers, parsedAdminEditorUsers]
  );

  const isWorkflowDisabled = useMemo(
    () =>
      (isEditMode && !isGovEnabled) ||
      (!isEditMode && !selectedDomainInfo?.isGovEnabled) ||
      selectedDomainInfo?.isWorkflowEnabled,
    [isGovEnabled, isEditMode, selectedDomainInfo]
  );

  const govNotEnabledOnSubdomain = useMemo(() => !isGovEnabled && isEditMode, [
    isGovEnabled,
    isEditMode,
  ]);
  const govNotEnabledOnDomain = useMemo(
    () => !selectedDomainInfo?.isGovEnabled && !isEditMode,
    [selectedDomainInfo, isEditMode]
  );

  return (
    <StateHandler
      error={
        errorDomains ||
        errorUsers ||
        errorUsersAndGroups ||
        error ||
        errorWorkflowTemplate
      }
      isFetching={
        isLoadingDomains ||
        isLoadingUsers ||
        isLoadingUsersAndGroups ||
        isLoading ||
        workflowTemplateLoading
      }
      isModal
    >
      <AddGlossaryCategoryFormStyled>
        <FormStyled
          paddingLeft="334px"
          onFinish={handleSubmit(onSubmit) as any}
          isItemColumnLayout
        >
          <div className="scroll-sec">
            <FormItemStyled label="Data Domain" required>
              <SelectField
                name="domain"
                control={control}
                setValue={setValue}
                setError={setError}
                options={domainSelectOptions || []}
                isAllowClear={false}
                placeholder="Select"
                showSearch
                propOnChange={onDomainNameChange}
                filterOption={(input: string, option: any): boolean =>
                  option?.labelText
                    ?.toString()
                    .toLowerCase()
                    .includes(input?.toString().toLowerCase())
                }
              />
            </FormItemStyled>

            <FormItemStyled label="Name" required>
              <InputField control={control} name="name" />
            </FormItemStyled>

            <FormItemStyled label="Description" required>
              <TextAreaField control={control} name="description" />
            </FormItemStyled>

            <FormItemStyled label="Priority" required>
              <SelectField
                name="priority"
                control={control}
                setValue={setValue}
                setError={setError}
                options={[
                  { label: "Normal", value: "NML" },
                  { label: "High", value: "HIG" },
                  { label: "Low", value: "LOW" },
                ]}
                placeholder="Select"
                isAllowClear={false}
              />
            </FormItemStyled>

            <FormItemStyled label="Data Owner">
              <MultiSelectUsersField
                name="data_owners"
                control={control}
                setValue={setValue}
                setError={setError}
                width="512px"
                height="34px"
                maxHeight="165px"
                placeholder={
                  isEditMode
                    ? "Select one or more users"
                    : "Will inherit from Domain by default. Select one or more users to change"
                }
                allowClear
              />
            </FormItemStyled>

            <FormItemStyled label="Data Steward">
              <MultiSelectUsersField
                placeholder={
                  isEditMode
                    ? "Select one or more users"
                    : "Will inherit from Domain by default. Select one or more users to change"
                }
                name="data_stewards"
                control={control}
                setValue={setValue}
                setError={setError}
                width="512px"
                height="34px"
                maxHeight="165px"
                allowClear
                onlyUsers
                onlyUsersWithAdminAndEditorRole
                excludeUsers={excludeDataStewards}
              />
            </FormItemStyled>
            {selectedDomainInfo?.isWorkflowEnabled && (
              <>
                <div className="note">
                  <IconWithText
                    icon={infoIcon}
                    text="Inherited from Domain"
                    textFlexDirection="column"
                  />
                  <div className="selected-domain-info" />
                </div>
              </>
            )}

            <CheckboxTooltipWrapperStyled>
              <CheckboxWrapperStyled className="extended-checkbox">
                <Tooltip
                  title={
                    govNotEnabledOnSubdomain
                      ? CAT_GOVERNANCE_NOT_ENABLED
                      : govNotEnabledOnDomain
                      ? DOMAIN_GOVERNANCE_NOT_ENABLED
                      : undefined
                  }
                  placement="rightTop"
                  overlayClassName="domain-checkbox-tooltip"
                  getPopupContainer={(trigger): HTMLElement =>
                    trigger.parentNode as HTMLElement
                  }
                >
                  <CheckboxField
                    name="enable_workflow"
                    control={control}
                    disabled={isWorkflowDisabled}
                    mode="primary"
                  >
                    Enable Metadata Workflow
                  </CheckboxField>
                </Tooltip>
                <div className="checkbox-desc">
                  Require approval before publishing updates.
                </div>
              </CheckboxWrapperStyled>
            </CheckboxTooltipWrapperStyled>

            {domain && workflowEnabled && (
              <>
                <FormItemStyled label="Workflow" required>
                  <SelectField
                    name="wt_id"
                    control={control}
                    setValue={setValue}
                    setError={setError}
                    options={workflowOptions}
                    isAllowClear={false}
                    placeholder="Select"
                    showSearch
                    filterOption={(input: string, option: any): boolean =>
                      option?.label
                        ?.toString()
                        .toLowerCase()
                        .includes(input?.toString().toLowerCase())
                    }
                    disabled={isWorkflowDisabled}
                  />
                </FormItemStyled>
                <FormItemStyled label="Approvers" required>
                  <MultiSelectUsersField
                    placeholder="Select one or more users"
                    name="approvers"
                    control={control}
                    setValue={setValue}
                    setError={setError}
                    width="333px"
                    height="34px"
                    maxHeight="165px"
                    allowClear
                    disabled={isWorkflowDisabled}
                    onlyUsers
                    excludeUsers={excludeApprovers}
                    excludeIdsList={excludeApproversIdsList}
                  />
                </FormItemStyled>
              </>
            )}
          </div>

          <FormItemStyled
            label=""
            className="form-actions-sec"
            marginBottom="0px"
          >
            <Button id="cancel" width="74px" height="39px" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id="primary"
              width="74px"
              height="39px"
              marginLeft="8px"
              htmlType="submit"
              disabled={!isValid}
            >
              Save
            </Button>
          </FormItemStyled>
        </FormStyled>
      </AddGlossaryCategoryFormStyled>
    </StateHandler>
  );
};

export default AddGlossaryCategoryForm;
