import { useCallback } from "react";
import AgGridTable from "../../../../../../components/aggridtable";
import {
  excelSourceFileGridViewColumns,
  excelSourceFileGridViewRendrers,
  ExcelSourceFilesActionRendrer,
  UpdatedOnRendrers,
  FileNameRendrer,
  SizeRendrer,
  UpdatedByRendrer,
} from "./excelsourcefilesgridview.rendrer";
import { ExcelSourceFilesGridViewStyled } from "./excelsourcefilesgridview.styles";
import {
  ExcelSourceFilesGridViewProps,
  ExcelSourceFilesGridViewType,
} from "./excelsourcefilesgridview.types";

import { useGetAppState } from "../../../../../../customhooks";
import ConditionalDisplay from "../../../../../../components/conditionaldisplay";

function ExcelSourceFilesGridView(
  props: ExcelSourceFilesGridViewProps
): JSX.Element {
  const { onGridReady, rowData } = props;
  const {
    expandableGridProps: { isGridExpanded = false } = { isGridExpanded: false },
  } = useGetAppState();

  const getRowId = useCallback((params: any) => {
    return params?.id;
  }, []);

  return (
    <ExcelSourceFilesGridViewStyled isGridExpanded={isGridExpanded}>
      <ConditionalDisplay condition={isGridExpanded}>
        <div className="grid-heading">Files</div>
      </ConditionalDisplay>
      <AgGridTable<ExcelSourceFilesGridViewType[number]>
        tableColumns={excelSourceFileGridViewColumns}
        rowData={rowData}
        rowHeight={43}
        headerHeight={43}
        maxHeight={
          isGridExpanded ? "calc(100vh - 285px)" : "calc(100vh - 265px)"
        }
        frameworkComponents={{
          ExcelSourceFilesActionRendrer,
          UpdatedOnRendrers,
          FileNameRendrer,
          SizeRendrer,
          UpdatedByRendrer,
        }}
        cellRenderers={excelSourceFileGridViewRendrers}
        theme="ag-theme-alpine table-container"
        defaultColDef={{
          resizable: false,
          lockPosition: true,
        }}
        onGridReady={onGridReady}
        getRowNodeId={getRowId}
        animateRows
        showExpandCollapse
      />
    </ExcelSourceFilesGridViewStyled>
  );
}

export default ExcelSourceFilesGridView;
