import { Tooltip } from "antd";
import { useMemo, useCallback, useRef, useState, useEffect } from "react";

import { DownOutlined } from "@ant-design/icons";
import { useController } from "react-hook-form";

import { chevronDown1, crossIcon } from "../../../svgs";

import {
  SelectContainerStyled,
  SelectFieldStyled,
  TreeSelectFieldStyled,
} from "./selectfield.styles";
import {
  SelectFieldOption,
  SelectFieldProps,
  SelectFieldType,
  TreeSelectFieldOption,
  TreeSelectFieldType,
} from "./selectfield.types";

import { DVSUM_TOOLTIP_CLASS_NAME } from "../../../constants";
import DropdownOptionWithHoverIcon from "../../dropdownoptionwithhovericon";

function SelectField<T = SelectFieldType>({
  control,
  name,
  options = [],
  setValue,
  setError,
  height = "34px",
  maxHeight,
  isMessagePositionAbsolute = false,
  isAllowClear = true,
  allowClearLabel = "Select",
  propOnChange,
  className = "",
  isUseGlobalStyle = false,
  isGroupedOpt = false,
  shouldAddDescInLabel = false,
  tooltipPlacement = "left",
  tooltipClassName = DVSUM_TOOLTIP_CLASS_NAME,
  width,
  isTreeSelect = false,
  notFoundContent,
  treeDefaultExpandedKeys = [""],
  id = "",
  propOnClear,
  ...props
}: SelectFieldProps<T>): JSX.Element {
  const { allowClear, onClear, mode } = props;

  const isMultipleMode = mode === "multiple";

  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({ name, control });

  const [searchTextForSelect, setSearchTextForSelect] = useState("");

  const selectRef: any = useRef(ref);

  const handleChange = useCallback(
    (option, selectOption) => {
      //Commneted code to test the clear functionality accross the app.
      // if (
      //   option === "select" ||
      //   (allowClear && option === undefined && !onClear)
      // ) {
      //   setValue(name, undefined, { shouldValidate: true, shouldDirty: true });
      //   setError && setError(name, { message: REQUIRED_MESSAGE });
      //   propOnChange && propOnChange(option, selectOption);
      // } else {
      inputProps?.onChange(option);
      propOnChange && propOnChange(option, selectOption);
      // }
    },
    [name, inputProps, allowClear, isMultipleMode, propOnChange]
  );

  const updatedOptions = useMemo(() => {
    if (isGroupedOpt) {
      const filteredOpts = (isTreeSelect
        ? (options as TreeSelectFieldOption[])
        : (options as SelectFieldOption[])
      )?.map((opt) => ({
        ...opt,
        options: (isTreeSelect
          ? (opt as TreeSelectFieldOption)?.children
          : (opt as SelectFieldOption)?.options
        )?.filter((item: SelectFieldType["options"][number]) =>
          (item?.labelText || "")
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchTextForSelect?.toString()?.toLowerCase())
        ),
      }));

      const filteredOptsWhereOptsExists = filteredOpts?.filter(
        (opt) => opt?.options?.length
      );

      const optionsWithLabelDesc = shouldAddDescInLabel
        ? (isTreeSelect
            ? (filteredOpts as TreeSelectFieldOption[])
            : (filteredOptsWhereOptsExists as SelectFieldOption[])
          )?.map((opt) => ({
            ...opt,
            label: (
              <Tooltip
                title={opt?.labelDesc || undefined}
                overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                placement={tooltipPlacement}
              >
                {opt?.icon ? (
                  <DropdownOptionWithHoverIcon
                    icon={opt?.icon}
                    onClickIcon={opt?.iconOnClick}
                    label={opt?.label || ""}
                  />
                ) : (
                  opt?.label
                )}
              </Tooltip>
            ),
            [isTreeSelect ? "children" : "options"]: (isTreeSelect
              ? (opt as TreeSelectFieldOption)?.children
              : (opt as SelectFieldOption)?.options
            )?.map((nestedOpts) => ({
              ...nestedOpts,
              label: (
                <Tooltip
                  title={nestedOpts?.labelDesc || undefined}
                  overlayClassName={DVSUM_TOOLTIP_CLASS_NAME}
                  placement={tooltipPlacement}
                >
                  {nestedOpts?.icon ? (
                    <DropdownOptionWithHoverIcon
                      icon={nestedOpts?.icon}
                      onClickIcon={nestedOpts?.iconOnClick}
                      label={nestedOpts?.label || ""}
                    />
                  ) : (
                    nestedOpts?.label
                  )}
                </Tooltip>
              ),
            })),
          }))
        : filteredOptsWhereOptsExists;

      return optionsWithLabelDesc?.[0]?.label
        ? optionsWithLabelDesc
        : optionsWithLabelDesc?.[0]?.options;
    }

    //Removed the select as an option from the dropdown
    const finalOptions: SelectFieldOption[] = options;
    const optionsWithLabelDesc = shouldAddDescInLabel
      ? finalOptions?.map((opt) => ({
          ...opt,
          label: (
            <Tooltip
              title={opt?.labelDesc || undefined}
              overlayClassName={tooltipClassName}
              // we can make it configurable through props
              placement={tooltipPlacement}
            >
              {opt?.label}
            </Tooltip>
          ),
        }))
      : finalOptions;

    return optionsWithLabelDesc;
  }, [
    options,
    isAllowClear,
    allowClearLabel,
    searchTextForSelect,
    shouldAddDescInLabel,
    isTreeSelect,
  ]);

  const onChange = useCallback(
    (input) => {
      setSearchTextForSelect(input || "");
    },
    [searchTextForSelect]
  );

  const onBlur = useCallback(() => {
    setSearchTextForSelect("");
  }, [searchTextForSelect]);

  //To clear the field value
  const onClearFieldValue = useCallback(() => {
    propOnClear
      ? propOnClear()
      : setValue(name, undefined, { shouldValidate: true, shouldDirty: true });
  }, [searchTextForSelect]);

  const commonProps = {
    notFoundContent: (
      <div className="no-result-fount-content">
        {notFoundContent || "No results match"}
      </div>
    ),
    virtual: true,
    onChange: handleChange,
    className: `dvsum-select-field ${className}`,
    dropdownClassName: "multiselect-dropdown-dvsum",
    ref: selectRef,
    onSelect: (): void => mode !== "multiple" && selectRef?.current.blur(),
    value: inputProps?.value === "" ? undefined : inputProps?.value,
    ...(!props?.loading && {
      suffixIcon: chevronDown1("11.7px", "6.9px"),
    }),
    ...(isGroupedOpt && {
      onSearch: onChange,
      onBlur,
      onFocus: onBlur,
    }),

    // Using default values for the below props if any issue faced then we will control it through props
    allowClear: true,
    onClear: onClearFieldValue,
    clearIcon: crossIcon("12", "12"),
  };

  return (
    <SelectContainerStyled
      isMessagePositionAbsolute={isMessagePositionAbsolute}
      width={width}
      className="select-field-container"
      id={id}
    >
      {isTreeSelect ? (
        <TreeSelectFieldStyled
          style={{ width }}
          switcherIcon={<DownOutlined />}
          treeData={(updatedOptions as TreeSelectFieldOption[])?.map((opt) => ({
            ...opt,
            key: opt?.key,
            label: opt?.label || "",
            value: opt?.value || "",
            title: opt?.title || "",
            disabled: !!opt?.children,
            children: opt?.children?.map((child) => ({
              ...child,
              label: <div className="inner-child">{child?.label || ""}</div>,
              value: child?.value || "",
              title: child?.title || "",
            })),
          }))}
          multiple={mode === "multiple"}
          {...(props as TreeSelectFieldType)}
          {...inputProps}
          {...commonProps}
          getPopupContainer={
            !isUseGlobalStyle
              ? (trigger): HTMLElement => trigger.parentNode as HTMLElement
              : undefined
          }
          treeDefaultExpandedKeys={treeDefaultExpandedKeys}
          // style={{ width }}
          // switcherIcon={<DownOutlined />}
          // notFoundContent={
          //   <div className="no-result-fount-content">No results match </div>
          // }
          // virtual
          // {...(props as TreeSelectFieldType)}
          // {...inputProps}
          // onChange={handleChange}
          // treeData={(updatedOptionsTreeSelect as TreeSelectFieldOption[])?.map(
          //   (opt) => ({
          //     label: opt?.label || "",
          //     value: opt?.value || "",
          //     title: opt?.title || "",
          //     disabled: !!opt?.children,
          //     children: opt?.children?.map((child) => ({
          //       label: <div className="inner-child">{child?.label || ""}</div>,
          //       value: child?.value || "",
          //       title: child?.title || "",
          //     })),
          //   })
          // )}
          // className={`dvsum-select-field ${className}`}

          // dropdownClassName="multiselect-dropdown-dvsum"
          // ref={selectRef}
          // onSelect={(): void =>
          //   mode !== "multiple" && selectRef?.current.blur()
          // }
          // multiple={mode === "multiple"}
          // // height={height}
          // // maxHeight={maxHeight}
          // {...(!props?.loading && {
          //   suffixIcon: chevronDown1("11.7px", "6.9px"),
          // })}
          // value={inputProps?.value === "" ? undefined : inputProps?.value}
          // {...(isGroupedOpt && {
          //   onSearch: onChange,
          //   onBlur,
          //   onFocus: onBlur,
          // })}
        />
      ) : (
        <SelectFieldStyled
          multipleMode={mode === "multiple"}
          height={height}
          maxHeight={maxHeight}
          {...(props as SelectFieldType)}
          {...inputProps}
          {...commonProps}
          options={updatedOptions}
          getPopupContainer={
            !isUseGlobalStyle
              ? (trigger): HTMLElement => trigger.parentNode as HTMLElement
              : undefined
          }
          style={{ width }}

          // notFoundContent={
          //   <div className="no-result-fount-content">No results match </div>
          // }
          // virtual
          // {...(props as SelectFieldType)}
          // {...inputProps}
          // onChange={handleChange}
          // options={updatedOptions}
          // className={`dvsum-select-field ${className}`}
          // getPopupContainer={
          //   !isUseGlobalStyle
          //     ? (trigger): HTMLElement => trigger.parentNode as HTMLElement
          //     : undefined
          // }
          // dropdownClassName="multiselect-dropdown-dvsum"
          // ref={selectRef}
          // onSelect={(): void =>
          //   mode !== "multiple" && selectRef?.current.blur()
          // }
          // multipleMode={mode === "multiple"}
          // height={height}
          // maxHeight={maxHeight}
          // {...(!props?.loading && {
          //   suffixIcon: chevronDown1("11.7px", "6.9px"),
          // })}
          // value={inputProps?.value === "" ? undefined : inputProps?.value}
          // {...(isGroupedOpt && {
          //   onSearch: onChange,
          //   onBlur,
          //   onFocus: onBlur,
          // })}
        />
      )}
      <div className="error">{error?.message}</div>
    </SelectContainerStyled>
  );
}

export default SelectField;
