import styled from "styled-components";

export const ETLCodeRefDrawerStyled = styled.div``;

export const ETLCodeRefDrawerHeaderStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 3px;
  flex-direction: column;
  border-bottom: 1px solid
    ${({ theme }): string => theme.borderColors.LIGHT_GREY_51};
  padding: 20px;

  .source-info {
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: ${({ theme }): string => theme.textColors.GREY_210};
  }

  .text {
    margin-left: 35px;
    color: ${({ theme }): string => theme.textColors.GREY_24};
  }
`;

export const ETLCodeRefDrawerSectionWrapperStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 10px;
  flex-direction: column;

  .etl-metadata-info {
    padding: 15px 20px;
    border-bottom: 1px solid
      ${({ theme }): string => theme.borderColors.LIGHT_GREY_51};
    width: 100%;
  }

  .table-field-name {
    .letter-icon-mapping {
      font-size: 10px;
    }

    .etl-node-icon {
      svg {
        margin-top: 5px;
        width: 15px;
        height: 15px;

        path {
          fill: ${({ theme }): string => theme?.textColors?.BLUE_116};
        }
      }
    }
  }

  .etl-code-info {
    padding: 10px 20px;
    width: 100%;

    .etl-code-section-title {
      color: ${({ theme }): string => theme.textColors.BLUE_116};
      font-family: OpenSansSemiBold;
      margin-bottom: 10px;
    }

    .code-text {
      color: ${({ theme }): string => theme.textColors.WHITE_COLOR};
      font-size: 12px;
    }

    .query-line {
      white-space: unset;
      word-break: break-word;
    }

    .expand-icon {
      svg {
        margin-top: 6px;
      }
    }

    .query-wrapper {
      max-height: calc(100vh - 365px);
      min-height: calc(100vh - 365px);
    }
  }
`;

export const SourceCodeRendererStyled = styled.div`
  width: 100%;
`;

export const RefDrawerHeaderWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: ${({ theme }): string => theme.textColors.BLUE_116};
  font-family: OpenSansSemiBold;
  font-size: 19.2px;
`;

export const SourceOfRelationRendererStyled = styled.div`
  span {
    text-transform: capitalize;
    color: ${({ theme }): string => theme.textColors.GREY_24};
  }
`;
