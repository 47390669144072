import { Edge } from "reactflow";

export function traverseLineageForward(
  data: Edge[],
  sourceIds: string[],
  combineIds: string[],
  fetchAllConnected = true,
  includeSourceId = true,
  visited = new Set<string>() // Track visited nodes to prevent cycles
): string[] {
  // Add current sourceIds to the combined list
  if (includeSourceId) {
    combineIds?.push(...sourceIds);
  }

  // Find the child nodes for the given sourceIds
  const childOfDataIds = data
    ?.filter((item) => sourceIds?.includes(item?.source))
    ?.map((item) => item?.target);

  // Filter out already visited nodes to prevent cycles
  const unvisitedChildren = childOfDataIds?.filter(
    (childId) => !visited?.has(childId)
  );

  // Mark current sourceIds as visited
  sourceIds?.forEach((id) => visited?.add(id));

  try {
    if (unvisitedChildren?.length) {
      // If fetchAllConnected is true, continue recursive traversal
      combineIds?.push(...unvisitedChildren);
      if (fetchAllConnected) {
        return traverseLineageForward(
          data,
          unvisitedChildren,
          combineIds,
          fetchAllConnected,
          includeSourceId,
          visited
        );
      }
      // If fetchAllConnected is false, return only directly connected child nodes
    }

    return [...new Set(combineIds)];
  } catch (error) {
    console.error("Error in traverseLineageForward", error);
    return [];
  }
}
