import styled from "styled-components";

export const LayoutStyled = styled.div<{
  isSideBarCollapsed: boolean;
}>`
  & > .page-content {
    position: absolute;
    height: calc(100vh - 45px);
    left: ${(props): string => (props.isSideBarCollapsed ? "43px" : "190px")};
    top: 45px;

    width: ${(props): string =>
      props.isSideBarCollapsed ? "calc(100vw - 55px)" : "calc(100vw - 202px)"};

    .page-content-layout {
      min-height: 100%;
      min-width: ${(props): string =>
        props.isSideBarCollapsed
          ? "calc(1500px - 65px)"
          : "calc(1500px - 212px)"};
      /* max-width: ${(props): string =>
        props.isSideBarCollapsed
          ? "calc(1920px - 55px)"
          : "calc(1920px - 202px)"}; */
    }
  }
`;

export const PageContentLayoutStyled = styled.div`
  position: relative;
  &.page-content-layout {
    padding: 22px 30px 12px 20px;
  }

  .add-anlysis-icon {
    svg {
      margin-right: 7px;
    }
  }
`;
