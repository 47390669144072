import { useCallback } from "react";
import {
  RemoveScheduleModalFooterStyled,
  RemoveScheduleModalStyled,
} from "./removeschedulemodal.styles";

import Button from "../../../button";
import { useCancelModal, useGetAppState } from "../../../../customhooks";

import { useRequestWithMethod } from "../../../../api";

import {
  getPageNode,
  isJsonString,
  jsonParse,
  openNotification,
} from "../../../../utils";
import SuccessNotificationMessage from "../../../successnotificationmessagerendrer/successnotificationmessagerendrer";

import StateHandler from "../../../statehandler/statehandler";
import { RemoveScheduleModalProps } from "./removeschedulemodal.types";
import { getPageNodeName } from "../../../../pages/listingpage/listingpage.utils";

const RemoveScheduleModal = (): JSX.Element => {
  const onCancel = useCancelModal();

  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    allSelectedNodesIds,
    handleClearAll,
    viewId: searchId,
    countFilter,
    filterModel,
    isSelectAll,
    nodeType = "RLS",
    populateData,
  } = modalProps as RemoveScheduleModalProps;

  const { isTablePage } = getPageNode(nodeType);

  const nodeName = getPageNodeName(nodeType);

  const onUpdateJobSchedulerSuccess = useCallback(
    (response) => {
      const jobSchedulerResp = isTablePage
        ? jsonParse(response?.data?.FN_UPDATE_GOV_TBLS_DATA)
        : jsonParse(response?.data?.FN_UPDATE_GOV_RLSS_DATA);

      openNotification(
        <SuccessNotificationMessage
          message={`${jobSchedulerResp?.total_cnt} ${nodeName}(s) has been removed from schedule(s).`}
        />
      );

      onCancel();
      handleClearAll?.();
      populateData?.({});
    },
    [nodeName, handleClearAll, populateData]
  );

  const {
    onExecuteRequest: onRemoveJobScheduler,
    isLoading: onRemovingJobScheduler,
    error: errorInRemovingJobScheduler,
  } = useRequestWithMethod(
    "update_field_data",
    [nodeType, searchId],
    false,
    onUpdateJobSchedulerSuccess
  );

  const onConfirmClick = useCallback(() => {
    const updatedScheduler = [
      {
        job_def_ids: [],
        job_action: "DEL",
        isSelectAll,
        [isTablePage ? "tbl_ids" : "rule_ids"]: allSelectedNodesIds,
        filterModel: filterModel || {},
        countFilter: countFilter || "all",
      },
    ];

    onRemoveJobScheduler(updatedScheduler);
  }, [nodeType, allSelectedNodesIds, countFilter, filterModel, isSelectAll]);

  return (
    <StateHandler
      isFetching={onRemovingJobScheduler}
      error={errorInRemovingJobScheduler}
      isModal
    >
      <RemoveScheduleModalStyled>
        <div className="label">
          If these {nodeName}(s) were part of an automated schedule, they will
          be remove from it which means {nodeName}(s) will not{" "}
          {nodeType === "RLS" ? "run" : "profile"} automatically anymore. The
          schedule itself will not be deleted.
          <br />
          Do you want to remove?
        </div>

        <RemoveScheduleModalFooterStyled className="form-actions-sec">
          <Button id="cancel" width="74px" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            id="primary"
            width="74px"
            marginLeft="8px"
            htmlType="submit"
            onClick={onConfirmClick}
          >
            Remove
          </Button>
        </RemoveScheduleModalFooterStyled>
      </RemoveScheduleModalStyled>
    </StateHandler>
  );
};

export default RemoveScheduleModal;
