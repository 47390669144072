import styled from "styled-components";
import {
  SelectStyled,
  TreeSelectStyled,
} from "../../selectdropdowns/selectdropdown/selectdropdown.styles";

export const SelectContainerStyled = styled.div<{
  isMessagePositionAbsolute?: boolean;
  width?: string;
}>`
  width: ${({ width }): string => width || "100%"};

  .icon {
    margin: 0 0.4rem;
    font-size: 12px;
    font-weight: normal;
    color: #c3c3c3;
  }
  .error {
    display: flex;
    justify-content: flex-end;
    color: red;

    ${(props): any =>
      props.isMessagePositionAbsolute &&
      `
    position:absolute;
    right:0;
    `}
  }
`;

export const SelectFieldStyled = styled(SelectStyled)``;
export const TreeSelectFieldStyled = styled(TreeSelectStyled)``;
