import { useMemo } from "react";

import { Section } from "../../components/section";
import { ETL_SECTIONS } from "../../constants/labelconstants/etlconfig";

import {
  ETLRefDrawerHeaderStyled,
  ETLRefDrawerSectionWrapperStyled,
  ETLRefDrawerStyled,
} from "./etlrefdrawer.styles";

import { ETLRefDrawerPropsType } from "./etlrefdrawer.types";
import { etlRefDrawer } from "./etlrefdrawer.renderer";

import { getDataSourceLogoIconByType } from "../../utils";
import { settingsIcon } from "../../svgs";

import { useGetEtlDoc } from "../../api/etlservice/etlservice";
import { useGetAppState } from "../../customhooks";

import { etlRefBlankSlate } from "../../blankslates/etlrefblankslate";
import StateHandler from "../../components/statehandler";

const titleSecProps = {
  className: "col-ref-sec-titile",
  showBorderBottom: false,
  isShowIcon: false,
};

const etlNodeIcon = settingsIcon;

const ETLRefDrawer = (props: ETLRefDrawerPropsType): JSX.Element => {
  const { drawer } = useGetAppState();

  const { drawerProps = {} } = drawer || {};

  const nodeId = props?.id || drawerProps?.id;

  const { parsedData, isLoading, error } = useGetEtlDoc(nodeId, "PLO", "PUB");

  const etlRefDrawerRendrer = useMemo(() => {
    return etlRefDrawer;
  }, []);

  const sourceIcon = getDataSourceLogoIconByType(parsedData?.sourceTypeId);

  return (
    <StateHandler
      isFetching={isLoading}
      error={error}
      blankSlate={etlRefBlankSlate}
    >
      <ETLRefDrawerStyled>
        <ETLRefDrawerHeaderStyled>
          <div className="source-info">
            <span>{sourceIcon}</span>
            <span>{parsedData?.parentName}</span>
          </div>
          <div className="etl-info">
            <span className="etl-icon">{etlNodeIcon}</span>
            <span className="etl-name">{parsedData?.nodeName}</span>
          </div>
        </ETLRefDrawerHeaderStyled>
        <ETLRefDrawerSectionWrapperStyled>
          <div className="etl-metadata-info">
            <Section
              secTitle={{
                title: ETL_SECTIONS?.metaInfo,
                ...titleSecProps,
              }}
              secRowData={parsedData?.metaInfo?.map((item) => ({
                ...item,
                customJSX: etlRefDrawerRendrer?.[item?.title]?.(item),
              }))}
            />
          </div>
          <div className="etl-exec-details-info">
            <Section
              secTitle={{
                title: ETL_SECTIONS?.executionDetails,
                ...titleSecProps,
              }}
              secRowData={parsedData?.execution?.map((item) => ({
                ...item,
                customJSX: etlRefDrawerRendrer?.[item?.title]?.(item),
              }))}
            />
          </div>
          <div className="etl-managed-by-info">
            <Section
              secTitle={{
                title: ETL_SECTIONS?.managedBy,
                ...titleSecProps,
              }}
              secRowData={parsedData?.managedBy?.map((item) => ({
                ...item,
                customJSX: etlRefDrawerRendrer?.[item?.title]?.(item),
              }))}
            />
          </div>
        </ETLRefDrawerSectionWrapperStyled>
      </ETLRefDrawerStyled>
    </StateHandler>
  );
};

export default ETLRefDrawer;
