import { useCallback } from "react";

import { DeleteRecordsStyled } from "./deleterecords.styles";
import { Button } from "../../../../components";

import { useCancelModal, useGetAppState } from "../../../../customhooks";
import { DeleteRecordsModalProps } from "./deleterecords.types";

import StateHandler from "../../../../components/statehandler/statehandler";
import { useRequestWithMethod } from "../../../../api";

import SuccessNotificationMessage from "../../../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";
import { getPageNode, jsonParse, openNotification } from "../../../../utils";
import { getPageNodeName } from "../../listingpage.utils";

const DeleteRecords = (): JSX.Element => {
  const {
    modal: { modalProps = {} },
  } = useGetAppState();
  const handleCancel = useCancelModal();

  const {
    nodeType = "RLS",
    selectedNodesIds = [],
    countFilter = "",
    filterModel,
    isSelectAll = false,
    searchId = "",
    onDeleteRecords,
  } = modalProps as DeleteRecordsModalProps;

  const { isTablePage, isColumnPage, isJobPage, isRulePage } = getPageNode(
    nodeType
  );

  const nodeName = getPageNodeName(nodeType);

  const onSuccessDeleteRecords = useCallback(
    (response) => {
      const parsedRes = isTablePage
        ? jsonParse(response?.data?.FN_UPDATE_GOV_TBLS_DATA)
        : isColumnPage
        ? jsonParse(response?.data?.FN_UPDATE_GOV_COLS_DATA)
        : isRulePage
        ? jsonParse(response?.data?.FN_UPDATE_GOV_RLSS_DATA)
        : jsonParse(response?.data?.FN_UPDATE_GOV_JOBS_DATA);

      if (parsedRes?.deleted_cnt) {
        openNotification(
          <SuccessNotificationMessage
            message={`${parsedRes?.deleted_cnt} ${nodeName}${
              parsedRes?.deleted_cnt > 1 ? "s have" : " has"
            } been deleted.`}
          />
        );
      } else {
        openNotification(
          `No ${nodeName}(s) has been deleted.`,
          undefined,
          {},
          "info"
        );
      }

      onDeleteRecords?.();
      handleCancel();
    },
    [onDeleteRecords, nodeType]
  );

  const {
    onExecuteRequest: onExecuteRequestMassUpdate,
    isLoading: isLoadingMassUpdate,
    error: errorMassUpdate,
  } = useRequestWithMethod(
    "update_field_data",
    [nodeType, searchId],
    false,
    onSuccessDeleteRecords
  );

  const onSubmit = useCallback(() => {
    const isMassUpdate: boolean =
      isSelectAll || (!isSelectAll && selectedNodesIds?.length > 1);

    const fieldName = isTablePage
      ? "tbl_status"
      : isColumnPage
      ? "col_status"
      : isRulePage
      ? "rule_status"
      : "job_status";

    onExecuteRequestMassUpdate([
      {
        [isTablePage
          ? "tbl_ids"
          : isColumnPage
          ? "col_ids"
          : isRulePage
          ? "rule_ids"
          : "job_def_ids"]: [...(selectedNodesIds || [])],
        is_mass_update: isMassUpdate,
        isSelectAll: isSelectAll || false,
        filterModel: filterModel || {},
        countFilter: countFilter || "all",
        field: fieldName,
        [fieldName]: "DEL",
      },
    ]);
  }, [nodeType, isSelectAll, selectedNodesIds, filterModel, countFilter]);

  return (
    <StateHandler
      isFetching={isLoadingMassUpdate}
      error={errorMassUpdate}
      isModal
    >
      <DeleteRecordsStyled>
        {isRulePage ? (
          <div className="message-content">
            Deleting the selected rule(s) will effect the associated jobs.
            <br /> Are you sure you want to delete?
          </div>
        ) : isJobPage ? (
          <div className="message-content">
            Are you sure you want to delete the selected scheduled job(s)?
          </div>
        ) : (
          <div className="message-content">
            This action will remove the
            {isTablePage ? " table(s)" : " column(s)"} from the catalog which no
            longer exist in the data source. This action cannot be undone. Are
            you sure?
          </div>
        )}

        <div className="modal-actions-sec">
          <Button id="cancel" width="78px" onClick={handleCancel}>
            Cancel
          </Button>

          <Button id="primary" width="78px" onClick={onSubmit}>
            Delete
          </Button>
        </div>
      </DeleteRecordsStyled>
    </StateHandler>
  );
};

export default DeleteRecords;
