import moment from "moment";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  ColumnResizedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridColumnsChangedEvent,
  ICellRendererParams,
  SortChangedEvent,
} from "ag-grid-community";

import AgGridTable from "../../../../../../../../../../../../components/aggridtable";

import { getFilterByDataType } from "../../../../../../../../../../../../parsers/listingpagesparser/listingpagesparser.utils";

import { QueryResultsGridHeaderRendrer } from "./queryresultsgrid.renderer";

import {
  DateRendererStyled,
  NumberRendererStyled,
  QueryResultsContentStyled,
} from "./queryresultsgrid.styles";

import {
  QueryResultsGridProps,
  ResultGridHeaderParams,
} from "./queryresultsgrid.types";

import { CustomPagination } from "../../../../../../../../../../../../components";
import QueryBlocksLoadingSlateWrapper from "../../../../../querblocksloadingstate";
import {
  NO_RESULTS_ON_FILTERING_QUERY_BLOCK_MESSAGE,
  NO_RESULTS_QUERY_BLOCK_ERROR_MESSAGE,
  QUERY_GRID_EXPANDED_CLASSNAME_PREFIX,
} from "../../../../../../../../../../analysisdetalpage.constants";

import { TableColumns } from "../../../../../../../../../../../../components/aggridtable/aggridtable.types";

import {
  calculateElementSize,
  checkDataTypeOfColumn,
  isPercentageMetricColumn,
  isAmountMetricColumn,
  getUniqueList,
  formatPercentage,
  getObjectKeys,
  checkSourceType,
  maskString,
  sortListOnSpecificKeyValue,
} from "../../../../../../../../../../../../utils";

import {
  formatProfilingDate,
  transformFormatOfFilterModelToBEModel,
} from "../../../../../../../../../../analisisdetailpage.utils";

import { checkIsChatPage } from "../../../../../../../../../../../../utils/ischatpage";
import { queryResultsGridHumanReadableFormating } from "../../queryresults.utils";

import { AnalysisPageQueryBoxResultsColumns } from "../../../../../../../../../../analysisdetailpage.types";

import {
  ANL_DEF_PAR_KEYS,
  ANL_DEF_PAR_KEYS_LIST,
  ColumnDataType,
} from "../../../../../../../../../../../../parsers";
import { useGetAppState } from "../../../../../../../../../../../../customhooks";

const FILTER_PARAMS = {
  inRangeInclusive: true,
  filterOptions: [
    "empty",
    "equals",
    "notEqual",
    "lessThan",
    "lessThanOrEqual",
    "greaterThan",
    "greaterThanOrEqual",
    "inRange",
    {
      displayKey: "blanks",
      displayName: "Blanks",
      test(_filterValue: any, cellValue: any): boolean {
        return !cellValue && cellValue !== 0;
      },
      hideFilterInput: true,
    },
    {
      displayKey: "nonBlanks",
      displayName: "Non-Blanks",
      test(_filterValue: any, cellValue: any): boolean {
        return cellValue;
      },
    },
  ],
};

function MetricRenderer(props: ICellRendererParams): JSX.Element {
  const columnName = props?.colDef?.headerName || "";

  const finalVal = props?.value?.value || props?.value;

  if (finalVal === null || finalVal === "null") {
    return <NumberRendererStyled>null</NumberRendererStyled>;
  }

  // const updatedColumnName = columnName?.includes("pivot")
  //   ? props?.colDef?.headerName?.toLocaleLowerCase() || ""
  //   : columnName;

  const {
    col_data_type: colDataType,
    is_data_formatted: isDataFormatted = true,
    prefrences,
    is_qry_contains_perc_fmt: isPercFormatApplied,
  } = (props?.colDef?.headerComponentParams || {}) as ResultGridHeaderParams;

  const isPivotView = props?.context?.is_pivot_view;

  const colDecimalPlaces = isPivotView
    ? props?.context?.prefrences?.[columnName]?.decimals
    : prefrences?.[columnName]?.decimals;

  const updatedIsDataFormatted = isPivotView
    ? props?.context?.is_data_formatted
    : isDataFormatted;

  const formattedString = props?.value?.toLocaleString();
  const number = props?.value?.value || props?.value || 0;

  const strNum = `${number}`;

  if (strNum === "") return <div />;

  const columnsState = props?.columnApi?.getColumnState();
  const currentColumnState = columnsState?.find(
    (columnState) => columnState?.colId === columnName
  );

  const isCountAggrFunc = currentColumnState?.aggFunc === "count";
  const isAvgAggrFunc = currentColumnState?.aggFunc === "avg";

  const updatedColumnDataType = isCountAggrFunc
    ? undefined
    : isAvgAggrFunc
    ? "DEC"
    : colDataType;

  if (!isCountAggrFunc && isPercentageMetricColumn(columnName)) {
    return (
      <NumberRendererStyled title={`${formattedString}%`}>
        {queryResultsGridHumanReadableFormating({
          dataType: updatedColumnDataType,
          number: isPivotView
            ? formatPercentage(number, isPercFormatApplied)
            : number,
          isDataFormatted: updatedIsDataFormatted,
          decimals: colDecimalPlaces,
        })}
        %
      </NumberRendererStyled>
    );
  }

  if (!isCountAggrFunc && isAmountMetricColumn(columnName)) {
    return (
      <NumberRendererStyled title={`$${formattedString}`}>
        $
        {queryResultsGridHumanReadableFormating({
          dataType: updatedColumnDataType,
          number,
          isDataFormatted: updatedIsDataFormatted,
          decimals: colDecimalPlaces,
        })}
      </NumberRendererStyled>
    );
  }

  return (
    <NumberRendererStyled title={`${formattedString} `}>
      {queryResultsGridHumanReadableFormating({
        dataType: updatedColumnDataType,
        number,
        isDataFormatted: updatedIsDataFormatted,
        decimals: colDecimalPlaces,
      })}
    </NumberRendererStyled>
  );
}

function DateRenderer(props: ICellRendererParams): JSX.Element {
  const date = formatProfilingDate(props?.value);
  return <DateRendererStyled title={date}>{date}</DateRendererStyled>;
}

function NumberRenderer(props: ICellRendererParams): JSX.Element {
  const { value = "" } = props;

  const finalVal = props?.value?.value || props?.value;

  if (finalVal === null || finalVal === "null") {
    return <NumberRendererStyled>null</NumberRendererStyled>;
  }

  return (
    <NumberRendererStyled>
      {queryResultsGridHumanReadableFormating({
        dataType: "NUM",
        number: value,
        isDataFormatted: false,
      })?.replace(/,/g, "")}
    </NumberRendererStyled>
  );
}

const GridSection = (props: QueryResultsGridProps): JSX.Element => {
  const gridApi = useRef<GridApi>();
  const isGridLoaded = useRef<any>();

  const numberOfCountColumnChangedCalled = useRef<number>(0);

  const {
    expandableGridProps: { isGridExpanded } = { isGridExpanded: false },
  } = useGetAppState();

  const isChatPage = checkIsChatPage();

  const {
    queryItem,
    handlers,
    onChangeRenderedGridRows,
    defaultSortModel,
    analysisSrcTypeId,
    isCopilotSortedColumns,
  } = props;

  const {
    results,
    id = "",
    filters,
    type,
    sub_type: subType,
    query,
    header,
    settings,
    is_loading: isLoading,
  } = queryItem || {};

  const {
    is_data_formatted: isDataFormatted = true,
    date_format: dateFormat,
    prefrences,
    metric_format: metricFormat,
  } = settings || {};

  const {
    result_type: resultType,
    is_maximize_query_block: isMaximizeQueryBlock = false,
  } = header || {};

  const isPivotView = resultType === "pivot";

  const {
    transiant_filters: transiantFilters = {},
    applied_filters: appliedFilters = {},
    sortModel = [],
  } = filters || {};

  const {
    columns = [],
    data: gridData = [],
    selected_cell: selectedCell = { index: 0, value: "", col_id: "" },
    is_server_side_filteration: isServerSideFilteration,
    pagination,
    grid_record_count: gridRecordCount,
    col_preprocess_info: colPreprocessInfo,
    pivot_state: pivotState = [],
    last_refreshed: lastRefreshed,
    record_count: recordCount,
  } = results || {};

  const { sql_query: sqlQuery = "" } = query || {};

  const { index = 0, value = "", col_id: colId = "" } = selectedCell;

  const {
    onChangeQueryBlockGridPagination,
    onGetQueryGridResults,
    onChangeQueryColumnSorting,
    updateQueryPivotState,
    onChangeQuerySettings,
  } = handlers;

  const isNoRecords = useMemo(
    () =>
      type === "CCQ" &&
      subType !== "GME" &&
      subType !== "TRM" &&
      !!lastRefreshed &&
      !recordCount,
    [type, subType, recordCount, lastRefreshed, isLoading]
  );

  const computeColumnDefs = useMemo(() => {
    const createCommonParams = (
      item: AnalysisPageQueryBoxResultsColumns
    ): any => {
      const minWidth =
        calculateElementSize(item?.column_display_name || "", {
          fontSize: "14px",
        })?.clientWidth ?? 0;

      const minWidthWithBuffer = minWidth + 70;

      const { isNumberCol, isDateColumn } = checkDataTypeOfColumn(
        item?.data_type
      );

      const hasToAddRenderer =
        (isDateColumn || item?.col_type_id === "MET" || isNumberCol) &&
        !item?.is_col_masked;

      return {
        headerName: item?.column_display_name || "",
        field: item?.column_name || "",
        suppressSizeToFit: true,
        minWidth: minWidthWithBuffer > 170 ? minWidthWithBuffer : 170,

        flex: 1,
        ...(hasToAddRenderer && {
          cellRenderer: isDateColumn
            ? "DateRenderer"
            : item?.col_type_id === "MET"
            ? "MetricRenderer"
            : isNumberCol
            ? "NumberRenderer"
            : "",
        }),
        headerComponentParams: {
          col_data_type: item?.data_type,
        },

        ...(isNumberCol && {
          valueGetter: (e: any): any => {
            const value = e?.data?.[item?.column_name] ?? "";
            return value === "" ? value : parseFloat(value);
          },
        }),

        ...(item?.is_col_masked && {
          valueGetter: (event: any): any => {
            return maskString(event?.data?.[item?.column_name]);
          },
        }),
      };
    };

    const isPivotEnabled = (
      item: AnalysisPageQueryBoxResultsColumns
    ): boolean => {
      let enablePivot = false;

      if (item?.col_type_id !== "MET") {
        const currentColData = gridData?.map((gridDataItem) => {
          return gridDataItem?.[item?.column_name];
        });

        const uniqueDataItems = getUniqueList(currentColData);
        enablePivot = uniqueDataItems?.length <= 52;
      }

      return enablePivot;
    };

    if (pivotState?.length) {
      return pivotState?.map((pivotItem) => {
        const item = columns?.filter(
          (columnItem) => columnItem?.column_name === pivotItem?.colId
        )?.[0];

        return {
          ...createCommonParams(item),
          rowGroup: item?.col_type_id !== "MET",
          enableRowGroup: item?.col_type_id !== "MET",
          enablePivot: isPivotEnabled(item),
          enableValue: item?.col_type_id === "MET",
          aggFunc: item?.col_type_id === "MET" ? "sum" : undefined,
          ...pivotItem,
        };
      });
    }

    let acc = 0;

    return columns?.map((item) => {
      if (item?.col_type_id !== "MET") {
        acc += 1;
      }

      return {
        ...createCommonParams(item),
        rowGroup: acc < 4 && item?.col_type_id !== "MET",
        enableRowGroup: item?.col_type_id !== "MET",
        enablePivot: isPivotEnabled(item),
        enableValue: item?.col_type_id === "MET",
        aggFunc: item?.col_type_id === "MET" ? "sum" : undefined,
      };
    });
  }, [gridData, pivotState, columns]);

  const [updatedColumnsForPivotView] = useState<TableColumns<any>>(
    computeColumnDefs
  );

  const onGridReady = useCallback(
    (params: { api: GridApi }) => {
      const applyTransc = (data: any): void => {
        params?.api?.applyTransactionAsync({ add: data }, () => {
          if (!isServerSideFilteration && !isPivotView) {
            params?.api?.paginationGoToPage(pagination?.current_page - 1);
          }
        });
      };

      applyTransc(gridData);

      if (!isServerSideFilteration && !isPivotView) {
        params?.api?.setSortModel(defaultSortModel || sortModel);
        params?.api?.paginationSetPageSize(pagination?.page_size);
      }

      gridApi.current = params?.api;
    },
    [
      gridApi,
      gridData,
      pagination,
      isServerSideFilteration,
      sortModel,
      sqlQuery,
      columns,
      isChatPage,
      pivotState,
      isPivotView,
      defaultSortModel,
    ]
  );

  const applyFilterModelToGrid = useCallback(() => {
    const filterModel = transformFormatOfFilterModelToBEModel(transiantFilters);
    gridApi?.current?.setFilterModel(filterModel);
  }, [transiantFilters]);

  const { isRestAPISource } = checkSourceType(analysisSrcTypeId);

  const updatedColumns = useMemo(() => {
    const isFlattenResData = isRestAPISource
      ? isCopilotSortedColumns ||
        (getObjectKeys(results?.data?.[0])?.length === 3 &&
          getObjectKeys(results?.data?.[0])?.every((item) =>
            ANL_DEF_PAR_KEYS_LIST?.includes(item)
          ))
      : false;

    const resCols = isRestAPISource
      ? getObjectKeys(results?.data[0]).map((item) => {
          const colExists = columns?.find(
            (column) => column?.column_name === item
          );
          return (
            colExists || {
              column_name: item,
              column_display_name: item,
              data_type: "" as ColumnDataType,
              is_selected: false,
              is_col_masked: false,
              col_type_id: "",
              is_qry_contains_perc_fmt: false,
            }
          );
        })
      : [];
    //Sort Columns check
    const resultColumns = isRestAPISource
      ? isFlattenResData
        ? resCols
        : sortListOnSpecificKeyValue({
            list: resCols,
            key: "column_name",
            type: "caseInsensitive",
          })
      : columns;

    const config: TableColumns<any> = isPivotView
      ? []
      : resultColumns?.map((item) => {
          const isPercentageCol = isPercentageMetricColumn(
            item?.column_display_name
          );
          //TODO: Will give it proper type just added any for LLA changes
          const compParams: any = {
            ...item,
            query_id: id,
            transiant_filters: transiantFilters,
            applied_filters: appliedFilters,
            onChangeQueryColumnSorting,
            sortModel: sortModel?.[0],
            query_type: type,
            col_data_type: item?.data_type,
            is_data_formatted: isDataFormatted,
            onChangeQuerySettings: item?.data_type
              ? onChangeQuerySettings
              : (): void => {},
            prefrences,
            metricFormat,
          };

          const minWidth =
            calculateElementSize(item?.column_display_name || "", {
              fontSize: "14px",
            })?.clientWidth ?? 0;

          const minWidthWithBuffer = minWidth + 70;

          const isFilterApplied =
            type === "CCQ"
              ? transiantFilters?.[item?.column_name]?.filter_model
              : transiantFilters?.[item?.column_name]?.filter_model ||
                appliedFilters?.[item?.column_name]?.filter_model;

          const { isNumberCol, isDateColumn } = checkDataTypeOfColumn(
            item?.data_type
          );

          const isMetricCol = item?.col_type_id === "MET";

          const hasToAddRenderer =
            (isDateColumn || isMetricCol || isNumberCol) &&
            !item?.is_col_masked;

          return {
            headerName: item?.column_display_name || "",
            field: item?.column_name || "",
            suppressSizeToFit: true,
            minWidth: minWidthWithBuffer > 210 ? minWidthWithBuffer : 210,
            ...(isRestAPISource && {
              maxWidth:
                ANL_DEF_PAR_KEYS.PROPERTY === item?.column_name
                  ? 350
                  : ANL_DEF_PAR_KEYS.VALUE === item?.column_name
                  ? 300
                  : undefined,
            }),
            flex: 1,
            headerComponentParams: compParams,
            ...(hasToAddRenderer
              ? {
                  cellRenderer: isDateColumn
                    ? "DateRenderer"
                    : item?.col_type_id === "MET"
                    ? "MetricRenderer"
                    : isNumberCol
                    ? "NumberRenderer"
                    : "",
                }
              : { cellRenderer: "DefaultRendrerForEllipsis" }),

            filter: getFilterByDataType(item?.data_type),
            ...(isNumberCol && {
              valueGetter: (e: any): any => {
                const colValue = e?.data?.[item?.column_name];
                if (colValue === null || colValue === "null") {
                  return null;
                }

                const valueWithDefVal = colValue ?? "";

                return valueWithDefVal === ""
                  ? valueWithDefVal
                  : parseFloat(
                      isPercentageCol && isMetricCol
                        ? formatPercentage(
                            valueWithDefVal,
                            item?.is_qry_contains_perc_fmt
                          )
                        : valueWithDefVal
                    );
              },
            }),

            ...(item?.is_col_masked && {
              valueGetter: (event: any): any => {
                return maskString(event?.data?.[item?.column_name]);
              },
            }),
            filterParams: {
              ...FILTER_PARAMS,
              ...(isDateColumn && {
                comparator: (
                  filterLocalDateAtMidnight: Date,
                  cellValue: any
                ): any => {
                  const dateAsString = cellValue;

                  if (dateAsString == null) return -1;
                  const cellDate = new Date(
                    moment.utc(dateAsString)?.format("MM/DD/YYYY")
                  );

                  if (
                    filterLocalDateAtMidnight?.getTime() === cellDate?.getTime()
                  ) {
                    return 0;
                  }
                  if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                  }
                  if (cellDate > filterLocalDateAtMidnight) {
                    return 1;
                  }
                  return 0;
                },
              }),
            },
            cellStyle: (params: ICellRendererParams): any => {
              const { value: paramsVal = "", rowIndex = 0 } = params;

              const { column_id: paramColId = "" } = params?.colDef
                ?.headerComponentParams as ResultGridHeaderParams;

              const isCellSelected =
                paramsVal === value &&
                rowIndex + 1 === index &&
                paramColId === colId;

              const isLastCell =
                params?.api?.paginationGetPageSize() *
                  (params?.api?.paginationGetCurrentPage() + 1) ===
                  params?.rowIndex + 1 ||
                params?.rowIndex + 1 === gridData?.length ||
                gridApi?.current?.paginationGetRowCount() ===
                  params?.rowIndex + 1;

              return value || index
                ? isCellSelected
                  ? { border: "2px solid #428bca", background: "#fff" }
                  : { border: "1px solid #e6e6e6", background: "#fff" }
                : item?.is_selected
                ? {
                    borderRight: "2px solid #428bca",
                    borderLeft: "2px solid #428bca",
                    borderBottom: isLastCell
                      ? "2px solid #428bca"
                      : "1px solid #e6e6e6",
                    borderTop: "1px solid #e6e6e6",
                    background: "#fff",
                  }
                : isFilterApplied
                ? {
                    background: "#eff3f8",
                    border: "1px solid #e6e6e6",
                  }
                : {
                    border: "1px solid #e6e6e6",
                    background: "#fff",
                  };
            },
          };
        });

    return config;
  }, [
    columns,
    id,
    index,
    value,
    transiantFilters,
    appliedFilters,
    gridData?.length,
    sortModel,
    type,
    colPreprocessInfo,
    isDataFormatted,
    isPivotView,
    dateFormat,
    prefrences,
    metricFormat,
  ]);

  const onChangePagination = useCallback(
    (updPagination) => {
      onGetQueryGridResults?.(id, updPagination);
      onChangeQueryBlockGridPagination?.(id, updPagination);
    },
    [onChangeQueryBlockGridPagination, id, onGetQueryGridResults]
  );

  const onPivotChange = useCallback(
    (event: GridColumnsChangedEvent) => {
      if (event?.type === "gridColumnsChanged") {
        event?.columnApi?.autoSizeColumns(["ag-Grid-AutoColumn"]);
      }

      updateQueryPivotState(
        id,
        event?.columnApi
          ?.getColumnState()
          ?.filter((pivotItem) => pivotItem?.colId !== "ag-Grid-AutoColumn"),
        //doing this to prevent unnecessary API calls in case of grid initialize first time
        numberOfCountColumnChangedCalled?.current > 2
      );

      numberOfCountColumnChangedCalled.current += 1;
    },
    [updateQueryPivotState, numberOfCountColumnChangedCalled]
  );

  const onColumnResized = useCallback(
    (event: ColumnResizedEvent) => {
      if (event?.finished) {
        updateQueryPivotState(
          id,
          event?.columnApi
            ?.getColumnState()
            ?.filter((pivotItem) => pivotItem?.colId !== "ag-Grid-AutoColumn"),
          false
        );
      }
    },
    [updateQueryPivotState]
  );

  const onSortChanged = useCallback(
    (event: SortChangedEvent) => {
      updateQueryPivotState(
        id,
        event?.columnApi
          ?.getColumnState()
          ?.filter((pivotItem) => pivotItem?.colId !== "ag-Grid-AutoColumn"),
        false
      );
    },
    [updateQueryPivotState]
  );

  useEffect(() => {
    if (!isServerSideFilteration && !isPivotView) {
      applyFilterModelToGrid();
    }
  }, [transiantFilters]);

  useEffect(() => {
    if (!isServerSideFilteration && !isPivotView) {
      gridApi?.current?.setSortModel(defaultSortModel || sortModel);
    }
  }, [sortModel, defaultSortModel]);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerValueGetter: (): string => ``,
      sortable: false,
      minWidth: 200,
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (isPivotView) {
        gridApi?.current?.refreshCells({ force: true });
      }
    }, 200);
  }, [isDataFormatted, dateFormat, prefrences, metricFormat]);

  return (
    <>
      <AgGridTable
        gridId={`${QUERY_GRID_EXPANDED_CLASSNAME_PREFIX}-${id}`}
        groupDefaultExpanded={-1}
        showExpandCollapse
        autoGroupColumnDef={autoGroupColumnDef}
        rowData={[]}
        tableColumns={isPivotView ? updatedColumnsForPivotView : updatedColumns}
        frameworkComponents={{
          ...(!isPivotView && {
            agColumnHeader: QueryResultsGridHeaderRendrer,
          }),
          MetricRenderer,
          DateRenderer,
          NumberRenderer,
        }}
        isTableWidthfitTowindowSize={false}
        isComputeCol={false}
        rowHeight={40}
        onGridReady={onGridReady}
        defaultColDef={{
          suppressMovable: true,
          resizable: isPivotView,
          sortable: isPivotView,
        }}
        pagination={!isServerSideFilteration && !isMaximizeQueryBlock}
        showOnlyCountPagination={
          !isServerSideFilteration && isMaximizeQueryBlock
        }
        maxHeight={
          isMaximizeQueryBlock || isGridExpanded
            ? `calc(100vh - ${
                isGridExpanded && !isMaximizeQueryBlock
                  ? "300px"
                  : isGridExpanded && isMaximizeQueryBlock
                  ? "220px"
                  : "300px"
              })`
            : "400px"
        }
        localeText={{
          noRowsToShow: isNoRecords
            ? NO_RESULTS_QUERY_BLOCK_ERROR_MESSAGE
            : NO_RESULTS_ON_FILTERING_QUERY_BLOCK_MESSAGE,
        }}
        data-testid="analysis-detail-page-query-block-query-results-grid"
        pageSizes={["25", "50", "75", "100"]}
        {...(isPivotView && {
          sideBar: "columns",
          pivotMode: true,
          isShowPaginationComponent: false,
          pagination: false,
          onGridColumnsChanged: onPivotChange,
          onColumnValueChanged: onPivotChange,
          context: {
            is_data_formatted: isDataFormatted,
            is_pivot_view: true,
            prefrences,
          },
          onColumnResized,
          onSortChanged,
          onFirstDataRendered: (event: FirstDataRenderedEvent): void => {
            event?.columnApi?.autoSizeColumns(["ag-Grid-AutoColumn"]);
          },
        })}
        {...(!isPivotView && {
          onPaginationChanged: (event): void => {
            if (
              isGridLoaded?.current &&
              !isServerSideFilteration &&
              !isMaximizeQueryBlock
            ) {
              onChangeQueryBlockGridPagination?.(id, {
                page_size: event?.api?.paginationGetPageSize(),
                current_page: event?.api?.paginationGetCurrentPage() + 1,
              });
            }
          },
          onFirstDataRendered: (): void => {
            if (!isServerSideFilteration) {
              isGridLoaded.current = true;
              applyFilterModelToGrid();
            }

            // TODO: Needs to convert this logic into one func.
            // Default sort model calculated after grid ready,
            // This will help to apply default sort model on first data render.
            if (!isServerSideFilteration && !isPivotView) {
              gridApi?.current?.setSortModel(defaultSortModel || sortModel);
            }
          },
          onFilterChanged: (event): void => {
            onChangeRenderedGridRows?.(event?.api?.paginationGetRowCount());
          },
          // onCellClicked: (props): void => {
          //   const { column_id: colId = "" } = props?.colDef
          //     ?.headerComponentParams as ResultGridHeaderParams;

          //   onChangeCellSelection?.(
          //     id,
          //     props?.value,
          //     (props?.rowIndex ?? 0) + 1,
          //     colId
          //   );
          // },
        })}
      />

      {!isPivotView && isServerSideFilteration && (
        <CustomPagination
          currentPage={pagination?.current_page}
          pageSize={pagination?.page_size}
          totalCount={gridRecordCount}
          onChangePagination={onChangePagination}
        />
      )}
    </>
  );
};

const QueryResultsGrid = (props: QueryResultsGridProps): JSX.Element => {
  const { queryItem, isFiltersExist = false } = props;

  const {
    is_loading: isLoading,
    loader_type: loaderType,
    error,
    header,
    results,
  } = queryItem || {};

  const {
    result_type: resultType,
    is_maximize_query_block: isMaximizedQuery = false,
  } = header || {};
  const { is_sample_data: isSampleData = false } = results || {};

  const isChatPage = checkIsChatPage();

  const isGridView = resultType === "grid";
  const isPivotView = resultType === "pivot";

  return (
    <QueryResultsContentStyled
      data-testid="analysis-detail-page-query-block-query-results-content-main-wrapper"
      isChatPage={isChatPage}
      isGridView={isGridView || isSampleData}
      isPivotView={isPivotView}
      isMaximizedQuery={isMaximizedQuery}
      isFiltersExist={isFiltersExist}
    >
      <QueryBlocksLoadingSlateWrapper
        isLoading={isLoading && loaderType === "fetching_paginated_results"}
        text="Fetching Results..."
        isPaginatedResultsLoading={loaderType === "fetching_paginated_results"}
      >
        {error ? (
          <div
            className="msg-box query-block-error-box"
            data-testid="analysis-detail-page-query-block-query-results-grid-error-msg-box"
          >
            <span
              className="error-msg"
              data-testid="analysis-detail-page-query-block-query-results-grid-error-msg"
            >
              {queryItem?.error?.formattedMsg || ""}
            </span>
          </div>
        ) : (
          <>
            <GridSection {...props} />
          </>
        )}
      </QueryBlocksLoadingSlateWrapper>
    </QueryResultsContentStyled>
  );
};

export default memo(QueryResultsGrid);
