import { AxiosResponse } from "axios";
import { useCallback, useEffect } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormItemStyled, FormStyled } from "../../components/form";
import LinkButton from "../../components/linkbutton";
import { chainIconInCircle } from "../../svgs";
import {
  ShareFooter,
  SharedWithList,
  ShareForm,
} from "./shareownerform.components";
import SuccessNotificationMessage from "../../components/successnotificationmessagerendrer/successnotificationmessagerendrer";
import StateHandler from "../../components/statehandler/statehandler";

import { useGetSocialShareList } from "../../api/socialnodeservice";
import { useGetUserAndGroups } from "../../api/tablesservice";
import { useRequestWithMethod } from "../../api";
import { shareOwnerSchema } from "../../utils/schemas/shareschemas";

import {
  useCancelModal,
  useCopyToClipboard,
  useGetAppState,
  useGetData,
  useSetData,
} from "../../customhooks";

import { API_CONFIG } from "../../constants/apiconfig";

import {
  ShareOwnerFormType,
  ShareOwnerModalProps,
} from "./shareownerform.types";

import { getFiltersfromLocalStorage, openNotification } from "../../utils";

import { ShareOwnerFormStyled } from "./shareownerform.styles";
import ShareHOC from "./sharehoc/sharehoc";
import { ShareOwnerHOCEmbedProps } from "./sharehoc/sharehoc.types";
import { AnalysisDetailsDocResponse } from "../../parsers/analysisparser/analysisparser.types";
import { ShareListAnalysisResponse } from "../../parsers/socialnode/socialnodeparser.types";

const ShareOwnerFormContent = ({
  isShowShareForm,
  shareListLoading,
  onSubmit,
  sharedWithListItems = [],
  bottomBanner,
}: {
  isShowShareForm: boolean;
  shareListLoading: boolean;
} & ShareOwnerHOCEmbedProps): JSX.Element => {
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    showCopyLink = false,
    createdById = "",
    accessOptionsInInput = [],
    accessOptionsInListing = [],
  } = modalProps as ShareOwnerModalProps;

  const onCopyClipboard = useCopyToClipboard();
  const { handleSubmit } = useFormContext();

  const handleCopyLink = useCallback(() => {
    onCopyClipboard("");
  }, []);

  const onSubmitWrapper = useCallback(
    (values: ShareOwnerFormType): void => {
      onSubmit?.(values);
    },
    [onSubmit]
  );

  return (
    <>
      <FormStyled
        isItemColumnLayout
        onFinish={handleSubmit(onSubmitWrapper) as any}
      >
        <div className="content-sec">
          <ShareForm
            isShowShareForm={isShowShareForm}
            createdById={createdById}
            accessOptions={accessOptionsInInput}
          />
          {!!sharedWithListItems?.length && (
            <SharedWithList
              parsedSharedWith={sharedWithListItems}
              isShowShareForm={isShowShareForm}
              isLoading={shareListLoading}
              accessOptions={accessOptionsInListing}
            />
          )}

          {showCopyLink && (
            <FormItemStyled className="copy-link-icon">
              <LinkButton className="chain-icon" onClick={handleCopyLink}>
                {chainIconInCircle("48px", "48px")} Copy Link
              </LinkButton>
            </FormItemStyled>
          )}

          {bottomBanner || <div />}
        </div>
        <ShareFooter isShowShareForm={isShowShareForm} />
      </FormStyled>
    </>
  );
};

const ShareOwnerForm = (): JSX.Element => {
  const {
    modal: { modalProps = {} },
  } = useGetAppState();

  const {
    nodeId,
    nodeType = "TBL",
    successMessage = "",
  } = modalProps as ShareOwnerModalProps;

  const { get_share_list: getShareList } = API_CONFIG;

  const onCancel = useCancelModal();
  const onSetData = useSetData();
  const onGetData = useGetData();

  const onSuccessShare = useCallback(
    (response) => {
      onSetData(getShareList, response?.data, [nodeId, nodeType]);
      openNotification(<SuccessNotificationMessage message={successMessage} />);
      onCancel();
      const parsedNodeFilter = getFiltersfromLocalStorage(nodeType);
      if (nodeType === "ANL") {
        const prevData: AxiosResponse<AnalysisDetailsDocResponse> = onGetData(
          API_CONFIG.get_node,
          [nodeId, "ANL", "PUB", "false", "ANL", parsedNodeFilter]
        );

        const isAnalysisSharedWithAnyUser = response?.data?.find(
          (item: ShareListAnalysisResponse) =>
            item?.NODE_REL_TYPE !== "USER_OWNER"
        );
        const updatedData: AnalysisDetailsDocResponse = {
          ...prevData?.data,
          da_shared_with: {
            ...prevData?.data?.da_shared_with,
            value: response?.data,
          },
          da_access: {
            ...prevData?.data?.da_access,
            value: {
              da_share_level_id: isAnalysisSharedWithAnyUser ? "LIM" : "NON",
              da_share_level: isAnalysisSharedWithAnyUser
                ? "Shared with specific people"
                : "Not shared with anyone",
            },
          },
        };

        onSetData(API_CONFIG.get_node, updatedData, [
          nodeId,
          "ANL",
          "PUB",
          "false",
          "ANL",
          parsedNodeFilter,
        ]);
      }
    },
    [nodeId, successMessage, getShareList, nodeType]
  );

  const {
    onExecuteRequest: saveShareRefNodes,
    isLoading,
    error,
  } = useRequestWithMethod(
    "add_share_action",
    undefined,
    false,
    onSuccessShare
  );

  const formProvider = useForm<ShareOwnerFormType>({
    defaultValues: {
      share_with: undefined,
      share_with_role: "viewer",
      message: undefined,
      shared_with_list: [],
    },
    resolver: yupResolver(shareOwnerSchema),
    mode: "onChange",
  });

  const {
    parsedData: parsedSharedWith,
    isLoading: isLoadingSharedWithList,
    error: errorSharedWithList,
  } = useGetSocialShareList(nodeId, nodeType);

  const {
    parsedData: parsedUsers,
    isLoading: isLoadingUsers,
    error: errorUsers,
  } = useGetUserAndGroups(true);

  const { watch, trigger } = formProvider;
  const { share_with: shareWith } = watch();
  const isShowShareForm = shareWith?.length > 0 || false;

  useEffect(() => {
    if (isShowShareForm) {
      trigger("share_with_role");
    }
  }, [isShowShareForm]);

  return (
    <StateHandler
      isFetching={isLoading || isLoadingSharedWithList || isLoadingUsers}
      error={error || errorSharedWithList || errorUsers}
      isModal
    >
      <ShareOwnerFormStyled>
        <FormProvider {...formProvider}>
          <ShareHOC
            parsedUsers={parsedUsers}
            parsedSharedWith={parsedSharedWith}
            nodeType={nodeType}
            nodeId={nodeId}
            saveShareRefNodes={saveShareRefNodes}
            shareWith={shareWith}
          >
            <ShareOwnerFormContent
              shareListLoading={isLoadingSharedWithList}
              isShowShareForm={isShowShareForm}
              {...modalProps}
            />
          </ShareHOC>
        </FormProvider>
      </ShareOwnerFormStyled>
    </StateHandler>
  );
};

export default ShareOwnerForm;
