// <--- Styles Start --->
import styled from "styled-components";
// <--- Styles End --->

export const AnalysisDetailPageHeaderStyled = styled.div<{
  sideBarPosition: "collapsed" | "not-collapsed";
}>`
  .header-content-sec {
    padding: 14.4px 20px 0;
  }

  .fixed-breadcrum {
    position: fixed;
    left: ${(props): string =>
      props.sideBarPosition === "collapsed" ? "43px" : "190px"};
    top: 45px;
    z-index: 1;
    width: 100%;
  }
`;
