import { Edge } from "reactflow";

//       <--Types Start-->
import { LineageNode } from "../../../parsers/lineageparser/lineageparser.types";
//       <--Types End-->

//       <--Utils Start-->
import { setXYPositionOfNodesDrillDownMode } from "./setxypositionofnodesdrilldownmode";
//       <--Utils End-->

export function setXYPositionOfNodes(
  nodes: LineageNode[],
  edges: Edge[],
  isFirstTime: boolean,
  isDQEnabled?: boolean,
  isColumnLineage?: boolean
): { nodes: LineageNode[]; edges: Edge[] } {
  return setXYPositionOfNodesDrillDownMode(
    nodes,
    edges,
    isFirstTime,
    isDQEnabled,
    isColumnLineage
  );
}
