import { Progress } from "antd";
import styled from "styled-components";

export const OnlineProfilingProgressBarStyled = styled.div<{
  addGenericStyling?: boolean;
}>`
  .profiling-info-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 0;
    width: 100%;
  }

  .ant-progress-outer {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  .notification-message {
    gap: 10px;

    .gritter-message {
      display: flex;
      gap: 3px;

      .percentage {
        font-family: OpenSansSemiBold;
      }
    }
  }

  .ant-progress {
    /* top: ${({ addGenericStyling }): string =>
      addGenericStyling ? "66px" : ""}; */
    /* top: 66px; */
  }
`;

export const ProfilingProgressBarStyled = styled(Progress)<{
  profilingStatus?: "info" | "error";
}>`
  width: 100%;
  /* position: absolute; */

  .ant-progress-inner {
    height: 6px;
    background-color: ${({ theme, profilingStatus }): string =>
      profilingStatus === "info"
        ? theme.bgColors.LIGHT_BLUE_25
        : theme.bgColors.RED_101};
    border-radius: 0;
  }

  .ant-progress-bg {
    border-radius: 0;

    background-color: ${({ theme, profilingStatus }): string =>
      profilingStatus === "info"
        ? theme.bgColors.BLUE_123
        : theme.bgColors.RED_100};
  }
`;
