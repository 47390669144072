//          <--Types Start-->
import { LineageNode } from "../../../parsers/lineageparser/lineageparser.types";
import { CommonDataOfNodeReturnType } from "../lineagepage.types";
import { checkIfNodeIdExists, splitIds } from "./checkexpandednodeid";
import { getFocusedNodes } from "./helper";
import { UpdateNodesParameteres } from "./lineagepage.utils.types";
//          <--Types End-->

const commonDataOfNode = (
  id: string,
  expandedNodeId?: string[],
  focusedNodeId?: string,
  isAnyChilsNodeFocused = false,
  parentNodeId?: string,
  drillDownFocusedNodeId?: string
): CommonDataOfNodeReturnType => {
  const expandedId = expandedNodeId || "";

  const isCurrentNodeExpanded = expandedNodeId?.includes(id);

  const isMainNodeFocused = focusedNodeId === id;
  const isDrillDownNodeFocused = drillDownFocusedNodeId === id;

  return {
    isDisabled: false,
    isInsightMode: false,
    isExpandedMode: !!isCurrentNodeExpanded,
    isFocusedMode:
      isMainNodeFocused || isDrillDownNodeFocused || isAnyChilsNodeFocused,
    isParentNodeExpanded: parentNodeId === expandedNodeId,
  };
};

function updateBiDirectionalNodes({
  nodes = [],
  expandedNodeId,
  focusedNodeId,
  isInInsightMode,
}: UpdateNodesParameteres): LineageNode[] {
  return nodes?.map((node) => ({
    ...node,
    data: {
      ...node?.data,
      ...commonDataOfNode(
        node?.id,
        expandedNodeId,
        focusedNodeId,
        isInInsightMode,
        node?.data?.parentNodeId
      ),
    },
    // Reverted lineage drill down enhancement changes
    // hidden: !ids?.includes(node?.id) && node?.data?.level === 3 && isDrillDown,
  }));
}

function updateUniDirectionalNodes({
  nodes = [],
  expandedNodeId,
  focusedNodeId,
  ids = [],
  isInInsightMode,
}: UpdateNodesParameteres): LineageNode[] {
  return nodes?.map((item) => ({
    ...item,
    data: {
      ...item?.data,
      ...commonDataOfNode(
        item?.id,
        expandedNodeId,
        focusedNodeId,
        isInInsightMode
      ),
      isDisabled: !ids?.includes(item?.id),
    },
    // Reverted lineage drill down enhancement changes
    // hidden: isDrillDown && !ids?.includes(item?.id) && item?.data?.level === 3,
  }));
}

function updateNodesToDefaultState({
  nodes = [],
  expandedNodeId,
  isInInsightMode,
  focusedNodeId,
  drillDownFocusedNodeId,
}: UpdateNodesParameteres): LineageNode[] {
  return nodes?.map((node) => {
    const currentNodeChilds = nodes?.filter(
      (item) => item?.data?.parentNodeId === node?.id
    );

    const isAnyChilsNodeFocused = currentNodeChilds?.some(
      (item) => item?.id === drillDownFocusedNodeId
    );

    return {
      ...node,
      data: {
        ...node?.data,
        ...commonDataOfNode(
          node?.id,
          expandedNodeId,
          focusedNodeId,
          isAnyChilsNodeFocused,
          node?.data?.parentNodeId,
          drillDownFocusedNodeId
        ),
      },
      // Reverted lineage drill down enhancement changes
      // hidden: false,
    };
  });
}

export function updateNodes(params: UpdateNodesParameteres): LineageNode[] {
  switch (params?.mode) {
    case "bi-directional":
      return updateBiDirectionalNodes(params);
    case "uni-directional":
      return updateUniDirectionalNodes(params);
    default:
      return updateNodesToDefaultState(params);
  }
}
