import { useMemo } from "react";
import { useHistory } from "react-router";
// <--- Components Start --->
import {
  AnalysisDetailPageHeaderWithNoSelection,
  AnalysisdetailpageHeaderBulkSelection,
} from "./analysisdetailpageheader.views";
// <--- Components End --->

// <--- Custom Hooks Start --->
import { useGetAppState } from "../../../../customhooks";
// <--- Custom Hooks End --->

// <--- Styles Start --->
import { AnalysisDetailPageHeaderStyled } from "./analysisdetailpageheader.styles";
// <--- Styles End --->

// <--- Types Start --->
import {
  AnalysisDetailPageHeaderParamsState,
  AnalysisDetailPageHeaderProps,
} from "./analysisdetailpageheader.types";
import BreadCrum from "../../../../components/breadcrum";
import { APP_ROUTES } from "../../../../constants";
import { sourceDetailPageTabsValues } from "../../../sourcedetailpage/sourcedetailpage.constants";
import {
  DEFAULT_IS_FROM_PAGE,
  QUERY_GRID_EXPANDED_CLASSNAME_PREFIX,
} from "../../analysisdetalpage.constants";
import { AccessDialogueStyled } from "../../../../styles/app.styles";
import { Banner } from "../../../../components";
import ConditionalDisplay from "../../../../components/conditionaldisplay";
// <--- Types End --->

const ANALYSIS_DETAIL_PAGE_HEADER_ID = "analysis_detail_page_header_id";

const AnalysisDetailPageHeader = (
  props: AnalysisDetailPageHeaderProps
): JSX.Element => {
  const {
    sideBarPosition,
    expandableGridProps: { isGridExpanded = false, gridId = "" },
  } = useGetAppState();

  const {
    sourceId,
    sourceName = "",
    analysisInfo,
    analysisNodeSubType,
    hasUserViewOnlyAccess,
  } = props?.withOutSelectionProps;

  const history = useHistory();

  const SOURCE_BREAD_CRUM = [
    {
      label: "Data Sources",
      link: APP_ROUTES.private_routes.sources,
    },
    {
      label:
        analysisInfo?.datasourceUsed?.name || sourceName || "Source Detail",
      link: `${APP_ROUTES.private_routes.sources}/${sourceId}/${sourceDetailPageTabsValues?.["ai-agents"]}`,
    },
    {
      label: analysisNodeSubType === "TOL" ? "Tool" : "Agent",
    },
  ];

  const locationState = (history?.location?.state ||
    {}) as AnalysisDetailPageHeaderParamsState;

  const previousPage = locationState?.isFromPage || DEFAULT_IS_FROM_PAGE;
  const previousRoute = locationState?.isFromRoute;

  const breadCrumbBasisOnPreviousRoute = useMemo(() => {
    if (previousPage === "home" || previousPage === "search")
      return [
        ...((previousPage === "search" && [
          { label: "Search", link: previousRoute },
        ]) ||
          []),
        {
          label: analysisNodeSubType === "TOL" ? "Tool" : "Agent",
        },
      ];

    return SOURCE_BREAD_CRUM;
  }, [SOURCE_BREAD_CRUM, previousPage, previousRoute]);

  return (
    <>
      {hasUserViewOnlyAccess && analysisInfo?.createdBy?.name && (
        <AccessDialogueStyled>
          <Banner
            content={
              <div className="readonly-chat-banner">
                You are viewing{" "}
                <span className="created-by">
                  {analysisInfo?.createdBy?.name}'s
                </span>{" "}
                tool with view-only access. Please contact the owner of the tool
                if you require edit permissions.
              </div>
            }
            color="black"
            bgColor="#e6f3ff"
          />
        </AccessDialogueStyled>
      )}
      <div id={ANALYSIS_DETAIL_PAGE_HEADER_ID} />

      <AnalysisDetailPageHeaderStyled sideBarPosition={sideBarPosition}>
        <div
          className={
            isGridExpanded &&
            gridId?.includes(QUERY_GRID_EXPANDED_CLASSNAME_PREFIX)
              ? "fixed-breadcrum"
              : ""
          }
        >
          <BreadCrum currentPages={breadCrumbBasisOnPreviousRoute} />
        </div>
        <ConditionalDisplay condition={!isGridExpanded}>
          <div className="header-content-sec">
            {props?.isAnyQuerySelected ? (
              <AnalysisdetailpageHeaderBulkSelection />
            ) : (
              <AnalysisDetailPageHeaderWithNoSelection
                {...props?.withOutSelectionProps}
              />
            )}
          </div>
        </ConditionalDisplay>
      </AnalysisDetailPageHeaderStyled>
    </>
  );
};

export default AnalysisDetailPageHeader;
