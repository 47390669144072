import { Edge } from "reactflow";
import { LineageNode } from "../../../parsers/lineageparser/lineageparser.types";
import { AlertingNodeRelationToBase } from "../lineagepage.types";
import { splitIds } from "./checkexpandednodeid";
import {
  getAllAlertingNodesStatus,
  getAllChildsBwd,
  getAllChildsFwd,
  getExpAndVisbleNodesChildren,
  getInfoAboutFocusedChildLineage,
  getNodeChildren,
} from "./helper";
import {
  LineagePageDqFlowStateType,
  LineageVisibleNodeIds,
} from "./lineagepage.utils.types";

export const getLineagePageStateForDqFlowFromUrl = (
  expandedJunctionNodes: string,
  alertingNodeRelations: AlertingNodeRelationToBase[]
): LineagePageDqFlowStateType => {
  const {
    isAllInSource,
    isAllInTarget,
    isSomeInSrcAndSomeInTgt,
  } = getAllAlertingNodesStatus(alertingNodeRelations);

  const expandedJunctions = splitIds(expandedJunctionNodes) || [];

  const isSrcJunctionNodeCollapsed = !expandedJunctions?.includes("source");
  const isTgtJunctionNodeCollapsed = !expandedJunctions?.includes("target");

  const isCollapsedWithAllAlertingInSrc =
    isSrcJunctionNodeCollapsed && isAllInSource;

  const isCollapsedWithSomeAlertingInSrc =
    isSrcJunctionNodeCollapsed && isSomeInSrcAndSomeInTgt;

  const isCollpasedInSrc =
    isCollapsedWithAllAlertingInSrc || isCollapsedWithSomeAlertingInSrc;

  const isCollapsedWithAllAlertingInTgt =
    isTgtJunctionNodeCollapsed && isAllInTarget;

  const isCollapsedWithSomeAlertingInTgt =
    isSomeInSrcAndSomeInTgt && isTgtJunctionNodeCollapsed;

  const isCollpasedInTgt =
    isCollapsedWithAllAlertingInTgt || isCollapsedWithSomeAlertingInTgt;

  const isBothSrcAndTgtCollapsed = isCollpasedInSrc && isCollpasedInTgt;

  const isAnySrcOrTgtCollapsed = isCollpasedInSrc || isCollpasedInTgt;

  const showAllCollapsed = isAnySrcOrTgtCollapsed;

  const isAnyAlertingNodeExistsInSrc = isAllInSource || isSomeInSrcAndSomeInTgt;

  const isAnyAlertingNodeExistsInTgt = isAllInTarget || isSomeInSrcAndSomeInTgt;

  const showCollapsedFrwColLin =
    isCollpasedInTgt && isAnyAlertingNodeExistsInTgt;

  const showCollapsedBwdColLin =
    isCollpasedInSrc && isAnyAlertingNodeExistsInSrc;

  const showNodesFromAlertingToBase =
    !isCollpasedInSrc && isAnyAlertingNodeExistsInSrc;

  const showNodesFromBaseToAlerting =
    !isCollpasedInTgt && isAnyAlertingNodeExistsInTgt;

  return {
    isBothSrcAndTgtCollapsed,
    isAnySrcOrTgtCollapsed,
    showAllCollapsed,
    isCollpasedInSrc,
    isCollpasedInTgt,
    isAnyAlertingNodeExistsInSrc,
    isAnyAlertingNodeExistsInTgt,
    showCollapsedFrwColLin,
    showCollapsedBwdColLin,
    showNodesFromAlertingToBase,
    showNodesFromBaseToAlerting,
    isCollapsedWithAllAlertingInSrc,
    isCollapsedWithAllAlertingInTgt,
    isCollapsedWithSomeAlertingInSrc,
    isCollapsedWithSomeAlertingInTgt,
  };
};

export const getVisibleNodeIdsForLineage = (
  nodes: LineageNode[],
  edges: Edge[],
  expandedNodeIds: string[],
  visibleCollpasedNodeIds: string[],
  focusedColId: string,
  baseNodeId: string,
  isColumnLevelLineage: boolean,
  alertingNodeRelations: AlertingNodeRelationToBase[],
  expandedJunctionNodes: string
): LineageVisibleNodeIds => {
  const {
    showNodesFromAlertingToBase,
    showNodesFromBaseToAlerting,
  } = getLineagePageStateForDqFlowFromUrl(
    expandedJunctionNodes,
    alertingNodeRelations
  );

  const initialNodesThatAreVisible = [...visibleCollpasedNodeIds];

  // when alerting node is in src
  const nodesFromAlertingToBase = getAllChildsBwd(edges, [baseNodeId], true);
  const nodesFromAlertingToBaseIds =
    nodesFromAlertingToBase?.map((node) => node) || [];

  // when alerting node is in tgt
  const nodesFromBaseToAlerting = getAllChildsFwd(edges, [baseNodeId], true);
  const nodesFromBaseToAlertingIds =
    nodesFromBaseToAlerting?.map((node) => node) || [];

  const visibleTableIdsThatArePartOfLin = [
    ...initialNodesThatAreVisible,
    ...(showNodesFromAlertingToBase ? nodesFromAlertingToBaseIds : []),
    ...(showNodesFromBaseToAlerting ? nodesFromBaseToAlertingIds : []),
  ];

  const childsOfUpdatedVisibleParentNodes = getNodeChildren(
    nodes,
    visibleTableIdsThatArePartOfLin
  );

  const childsOfUpdatedVisibleParentNodesIds =
    childsOfUpdatedVisibleParentNodes?.map((node) => node?.id) || [];

  const {
    bwdLinOfFocusedCol,
    fwdLinOfFocusedCol,
    visibleBwdLinIds,
    visibleFwdLinIds,
  } = getInfoAboutFocusedChildLineage(
    edges,
    focusedColId,
    childsOfUpdatedVisibleParentNodesIds
  );

  const allColsIdsThatArePartOfLin = [
    ...bwdLinOfFocusedCol,
    ...fwdLinOfFocusedCol,
    focusedColId,
  ];

  // only few cols will be visible becoz in dq mode we have collapsed nodes
  // so show less cols, less edges
  const onlyVisibleColsIdsThatArePartOfLin = [
    ...visibleBwdLinIds,
    ...visibleFwdLinIds,
    ...(focusedColId ? [focusedColId] : []),
  ];

  const visibleColsWithData =
    nodes?.filter(
      (node) =>
        childsOfUpdatedVisibleParentNodesIds?.includes(node?.id) &&
        node?.data?.level === 2
    ) || [];

  const searchedColumnIds =
    visibleColsWithData
      ?.filter((node) => node?.data?.isNodePartOfSearchedQuery)
      ?.map((node) => node?.id) || [];

  const childsOfExpandedLevel1Nodes = getExpAndVisbleNodesChildren(
    nodes,
    expandedNodeIds,
    visibleTableIdsThatArePartOfLin
  );

  const parentOfFocusedCol = nodes?.find((node) => node?.id === focusedColId);
  const parentOfFocusedColId = parentOfFocusedCol?.parentNode || "";

  const childsOfFocusedColParentNode = getNodeChildren(nodes, [
    parentOfFocusedColId,
  ]);

  const childsIdsOfFocusedColParentNode =
    childsOfFocusedColParentNode?.map((node) => node?.id) || [];

  const expandedNodeColumnIds =
    childsOfExpandedLevel1Nodes?.map((node) => node?.id) || [];

  const isFocusedColPArentIsExpanded = expandedNodeIds?.includes(
    parentOfFocusedColId
  );

  const expandedColIds =
    focusedColId && isFocusedColPArentIsExpanded
      ? [...childsIdsOfFocusedColParentNode]
      : [...expandedNodeColumnIds];

  // const nodesOfColLineage=

  const visibleNodesIds = [
    ...expandedColIds,
    ...searchedColumnIds,
    ...visibleTableIdsThatArePartOfLin,
    ...(isColumnLevelLineage ? onlyVisibleColsIdsThatArePartOfLin : []),
  ];

  return {
    // remove them if not needed
    visibleLevel1Nodes: visibleTableIdsThatArePartOfLin,
    visibleLevel2Nodes: allColsIdsThatArePartOfLin,

    // actual visible nodes that will be needed
    allVisibleNodeIds: visibleNodesIds,
    visibleLevel2BwdLinIds: visibleBwdLinIds,
    visibleLevel2FwdLinIds: visibleFwdLinIds,
    allLevel2BwdLinIds: bwdLinOfFocusedCol,
    allLevel2FwdLinIds: fwdLinOfFocusedCol,
  };
};
