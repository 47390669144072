import { TooltipProps } from "antd";
import { TabsList } from "../../components/tabslayout/tabslayout.types";

export const ADD_USER_ROLE_TABS_LIST: TabsList = [
  {
    key: "general",
    value: "General",
  },
  {
    key: "admin",
    value: "Admin",
  },
  {
    key: "system-catalog",
    value: "System Catalog",
  },
  {
    key: "business-glossary",
    value: "Business Glossary",
  },
  {
    key: "lineage",
    value: "Lineage",
  },
];

export const ADD_USER_ROLE_FINE_GRAIN_CONFIG: {
  [key in string]?: { name: string; desc: string };
} = {
  admin: {
    name: "admin_fine_grain_enabled",
    desc:
      "Fine-grained settings let you specify which administrative features to enable for each role. You can also control whether users have View or Edit access to each feature.",
  },
  "system-catalog": {
    name: "catalog_fine_grain_enabled",
    desc:
      "When you enable this, you can restrict the scope and level of access of this role to specific Data Sources and criteria. Note that when you select this option, new sources will require explicit access to be given to this role for allowing them to access this source.",
  },
  "business-glossary": {
    name: "glossary_fine_grain_enabled",
    desc:
      "When you enable this, you can restrict the scope and level of access of this role to specific Domains. Note that when you select this option, new sources will require explicit access to be given to this role for allowing them to access this source.",
  },
};

export const ADD_USER_ROLE_TREE_CONFIG: {
  [key in string]?: { label: string };
} = {
  "system-catalog": {
    label: "Select Sources and level of access for each source",
  },
  "business-glossary": {
    label: "Select Data Domains and level of access for each domain",
  },
};

export const ADD_USER_ROLE_CRITERIA_CONFIG: {
  [key in string]?: any;
} = {
  "system-catalog": {
    name: "catalog_table_criteria",
    checkboxName: "catalog_table_criteria_enabled",
    title: "Table Exclusion Criteria",
    desc:
      "Optionally add a criteria on what Tables the user shouldn't be able to access. For example, you can allow only Published assets to be accessible by user in Elastic Search and Listing views.",
    type: "TBL",
  },
  "business-glossary": {
    name: "glossary_term_criteria",
    checkboxName: "glossary_term_criteria_enabled",
    title: "Term Exclusion Criteria",
    desc:
      "Optionally choose criteria for which Terms are not allowed for access. For example, only allow Published Terms to be accessible for Analysts and end-users. If you enable criteria below, it is possible that new Terms added to source may not be automatically available for Chat and will require them to be updated before they become available.",
    type: "TRM",
  },
};

export const ADD_ROLE_PERMISSIONS_OPTIONS_TOOLTIP: TooltipProps = {
  title: "Fine Grain enabled, Access permissions can be set below",
  placement: "right",
};

export const ADMIN_FINE_GRAIN_OPTS = [
  {
    name: "No Access",
    value: "NOA",
  },
  {
    name: "View",
    value: "VIW",
  },
  {
    name: "Edit",
    value: "EDT",
  },
];
