import styled from "styled-components";
import LinkButton from "../linkbutton/linkbutton";

export const ViewSelectorStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6.4px;

  .label {
    color: ${({ theme }): string => theme?.textColors?.GREY_26};
    font-size: 12px;
  }

  .view-selector-btns-wrapper {
    display: flex;
    align-items: center;
  }
`;

export const ViewSelectorToggleButtonStyled = styled(LinkButton)<{
  isActive?: boolean;
  isDisabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  min-width: 32px;

  &,
  &:hover,
  &:focus,
  &:active {
    background: ${({ theme, isActive, isDisabled }): string =>
      isActive
        ? theme?.bgColors?.BLUE_116
        : isDisabled
        ? theme?.bgColors?.LIGHT_GREY_22
        : theme?.bgColors?.WHITE_COLOR};
    border: 1px solid
      ${({ theme, isActive }): string =>
        isActive
          ? theme?.borderColors?.BLUE_116
          : theme?.borderColors?.GREY_18};
    border-radius: 0;
    border-color: ${({ theme, isActive }): string =>
      isActive ? theme?.borderColors?.BLUE_116 : theme?.borderColors?.GREY_18};

    cursor: ${({ isDisabled }): string =>
      isDisabled ? "not-allowed" : "pointer"};

    &,
    span {
      color: ${({ theme, isActive }): string =>
        isActive
          ? theme?.textColors?.WHITE_COLOR
          : theme?.textColors?.GREY_210};

      svg {
        path {
          fill: ${({ theme, isActive }): string =>
            isActive
              ? theme?.textColors?.WHITE_COLOR
              : theme?.textColors?.GREY_18};
          stroke: ${({ theme, isActive }): string =>
            isActive
              ? theme?.textColors?.WHITE_COLOR
              : theme?.textColors?.GREY_18};
        }
      }
    }
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    &:hover {
      border-right: 0;
    }
  }

  &:not(:last-child) {
    border-right: 0;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &.ant-btn {
    transition: none;
  }

  &:disabled {
  }
`;
