import { useCallback, useMemo } from "react";
import moment from "moment";

import LinkButton from "../linkbutton";
import { LineagePageAlertMessageStyled } from "./lineagealertmessage.styles";
import { LineageAlertMessagePropsType } from "./lineagealertmessage.types";

import { numberFormatter } from "../../utils";

const NUMBER_OF_NODES_TO_SHOW_UPFRONT = 5;

const LineageAlertMessage = (
  props: LineageAlertMessagePropsType
): JSX.Element => {
  const { onClick, alertingRulesInfo } = props;

  const totalAlertingRulesCount = alertingRulesInfo?.reduce(
    (total, { alertingRulesCount }) => total + alertingRulesCount,
    0
  );

  const totalAlertingNodesCount = alertingRulesInfo?.length || 0;

  const singleAlrtingRuleInfo = useMemo(() => alertingRulesInfo?.[0], [
    alertingRulesInfo,
  ]);

  const runDateOfSingleRule = singleAlrtingRuleInfo?.run_date;

  const formattedDate = runDateOfSingleRule
    ? moment.utc(runDateOfSingleRule).local().format("MM/DD/YYYY hh:mm A")
    : "";

  const isOnlyOneRuleAlerting = totalAlertingRulesCount === 1;

  const { tableName = "" } = singleAlrtingRuleInfo || {};

  const alertingRulesInfoCount = alertingRulesInfo?.length || 0;

  const onViewClick = useCallback(
    (event) => {
      event?.stopPropagation();
      onClick?.();
    },
    [onClick]
  );

  const renderAlertingRulesInfo = (
    startFrom: number,
    endAt: number
  ): string => {
    return alertingRulesInfo
      ?.slice(startFrom, endAt)
      ?.map(({ tableName }) => tableName)
      .join(", ");
  };

  const moreNodesExists =
    alertingRulesInfoCount > NUMBER_OF_NODES_TO_SHOW_UPFRONT;

  const moreNodesCount =
    alertingRulesInfoCount - NUMBER_OF_NODES_TO_SHOW_UPFRONT;

  const renderDetectedOnJsx = (): string => {
    const upfrontTablesName = renderAlertingRulesInfo(
      0,
      NUMBER_OF_NODES_TO_SHOW_UPFRONT
    );

    if (isOnlyOneRuleAlerting) return ` ${tableName} at ${formattedDate}.`;
    return ` ${upfrontTablesName}${moreNodesExists ? `... ` : ". "}`;
  };

  const showMoreTblsJsx =
    alertingRulesInfoCount > NUMBER_OF_NODES_TO_SHOW_UPFRONT;

  const renderMoreTablesJsx = (): JSX.Element => {
    const moreTablesName = renderAlertingRulesInfo(
      NUMBER_OF_NODES_TO_SHOW_UPFRONT,
      alertingRulesInfoCount
    );

    const fomattedMoreNodesCount = numberFormatter(`${moreNodesCount}`);

    return (
      <LinkButton
        tooltipProps={{
          title: moreTablesName,
        }}
        className="more-tables-count"
      >
        +{fomattedMoreNodesCount}.
      </LinkButton>
    );
  };

  const fomattedAlertingCount = numberFormatter(`${totalAlertingRulesCount}`);

  return (
    <LineagePageAlertMessageStyled>
      <div className="message-content">
        <span className="issues-count">{fomattedAlertingCount}</span> Issue
        {totalAlertingRulesCount > 1 ? "s" : ""} detected on
        {renderDetectedOnJsx()}
        {showMoreTblsJsx && (
          <span className="tables-count">{renderMoreTablesJsx()} </span>
        )}
        <LinkButton onClick={onViewClick}> View</LinkButton>
      </div>
    </LineagePageAlertMessageStyled>
  );
};

export default LineageAlertMessage;
