import { Edge } from "reactflow";
import {
  edgeCommonProperties,
  edgeStyles,
} from "../../../parsers/lineageparser/lineageparser.util";
import {
  getAllChildsBwd,
  getAllChildsFwd,
  JUNCTION_NODE_IN_SRC,
  JUNCTION_NODE_IN_TRGT,
} from "./helper";
import { LineageNode } from "../../../parsers/lineageparser/lineageparser.types";

export const addEdgeFromPartOfLineageColToBaseNodeFocusCol = (
  lineageIds: string[],
  focusColId: string,
  isAnyLineageExists: boolean
): Edge[] => {
  const isLineageExistsAndColIsPartOfVisibleNode = !!lineageIds?.length;

  const lineageNodeId = lineageIds?.[0] || "";
  const junctionNodeId = JUNCTION_NODE_IN_SRC;

  const firstEdges = lineageIds?.map((id, index) => {
    return {
      id: `${id}-${junctionNodeId}`,
      source: id,
      target: junctionNodeId,
      ...edgeCommonProperties,
      style: { ...edgeStyles },
      data: {
        isColumnLevelJunctionEdge: true,
      },
    };
  });

  const secondEdge = {
    id: `${junctionNodeId}-${focusColId}`,
    source: junctionNodeId,
    target: focusColId,
    ...edgeCommonProperties,
    style: { ...edgeStyles },
    data: {
      isColumnLevelJunctionEdge: true,
    },
  };

  return isAnyLineageExists
    ? [
        ...(isLineageExistsAndColIsPartOfVisibleNode ? [...firstEdges] : []),
        secondEdge,
      ]
    : [];
};

export const addEdgeFromBaseNodeFocusColToPartOfLineageCol = (
  lineageIds: string[],
  focusColId: string,
  isAnyLineageExists: boolean
): Edge[] => {
  const isLinExistsButColIsPartOfHiddenNode =
    !lineageIds?.length && isAnyLineageExists;

  const lineageNodeId = lineageIds?.[0] || "";
  const junctionNodeId = JUNCTION_NODE_IN_TRGT;

  const firstEdge = {
    id: `${focusColId}-${junctionNodeId}`,
    source: focusColId,
    target: junctionNodeId,
    ...edgeCommonProperties,
    style: { ...edgeStyles },
    data: {
      isColumnLevelJunctionEdge: true,
    },
  };

  const secondEdges = lineageIds?.map((id, index) => {
    return {
      id: `${junctionNodeId}-${id}`,
      source: junctionNodeId,
      target: id,
      ...edgeCommonProperties,
      style: { ...edgeStyles },
      data: {
        isColumnLevelJunctionEdge: true,
      },
    };
  });

  // console.log("addEdgeFromBaseNodeFocusColToPartOfLineageCol", {
  //   firstEdge,
  //   secondEdges,
  //   lineageIds,
  //   focusColId,
  // });

  return isAnyLineageExists
    ? [
        firstEdge,
        ...(isLinExistsButColIsPartOfHiddenNode ? [] : [...secondEdges]),
      ]
    : [];
};

export const addEdgeFromSrcFocusColToBaseNodeCol = (
  visibleLinIds: string[],
  focusColId: string,
  isAnyLineageExists: boolean,
  baseNodeChildsIds: string[],
  showNodesFromBaseToAlerting: boolean,
  showNodesFromAlertingToBase: boolean,
  allVisibleNodeIds: string[],
  edges: Edge[],
  nodes: LineageNode[]
): Edge[] => {
  const srcJxnNodeId = JUNCTION_NODE_IN_SRC;
  const tgtJxnNodeId = JUNCTION_NODE_IN_TRGT;

  const baseNodeChildInLinIds =
    visibleLinIds?.filter((id) => baseNodeChildsIds?.includes(id)) || "";

  const firstEdge = {
    id: `${focusColId}-${srcJxnNodeId}`,
    source: focusColId,
    target: srcJxnNodeId,
    ...edgeCommonProperties,
    style: { ...edgeStyles },
    data: {
      isColumnLevelJunctionEdge: true,
    },
  };

  const edgeFromJxnSrcToBaseNode = baseNodeChildInLinIds
    ?.map((id) => {
      const edge = {
        id: `${srcJxnNodeId}-${id}`,
        source: srcJxnNodeId,
        target: id,
        ...edgeCommonProperties,
        style: { ...edgeStyles },
        data: {
          isColumnLevelJunctionEdge: true,
        },
      };

      return edge;
    })
    ?.flat();

  const isAllVisibileLinColsAreBaseNodeChilds = visibleLinIds?.every((id) =>
    baseNodeChildInLinIds?.includes(id)
  );

  const baseColToTgtJxn = isAllVisibileLinColsAreBaseNodeChilds
    ? []
    : baseNodeChildInLinIds?.map((baseNodeId) => {
        const edge = {
          id: `${baseNodeId}-${tgtJxnNodeId}`,
          source: baseNodeId,
          target: tgtJxnNodeId,
          ...edgeCommonProperties,
          style: { ...edgeStyles },
          data: {
            isColumnLevelJunctionEdge: true,
          },
        };

        return edge;
      });

  const tgtJxnToTgtColEdges = baseNodeChildInLinIds?.map((baseNodeLinId) => {
    const fwdLin = getAllChildsFwd(edges, [baseNodeLinId]);
    const isFwdLinExists = fwdLin?.length > 0;

    const finalEdges = isFwdLinExists
      ? fwdLin?.map((fwLinNodeId) => {
          const parentId =
            nodes?.find((node) => node?.id === fwLinNodeId)?.parentNode || "";
          const isParentVisible = allVisibleNodeIds?.includes(parentId);

          const edge = {
            id: `${tgtJxnNodeId}-${fwLinNodeId}`,
            source: tgtJxnNodeId,
            target: fwLinNodeId,
            ...edgeCommonProperties,
            style: { ...edgeStyles },
            data: {
              isColumnLevelJunctionEdge: true,
            },
          };

          return [...(isParentVisible ? [edge] : [])];
        })
      : [];

    return finalEdges?.flat() || [];
  });

  const srcAltToBase = [
    ...baseColToTgtJxn?.flat(),
    ...tgtJxnToTgtColEdges?.flat(),
  ];

  return isAnyLineageExists
    ? [
        ...(showNodesFromAlertingToBase ? [] : [firstEdge]),
        ...(showNodesFromAlertingToBase ? [] : edgeFromJxnSrcToBaseNode),
        ...(showNodesFromBaseToAlerting ? [] : [...srcAltToBase]),
      ]
    : [];
};

export const addEdgeFromBaseColToFocusColOfTgtNode = (
  visibleLinIds: string[],
  focusColId: string,
  isAnyLineageExists: boolean,
  baseNodeChildsIds: string[],
  showNodesFromBaseToAlerting: boolean,
  showNodesFromAlertingToBase: boolean,
  allVisibleNodeIds: string[],
  edges: Edge[],
  nodes: LineageNode[]
): Edge[] => {
  const tgtJxnNodeId = JUNCTION_NODE_IN_TRGT;
  const srcJxnNodeId = JUNCTION_NODE_IN_SRC;

  const baseNodeChildinLinIds =
    visibleLinIds?.filter((id) => baseNodeChildsIds?.includes(id)) || "";

  const srcAltToSrcJxtEdges = baseNodeChildinLinIds?.map((baseNodeId) => {
    const baseNodeBwdLin = getAllChildsBwd(edges, [baseNodeId]);

    const isBwdLinExists = baseNodeBwdLin?.length > 0;

    const srcToBaseLinIds = baseNodeBwdLin?.reverse();

    const finalEdges = isBwdLinExists
      ? srcToBaseLinIds?.map((baseNodeLinId) => {
          const parentId =
            nodes?.find((node) => node?.id === baseNodeLinId)?.parentNode || "";
          const isParentVisible = allVisibleNodeIds?.includes(parentId);

          const firstEdge = {
            id: `${baseNodeLinId}-${srcJxnNodeId}`,
            source: baseNodeLinId,
            target: srcJxnNodeId,
            ...edgeCommonProperties,
            style: { ...edgeStyles },
            data: {
              isColumnLevelJunctionEdge: true,
            },
          };

          return [...(isParentVisible ? [firstEdge] : [])];
        })
      : [];

    return finalEdges?.flat() || [];
  });

  const srcJxnToBaseColEdges = baseNodeChildinLinIds?.map((baseNodeId) => {
    const bwdLin = getAllChildsBwd(edges, [baseNodeId]);
    const isBwdLinExists = bwdLin?.length > 0;
    const edge = {
      id: `${srcJxnNodeId}-${baseNodeId}`,
      source: srcJxnNodeId,
      target: baseNodeId,
      ...edgeCommonProperties,
      style: { ...edgeStyles },
      data: {
        isColumnLevelJunctionEdge: true,
      },
    };
    return isBwdLinExists ? [edge] : [];
  });

  const srcAltToBase = [
    ...srcAltToSrcJxtEdges?.flat(),
    ...srcJxnToBaseColEdges?.flat(),
  ];

  const edgeFromBaseNodeToTgtJxnNode = baseNodeChildinLinIds
    ?.map((id) => {
      const edge = {
        id: `${id}-${tgtJxnNodeId}`,
        source: id,
        target: tgtJxnNodeId,
        ...edgeCommonProperties,
        style: { ...edgeStyles },
        data: {
          isColumnLevelJunctionEdge: true,
        },
      };

      return edge;
    })
    ?.flat();

  const fourthEdge = {
    id: `${tgtJxnNodeId}-${focusColId}`,
    source: tgtJxnNodeId,
    target: focusColId,
    ...edgeCommonProperties,
    style: { ...edgeStyles },
    data: {
      isColumnLevelJunctionEdge: true,
    },
  };

  return isAnyLineageExists
    ? [
        ...(showNodesFromAlertingToBase ? [] : [...srcAltToBase]),
        ...(showNodesFromBaseToAlerting ? [] : edgeFromBaseNodeToTgtJxnNode),
        ...(showNodesFromBaseToAlerting ? [] : [fourthEdge]),
      ]
    : [];
};
