import { Edge, Node } from "reactflow";
import { LineageNode } from "../../../parsers/lineageparser/lineageparser.types";
import { LineagePageUrlParamsType } from "../lineagepage.types";
import { traverseLineageBackward } from "./traverselineagebackward";
import { traverseLineageForward } from "./traverselineageforward";

import { updateNodes } from "./updatenodes";
import { setXYPositionOfNodes } from "./setxypositionofnodes";

import { updateEdges } from "./updateedges";

import {
  getColumnLevelLineageNodes,
  getDisabledUpstreamDownstreamNodes,
  getExpandedNodes,
  getFocusedNodes,
  getChildNodeAndParentIds,
  getTableLevelLineageNodes,
  getUpstreamDownstreamNodes,
  adjustLevel2NodesVisibilityBasedOnParent,
  hideEdgesOfColumnNodesIfTableOrTechLineage,
  adjustEdgesVisibilityByLineage,
  adjustLevel1NodeVisibilityByLineage,
  getDirectedChildsBwd,
  getDirectedChildsFwd,
  getAllChildsBwd,
  getAllChildsFwd,
  getAlertingNodes,
  hideNodesBetweenBaseAndAlerting,
  localizeSearchQueryNodes,
  getEncodedParsedUrlNodes,
  findAlertingNodeRelationToBase,
  addEdgesForAlertingNodes,
  hideEdgesOFHiddenNodes,
  JUNCTION_NODE_IN_SRC,
  JUNCTION_NODE_IN_TRGT,
  setAllNodesVisiblityAsDefault,
  checkDqModeStatusFromUrl,
  extractBaseNodeDetails,
} from "./helper";

import { splitIds } from "./checkexpandednodeid";

import {
  getLineagePageStateForDqFlowFromUrl,
  getVisibleNodeIdsForLineage,
} from "./getvisiblenodes";

export const getUpdatedNodesAndEdgesOnNodeClick = (
  nodes: Node<LineageNode["data"]>[],
  edges: Edge[],
  lineageQueryParams: LineagePageUrlParamsType,
  isCmpMounted: boolean
): { nodes: Node<LineageNode["data"]>[]; edges: Edge[] } => {
  const {
    lineageLevel,
    searchQuery = "",
    nodes: urlNodes = "",
    enabledLayer = "",
    expandedJunctionNodes = "",
  } = lineageQueryParams || {};

  const parsedUrlNodes = getEncodedParsedUrlNodes(urlNodes);

  const baseNodeObj = extractBaseNodeDetails(parsedUrlNodes);

  const { nodeId: baseNodeId = "" } = baseNodeObj || {};

  const isColumnLevelLineage = lineageLevel === "column";
  const isTableLineage = lineageLevel === "table";

  const urlLayers = splitIds(enabledLayer) || [];
  const isDQEnabled = urlLayers?.includes("DQ");

  // console.log("lineageQueryParams", lineageQueryParams);
  // console.log("parsedUrlNodes", parsedUrlNodes);

  const focusedNodes = getFocusedNodes(parsedUrlNodes);

  const mainNodeFocusId =
    focusedNodes?.find((node) => node?.nodeLevel === 1)?.nodeId || "";

  const drillDownNodeFocusId =
    focusedNodes?.find((node) => node?.nodeLevel === 2)?.nodeId || "";

  const focusedNodeIds = isColumnLevelLineage
    ? [drillDownNodeFocusId]
    : [mainNodeFocusId];

  const expandedNodes = getExpandedNodes(parsedUrlNodes);

  const expandedNodeIds =
    expandedNodes?.map((node) => node?.nodeId || "") || [];

  const {
    enabledAllUpstreamIds,
    enabledAllDownstreamIds,
    enabledOneUpstreamIds,
    enabledOneDownstreamIds,
  } = getUpstreamDownstreamNodes(parsedUrlNodes);

  const {
    disabledAllUpstreamIds,
    disabledAllDownstreamIds,
    disabledOneDownstreamIds,
    disabledOneUpstreamIds,
  } = getDisabledUpstreamDownstreamNodes(parsedUrlNodes);

  // up means backward
  // down means forward

  const visibleIdsOfOneUp = getDirectedChildsBwd(
    edges,
    enabledOneUpstreamIds,
    true
  );

  const visibleIdsOfOneDown = getDirectedChildsFwd(
    edges,
    enabledOneDownstreamIds,
    true
  );

  const visibleIdsOfAllUp = getAllChildsBwd(edges, enabledAllUpstreamIds, true);

  const visibleIdsOfAllDown = getAllChildsFwd(
    edges,
    enabledAllDownstreamIds,
    true
  );

  // up means backward
  // down means forward

  const hiddenIdsOfOneUp = getDirectedChildsBwd(edges, disabledOneUpstreamIds);

  const hiddenIdsOfOneDown = getDirectedChildsFwd(
    edges,
    disabledOneDownstreamIds
  );

  const hiddenIdsOfAllUp = getAllChildsBwd(edges, disabledAllUpstreamIds);

  const hiddenIdsOfAllDown = getAllChildsFwd(edges, disabledAllDownstreamIds);

  const hideDirectBwdLineage = disabledOneUpstreamIds?.length;
  const hideDirectFwdLineage = disabledOneDownstreamIds?.length;
  const hideAllBwdLineage = disabledAllUpstreamIds?.length;
  const hideAllFwdLineage = disabledAllDownstreamIds?.length;

  // the reason is based on id i am getting upstream traversal
  // and downstream traversal. but user can only ask for one of them
  const allNodesThatAreHiddenIds = [
    ...(hideDirectBwdLineage ? hiddenIdsOfOneUp : []),
    ...(hideDirectFwdLineage ? hiddenIdsOfOneDown : []),
    ...(hideAllBwdLineage ? hiddenIdsOfAllUp : []),
    ...(hideAllFwdLineage ? hiddenIdsOfAllDown : []),
  ];

  const hiddenColumnsIdsWithTheirParents = getChildNodeAndParentIds(
    nodes,
    allNodesThatAreHiddenIds
  );

  const allNodesThatAreVisibleIds = [
    ...visibleIdsOfOneUp,
    ...visibleIdsOfOneDown,
    ...visibleIdsOfAllUp,
    ...visibleIdsOfAllDown,
    baseNodeId,
    // ...allColumnNodesIds,
  ];

  const visibleColumnsIdsWithTheirParents = getChildNodeAndParentIds(
    nodes,
    allNodesThatAreVisibleIds
  );

  const allNodesThatAreVisible = [...visibleColumnsIdsWithTheirParents];

  const allNodesThatAreHidden = [...hiddenColumnsIdsWithTheirParents];

  const finalNodeIdsThatAreVisible = allNodesThatAreVisible?.filter(
    (item) => !allNodesThatAreHidden?.includes(item)
  );

  const enabledColAndTableIds = [drillDownNodeFocusId, mainNodeFocusId];

  const enabledColAndTableBackwardIds = traverseLineageBackward(
    edges,
    enabledColAndTableIds,
    []
  );
  const enabledColAndTableForwardIds = traverseLineageForward(
    edges,
    enabledColAndTableIds,
    []
  );

  const enabledColAndTableEdgesIds = [
    ...enabledColAndTableForwardIds,
    ...enabledColAndTableBackwardIds,
  ];

  const alertingNodes = getAlertingNodes(nodes, baseNodeId);

  const alertingNodesIds = alertingNodes?.map((node) => node?.id) || [];

  const alertingNodeRelations = findAlertingNodeRelationToBase(
    edges,
    alertingNodesIds,
    baseNodeId
  );

  const { isBothSrcAndTgtCollapsed } = getLineagePageStateForDqFlowFromUrl(
    expandedJunctionNodes,
    alertingNodeRelations
  );

  const isDqflowMode = checkDqModeStatusFromUrl(
    nodes,
    edges,
    lineageQueryParams
  );

  const enabledIds = [...focusedNodeIds];

  const defaultVisibleNodes = setAllNodesVisiblityAsDefault(nodes);

  const nodesToUse = defaultVisibleNodes;

  const searchedNodes = localizeSearchQueryNodes(nodesToUse, searchQuery);

  const nodesByColLin = getColumnLevelLineageNodes(
    searchedNodes,
    edges,
    expandedNodeIds,
    mainNodeFocusId,
    drillDownNodeFocusId,
    isColumnLevelLineage
  );

  const nodesByTblLin = getTableLevelLineageNodes(
    searchedNodes,
    expandedNodeIds
  );

  const nodeRelations = findAlertingNodeRelationToBase(
    edges,
    alertingNodesIds,
    baseNodeId
  );

  const nodeRelationsLength = nodeRelations?.length || 0;

  const upstreamAlertingNodes = nodeRelationsLength
    ? nodeRelations?.filter((item) => item?.isSource)
    : [];

  const upstreamAlertingNodesIds =
    upstreamAlertingNodes?.map((item) => item?.nodeId) || [];

  const dqFlowActiveNodes = hideNodesBetweenBaseAndAlerting(
    searchedNodes,
    edges,
    baseNodeId,
    // if both, then just replace upstreamAlertingNodesIds with alertingNodesIds
    upstreamAlertingNodesIds,
    expandedNodeIds,
    focusedNodeIds,
    isColumnLevelLineage,
    expandedJunctionNodes
  );

  const dqFlowActiveEdges = addEdgesForAlertingNodes(
    nodes,
    edges,
    // if both, then just replace upstreamAlertingNodesIds with alertingNodesIds
    upstreamAlertingNodesIds,
    baseNodeId,
    expandedNodeIds,
    focusedNodeIds,
    isColumnLevelLineage,
    expandedJunctionNodes
  );

  const lineageNodes = isColumnLevelLineage ? nodesByColLin : nodesByTblLin;

  const lineageEdges = adjustEdgesVisibilityByLineage(
    lineageNodes,
    edges,
    isColumnLevelLineage,
    enabledColAndTableEdgesIds
  );

  // this will only update parents node visibility
  const activeLevel1Nodes = adjustLevel1NodeVisibilityByLineage(
    lineageNodes,
    finalNodeIdsThatAreVisible,
    isColumnLevelLineage
  );

  // this will update the visibility of children nodes
  // based on parent node visibility
  // so it contains info about visible of all nodes
  const activeNodes = adjustLevel2NodesVisibilityBasedOnParent(
    activeLevel1Nodes
  );

  const filteredEdgesByNodeVisibility = hideEdgesOFHiddenNodes(
    activeNodes,
    lineageEdges
  );

  // hiding the edges of junction nodes
  const activeEdges = hideEdgesOfColumnNodesIfTableOrTechLineage(
    activeNodes,
    filteredEdgesByNodeVisibility,
    isTableLineage
  );

  const isDrillDown = true;

  const finalNodesToProcess = isDqflowMode ? dqFlowActiveNodes : activeNodes;

  const finalEdgesToProcess = isDqflowMode ? dqFlowActiveEdges : activeEdges;

  const updatedNodes = updateNodes({
    nodes: finalNodesToProcess,
    ids: [],
    isDrillDown: true,
    focusedNodeId: mainNodeFocusId,
    expandedNodeId: expandedNodeIds,
    drillDownFocusedNodeId: drillDownNodeFocusId,
    isInInsightMode: false,
    mode: "default",
  });

  const visibleFinalEdges = finalEdgesToProcess?.filter(
    (edge) => !edge?.hidden
  );

  const updatedNodesWithPos = setXYPositionOfNodes(
    updatedNodes,
    finalEdgesToProcess,
    !isCmpMounted || isDrillDown,
    isDQEnabled,
    isColumnLevelLineage
  )?.nodes;

  const backwardIds = traverseLineageBackward(
    visibleFinalEdges,
    enabledIds,
    []
  );
  const forwardIds = traverseLineageForward(visibleFinalEdges, enabledIds, []);

  const traverseIds = [...backwardIds, ...forwardIds];

  const visibleParentIds = [baseNodeId, ...alertingNodesIds];

  const { allVisibleNodeIds } = getVisibleNodeIdsForLineage(
    nodes,
    edges,
    expandedNodeIds,
    visibleParentIds,
    drillDownNodeFocusId,
    baseNodeId,
    isColumnLevelLineage,
    alertingNodeRelations,
    expandedJunctionNodes
  );

  const junctionNodesIds = [JUNCTION_NODE_IN_SRC, JUNCTION_NODE_IN_TRGT];

  const level1NodeIds =
    nodes?.filter((node) => node?.data?.level === 1)?.map((node) => node?.id) ||
    [];

  const onlyLevel2Nodes = traverseIds?.filter(
    (id) => !level1NodeIds?.includes(id)
  );

  const edgesToTaverseOn =
    isDqflowMode && isColumnLevelLineage
      ? [...onlyLevel2Nodes, ...junctionNodesIds]
      : isDqflowMode && isBothSrcAndTgtCollapsed
      ? [...allVisibleNodeIds, ...junctionNodesIds]
      : [...traverseIds];

  const finalUpdatedEdges = updateEdges({
    edges: finalEdgesToProcess,
    ids: edgesToTaverseOn,
    mode: "bi-directional",
    isDrillDown,
  });

  return { edges: finalUpdatedEdges, nodes: updatedNodesWithPos };
};
