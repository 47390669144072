import { ETLCodeRefDrawerStyled } from "./etlcoderefdrawer.styles";
import { ETLRefDrawerPropsType } from "./etlcoderefdrawer.types";

import ETLCodeSource from "../../components/etlcodesource/etlcodesource";
import { useGetAppState } from "../../customhooks";
import { EdgeDataType } from "../../parsers/lineageparser/lineageparser.types";

const ETLCodeRefDrawer = (props: ETLRefDrawerPropsType): JSX.Element => {
  const { drawer } = useGetAppState();
  const { drawerProps = {} } = drawer || {};

  const {
    nodeCodeInfo,
    srcNodeId,
    srcNodeName,
    tgtNodeId,
    tgtNodeName,
    srcNodeType,
    tgtNodeType,
  } = (drawerProps as EdgeDataType) || {};

  const query = nodeCodeInfo?.[0]?.code || "";
  const codeSrc = nodeCodeInfo?.[0]?.plo_type || "";

  const parsedEtlCodeInfo = {
    metaInfo: [
      {
        title: "Source",
        value: srcNodeName,
        id: `${srcNodeId}`,
        additionalInfo: {
          nodeType: srcNodeType,
        },
      },

      {
        title: "Target",
        value: tgtNodeName,
        id: `${tgtNodeId}`,
        additionalInfo: {
          nodeType: tgtNodeType,
        },
      },

      {
        title: "Source of Relation",
        value: codeSrc,
      },
    ],
  };

  return (
    <ETLCodeRefDrawerStyled>
      <ETLCodeSource
        metaInfo={parsedEtlCodeInfo?.metaInfo}
        code={query}
        showRedirectIcon={false}
      />
    </ETLCodeRefDrawerStyled>
  );
};

export default ETLCodeRefDrawer;
