import { Spin } from "antd";
import { useCallback, useMemo, useRef, useState } from "react";

import { useFormContext } from "react-hook-form";
import { useCancelModal } from "../../../customhooks";

import { Button, FilterConditionModal } from "../../../components";

import { FormItemStyled, FormStyled } from "../../../components/form";

import {
  InputField,
  RadioGroupField,
  SelectField,
  TextAreaField,
  CheckboxGroupField,
  CheckboxField,
  ChoiceBoxField,
  DictionaryField,
  DatePickerField,
} from "../../../components/formfields";

import { DynamicFormProps, RendererFormProps } from "./dynamicform.types";
import {
  ChoiceBoxFieldWrapperStyled,
  ColumnNameAndTypeStyled,
  ComparisionWrapperStyled,
  DynamicFormLoadingSpinerStyled,
  DynamicformStyled,
  FilterWrapperStyled,
  SectionContainer,
} from "./dynamicform.styles";

import FormItemLabel from "../../../components/form/formitemlabel";
import { filledFilterIcon, filterIconFlipped } from "../../../svgs";

import LinkButton from "../../../components/linkbutton/linkbutton";
import Flex from "../../../components/flex";

import InformativeText from "../../../components/informativetext";
import { getDataTypeIcon, selectFilterOption } from "../../../utils";

import { HorizontalDividerStyled } from "../../../components/dividers/dividers.styles";

import { parsedFilters } from "../../../components/filtercriteria/filtercriteria.config";

import { CheckboxWrapperStyled } from "../../../components/form/form.styles";

import FilterConditionExpanded from "../../../components/filterconditionexpanded/filterconditionexpanded";
import { ELEMENT_IDS } from "../../../constants";

const filledFunnelIcon = filledFilterIcon("13", "13");
const unFilledFunnelIcon = filterIconFlipped("12", "12");

const {
  datdict_detpg_dq_ad_rul_sav_btn: DATDICT_DETPG_DQ_AD_RUL_SAV_BTN,
} = ELEMENT_IDS;

const RenderField = (props: RendererFormProps): JSX.Element => {
  const {
    field,
    control,
    setValue,
    watch,
    paddingLeft = "",
    columnsData = [],
    isEdit,
    refColumnData = [],
  } = props;

  const {
    id: fieldId,
    name: fieldName = "",
    label: fieldLabel = "",
    placeholder: fieldPlaceholder = "",
    description: fieldDescription = "",
    required: isFieldRequired = false,
    disabled: isFieldDisabled = false,
    width: fieldWidth = "",
    typeOfInputField: inputFieldType = "",
    multiSelect: isFieldMultiSelect = false,
    child: isChildField = false,
    isSelectedDictionaryHierarchial = false,
    dictListKeys = [],
    dictListValues = [],
    dictListLoading = false,
    tooltipPlacement = "left",
    tooltipClassName,
    isDictionaryInEditMode = false,
    intialSpecifyFields = [],
    isRefFilter = false,
  } = field || {};

  const selectOptions = (field?.options || [])?.map(
    (selectOption, index) =>
      ({
        value: selectOption?.value,
        label: selectOption?.type ? (
          <ColumnNameAndTypeStyled
            data-testid={`add-rule-select-field-option-${fieldName}-${index}`}
          >
            <span className="icon">{getDataTypeIcon(selectOption?.type)}</span>
            <span className="label">{selectOption?.label}</span>
          </ColumnNameAndTypeStyled>
        ) : (
          <div
            data-testid={`add-rule-select-field-option-${fieldName}-${index}`}
          >
            {selectOption?.label}
          </div>
        ),
        labelText: selectOption?.label as string,
        labelDesc: selectOption?.labelDesc,
        textForSearch: selectOption?.textForSearch as string,
        ...(selectOption?.options && { options: selectOption?.options }),
      } || [])
  );

  const radioGroupOptions =
    (field?.options || [])?.map((option) => {
      return {
        label: option?.label,
        value: option?.value,
        labelDesc: option?.labelDesc,
        children: option?.children ? (
          <RenderField
            key={`radio-field-${option?.value}`}
            field={option?.children}
            control={control}
            setValue={setValue}
            watch={watch}
            columnsData={columnsData}
            refColumnData={refColumnData}
          />
        ) : (
          <div />
        ),
      };
    }) || [];

  switch (field?.type) {
    case "input":
      return (
        <FormItemLabel
          label={fieldLabel}
          description={fieldDescription}
          key={fieldId}
          width="450px"
          required={isFieldRequired}
          paddingLeft={isChildField ? "35px" : paddingLeft || ""}
        >
          <InputField
            control={control}
            name={fieldName}
            disabled={isFieldDisabled}
            // QUESTION: Why we needed this width with ternanry operator? SEE IF WE CAN DO IT IN ANOTHER WAY
            width={isChildField ? fieldWidth || "428px" : fieldWidth || "512px"}
            placeholder={fieldPlaceholder}
            type={inputFieldType || "text"}
            propOnChange={field?.onChange}
          />
        </FormItemLabel>
      );

    case "date":
      return (
        <FormItemLabel
          label={fieldLabel}
          description={fieldDescription}
          key={fieldId}
          width="450px"
          required={isFieldRequired}
          paddingLeft={isChildField ? "35px" : paddingLeft || ""}
        >
          <DatePickerField
            defaultValue={new Date()}
            control={control}
            format="MM/DD/YYYY"
            name={fieldName}
            placeholder={fieldPlaceholder}
            disabled={isFieldDisabled}
            width={isChildField ? fieldWidth || "428px" : fieldWidth || "512px"}
          />
        </FormItemLabel>
      );

    case "text_area":
      return (
        <FormItemLabel
          label={fieldLabel}
          description={fieldDescription}
          key={fieldId}
          width="450px"
          required={isFieldRequired}
          marginTop="26px"
          className={field?.className || ""}
        >
          <TextAreaField
            control={control}
            name={fieldName}
            width={field?.width || "512px"}
            required={isFieldRequired}
            placeholder={fieldPlaceholder}
          />
        </FormItemLabel>
      );
    case "select":
      return (
        <FormItemLabel
          label={fieldLabel}
          description={fieldDescription}
          key={fieldId}
          width="450px"
          required={isFieldRequired}
          marginTop={isChildField ? "15px" : "26px"}
          paddingLeft={isChildField ? "10px" : ""}
        >
          <SelectField
            control={control}
            setValue={setValue}
            options={field?.isLoadingFieldData ? [] : selectOptions}
            name={fieldName}
            placeholder={fieldPlaceholder}
            isAllowClear={false}
            // QUESTION: Why we needed this width with ternanry operator? SEE IF WE CAN IT IN ANOTHER WAY
            width={
              isChildField ? (field?.compareLabel ? "201px" : "225px") : "512px"
            }
            showSearch={field?.showSearch}
            mode={isFieldMultiSelect ? "multiple" : undefined}
            disabled={isFieldDisabled}
            propOnChange={field?.onChange}
            filterOption={selectFilterOption}
            // isMessagePositionAbsolute
            isUseGlobalStyle
            // getPopupContainer={(trigger): HTMLElement =>
            //   trigger.parentNode as HTMLElement
            // }
            notFoundContent={
              field?.isLoadingFieldData ? (
                <DynamicFormLoadingSpinerStyled>
                  Data is being fetched <Spin size="small" />
                </DynamicFormLoadingSpinerStyled>
              ) : field?.errorFieldData || !selectOptions?.length ? (
                <DynamicFormLoadingSpinerStyled>
                  No results found
                </DynamicFormLoadingSpinerStyled>
              ) : null
            }
            shouldAddDescInLabel={field?.shouldAddDescInLabel}
            isGroupedOpt={field?.isGroupedOpt}
            allowClear={false}
            tooltipPlacement={tooltipPlacement}
            tooltipClassName={tooltipClassName}
            propOnClear={field?.propOnClear}
          />
          {field?.selectedOptionDes && (
            <InformativeText
              marginTop="8px"
              width="512px"
              desc={field?.selectedOptionDes}
            />
          )}
        </FormItemLabel>
      );
    case "radio_group":
      return (
        <FormItemLabel
          label={fieldLabel}
          description={fieldDescription}
          key={fieldId}
          width="450px"
          required={isFieldRequired}
          paddingLeft={isChildField ? "0" : ""}
          marginTop="10px"
        >
          <RadioGroupField
            control={control}
            name={fieldName}
            options={radioGroupOptions || []}
            direction="column"
            labelDescWidth="512px"
          />
        </FormItemLabel>
      );
    case "checkbox_group":
      return (
        <FormItemLabel
          label={fieldLabel}
          description={fieldDescription}
          key={fieldId}
          width="450px"
          required={isFieldRequired}
          marginTop="26px"
        >
          <CheckboxGroupField
            control={control}
            setValue={setValue}
            name={fieldName}
            className="is-repeat-cb"
            options={field?.options || []}
          />
        </FormItemLabel>
      );
    case "checkbox":
      return (
        // <FormItemLabel key={fieldId} width="450px" required={isFieldRequired}>
        <CheckboxWrapperStyled
          marginBottom="15px"
          paddingLeft="20px"
          marginTop="20px"
        >
          <CheckboxField
            control={control}
            name={fieldName}
            mode="primary"
            disabled={isFieldDisabled}
          >
            {fieldLabel}
            {fieldDescription && (
              <InformativeText
                desc={fieldDescription}
                marginBottom="8px"
                width="450px"
              />
            )}
            {field?.children?.map((child) => (
              <Flex key={child?.id} alignItems="center">
                <RenderField
                  field={child}
                  control={control}
                  setValue={setValue}
                  watch={watch}
                  paddingLeft="0"
                  columnsData={columnsData}
                  refColumnData={refColumnData}
                />
              </Flex>
            ))}
          </CheckboxField>
        </CheckboxWrapperStyled>
        // </FormItemLabel>
      );
    case "filter": {
      const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);

      const onFilterModalOpen = useCallback(() => {
        setValue("is_filter_criteria_active", true);
        setIsFilterModalVisible(true);
      }, []);

      const onFilterModalClose = useCallback(() => {
        setValue("is_filter_criteria_active", false);
        setIsFilterModalVisible(false);
      }, []);

      const onFilterClearData = useCallback(() => setValue(fieldName, []), [
        fieldName,
      ]);

      const existingFilters = field?.existingFilters || [];
      const existingFiltersLength = existingFilters?.length || 0;
      const isFiltersExists = existingFiltersLength > 0;

      // JUST ADDING CHAINING
      const lengthOfExistsingFilters =
        watch()?.[fieldName]?.[0]?.condition?.[0]?.column === undefined
          ? 0
          : watch()?.[fieldName]?.length;

      const filtersExists = useMemo(() => lengthOfExistsingFilters > 0, [
        lengthOfExistsingFilters,
      ]);

      return (
        <FormItemLabel
          label={fieldLabel}
          description={fieldDescription}
          key={fieldId}
          width="450px"
          required={isFieldRequired}
        >
          {isEdit ? (
            <FilterConditionExpanded
              parsedFields={isRefFilter ? refColumnData : columnsData}
              parsedFilters={parsedFilters}
              isEditMode={isFiltersExists}
              existingFilters={existingFilters}
              nameOfFilterComp={fieldName}
            />
          ) : (
            <>
              <FilterWrapperStyled onClick={onFilterModalOpen}>
                {/* <div className="filter-icon">{unFilledFunnelIcon}</div> */}
                {filtersExists ? filledFunnelIcon : unFilledFunnelIcon}

                <div className="filter-label-value">
                  <LinkButton className="label">
                    {/* Add Filter */}
                    {filtersExists
                      ? `${lengthOfExistsingFilters} condition(s) added`
                      : " Add Filter"}
                  </LinkButton>
                </div>
              </FilterWrapperStyled>

              <FilterConditionModal
                key={`${fieldId}-filter-criteria-${fieldName}`}
                isEditMode={false}
                isModalVisible={isFilterModalVisible}
                onHandleCancel={onFilterModalClose}
                parsedFields={isRefFilter ? refColumnData : columnsData}
                parsedFilters={parsedFilters}
                existingFilters={field?.existingFilters || []}
                nameOfFilterComp={fieldName}
                onClearData={onFilterClearData}
              />
            </>
          )}
        </FormItemLabel>
      );
    }
    case "comparision":
      return (
        <FormItemLabel
          label={fieldLabel}
          description={fieldDescription}
          key={fieldId}
          width="450px"
          required={isFieldRequired}
        >
          <ComparisionWrapperStyled>
            <Flex alignItems="flex-start" justifyContent="space-between">
              {field?.children?.map((child) => (
                <Flex key={child?.id} alignItems="flex-start">
                  {child?.compareLabel && (
                    <div className="label">{child?.compareLabel}</div>
                  )}
                  <RenderField
                    field={child}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    paddingLeft="0"
                    columnsData={columnsData}
                    refColumnData={refColumnData}
                  />
                  {child?.addHorizontalDivider && (
                    <HorizontalDividerStyled
                      width="10px"
                      height="1px"
                      marginLeft="20px"
                      marginTop="15px"
                      className="comparison-divider"
                    />
                  )}
                </Flex>
              ))}
            </Flex>
          </ComparisionWrapperStyled>
        </FormItemLabel>
      );
    case "choice_box":
      return (
        <ChoiceBoxFieldWrapperStyled>
          {/* MAY NEED TO FIND ANOTHER FIX, BUT USING FORMITEMLABEL DISTORTS CHECKBOXES STYLE */}
          <div className="label">{fieldLabel}</div>
          <ChoiceBoxField
            control={control}
            setValue={setValue}
            watch={watch}
            // PLEASE FIX THIS TS ERROR
            options={field?.options || []}
            width="512px"
            name={fieldName}
          />
        </ChoiceBoxFieldWrapperStyled>
      );
    case "dictionary":
      return (
        <FormItemLabel
          label={fieldLabel}
          description={fieldDescription}
          key={fieldId}
          width="490px"
          required={isFieldRequired}
          marginTop="26px"
        >
          <DictionaryField
            options={field?.options || []}
            name={fieldName}
            width="512px"
            dictListKeys={dictListKeys || []}
            dictListValues={dictListValues || []}
            dictListLoading={dictListLoading}
            isSelectedDictionaryHierarchial={isSelectedDictionaryHierarchial}
            columns={columnsData}
            onChange={field?.onChange}
            intialSpecifyFields={intialSpecifyFields}
            isInEditMode={isDictionaryInEditMode}
          />
        </FormItemLabel>
      );
    default:
      return <div />;
  }
};

const DynamicForm = (props: DynamicFormProps): JSX.Element => {
  const {
    schema,
    onSubmit,
    isFromRuleSet,
    propsOnCancel,
    columnsData,
    isEdit,
    isPageLoading = false,
    refColumnData = [],
  } = props;

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid, errors, isDirty },
    watch,
  } = useFormContext();
  const onCancel = useCancelModal();

  const onCancelButtonClick = useCallback(() => {
    propsOnCancel ? propsOnCancel?.() : onCancel();
  }, []);

  return (
    <DynamicformStyled>
      <FormStyled isItemColumnLayout layout="vertical">
        {schema?.map((section) => (
          <SectionContainer
            className="section-container"
            key={section?.id}
            isFromRuleSet={isFromRuleSet}
          >
            {section?.title && (
              <div className={`title ${section?.id}`}>{section?.title}</div>
            )}
            <p className="description">{section?.description}</p>
            {section?.fields?.map((nestedField) => {
              return (
                <RenderField
                  key={nestedField.id}
                  field={nestedField}
                  control={control}
                  setValue={setValue}
                  watch={watch}
                  columnsData={columnsData}
                  refColumnData={refColumnData}
                  isEdit={isEdit}
                />
              );
            })}
          </SectionContainer>
        ))}
        <FormItemStyled className="form-actions-sec" marginBottom="0px">
          <Button id="cancel" width="74px" onClick={onCancelButtonClick}>
            Cancel
          </Button>
          <Button
            id="primary"
            width="74px"
            marginLeft="8px"
            htmlType="submit"
            disabled={!isValid}
            onClick={handleSubmit(onSubmit)}
            elementId={isPageLoading ? "" : DATDICT_DETPG_DQ_AD_RUL_SAV_BTN}
          >
            Save
          </Button>
        </FormItemStyled>
      </FormStyled>
    </DynamicformStyled>
  );
};

export default DynamicForm;
