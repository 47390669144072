import styled from "styled-components";

export const LineagePageAlertMessageStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  column-gap: 5px;
  z-index: 10;

  .issues-count {
    font-family: OpenSansSemiBold;
    /* margin-right: 3px; */
  }

  .more-tables-count {
    color: ${({ theme }): string => theme.bgColors.GREY_40};
    font-family: OpenSansSemiBold;
  }

  button {
    cursor: pointer;
  }
`;
