import { GridApi, SelectionChangedEvent } from "ag-grid-community";
import { cloneElement, useCallback, useEffect, useState } from "react";

let flters: string[] = [];
function TableWithSearchAndFiltersHOC({
  appliedFilters,
  children,
  filterColumnName,
}: {
  appliedFilters: string[];
  children: JSX.Element;
  filterColumnName: string;
}): JSX.Element {
  const [searchText, setSearchText] = useState("");
  const [gridApi, setGridApi] = useState<GridApi>();

  const [isFilterExpanded, setIsFilterExpanded] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState<string[]>();

  const [selectedNodes, setSelectedNodes] = useState<any[]>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

  useEffect(() => {
    setSelectedFilters([...appliedFilters]);
    flters = appliedFilters;
  }, []);

  const onGridReady = useCallback(
    (params) => {
      setGridApi(params?.api);
      params?.api?.onFilterChanged();
    },
    [gridApi]
  );

  const onChangeSearchText = useCallback(
    (event) => {
      gridApi?.setQuickFilter(event?.target?.value);
      setSearchText(event?.target?.value);
    },
    [searchText, gridApi]
  );

  const onChangeFilter = useCallback(
    (filter, _checked) => {
      flters = [filter];
      gridApi?.onFilterChanged();
      setSelectedFilters([filter]);
    },
    [selectedFilters, gridApi]
  );

  const doesExternalFilterPass = useCallback(
    (node) => {
      return (
        flters?.length === 0 ||
        flters?.includes("all") ||
        flters?.includes(node.data?.[filterColumnName])
      );
    },
    [flters]
  );

  const onFilterButtonClicked = useCallback(() => {
    setIsFilterExpanded((st) => !st);
  }, [isFilterExpanded]);

  const handleSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    const selectedNodes = event?.api?.getSelectedNodes();

    const nodes = selectedNodes?.map((node) => node?.data);

    setSelectedNodes(nodes);
  }, []);

  const handleClearAll = useCallback(() => {
    setIsSelectAll(false);
    setSelectedNodes([]);
    gridApi?.deselectAll();
  }, [gridApi]);

  // Selects all records and displayed checkboxes
  const handleSelectAll = useCallback(() => {
    setIsSelectAll(true);
    gridApi?.selectAll();
  }, []);

  useEffect(() => {
    return (): void => {
      flters = [];
    };
  }, []);

  return (
    <div>
      {cloneElement(children as JSX.Element, {
        onFilterButtonClicked,
        doesExternalFilterPass,
        onChangeFilter,
        onChangeSearchText,
        onGridReady,
        searchText,
        gridApi,
        isFilterExpanded,
        selectedFilters,
        handleSelectionChanged,
        handleSelectAll,
        handleClearAll,
        isSelectAll,
        selectedRecords: selectedNodes?.length,
        selectedNodes,
      })}
    </div>
  );
}

export default TableWithSearchAndFiltersHOC;
